import React from 'react';
import { Link } from 'react-router-dom';

class EventFooter extends React.Component {
  render() {
    return (
      <footer class="footer">
        <div class="footer-body">
          <div class="row no-gutters">
            <div class="col-lg-3 col-md-6">
              <div class="text-center text-xl-left text-lg-left text-md-left mb-4 mb-lg-0 mb-xl-0">
              <a
                target="_blank"
                href="https://farechild.com/"
             
              >
               <img
                    src={require('../../../assets/images/logo.png')}
                    alt="FareChild"
                    className="w-50"
                  />
              </a>
              
              
               
              </div>
            </div>
            <div class="col-lg-2 col-md-6">
              <p class="text-brand text-capitalize font-weight-bold">
                Use farechild
              </p>
              <div class="menu-footer-menu-first-container">
                <ul
                  id="menu-footer-menu-first"
                  class="list-unstyled mb-4 mb-lg-0 mb-xl-0"
                >
                  <li
                    id="menu-item-118"
                    class="text-capitalize menu-item menu-item-type-custom menu-item-object-custom menu-item-118"
                  >
                    <a target="_blank" href="https://farechild.com/about-us/">
                      About
                    </a>
                  </li>
                  <li
                    id="menu-item-360"
                    class="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-14 current_page_item menu-item-360"
                  >
                    <a target="_blank" href="https://farechild.com/faq/" aria-current="page">
                      FAQs
                    </a>
                  </li>
                  <li
                    id="menu-item-120"
                    class="text-capitalize menu-item menu-item-type-custom menu-item-object-custom menu-item-120"
                  >
                    <Link to="/terms">Terms &amp; Conditions</Link>
                  </li>
                  <li
                    id="menu-item-361"
                    class="menu-item menu-item-type-post_type menu-item-object-page menu-item-361"
                  >
                    <a target="_blank" href="https://farechild.com/blog/">
                      Blogs
                    </a>
                  </li>
                  <li
                    id="menu-item-362"
                    class="menu-item menu-item-type-custom menu-item-object-custom menu-item-362"
                  >
                    <a
                      target="_blank"
                      href="https://farechild.com/event-gallery/"
                    >
                      Photo Galleries
                    </a>
                  </li>
                </ul>
              </div>{' '}
            </div>
            <div class="col-lg-2 col-md-6">
              <p class="text-brand text-capitalize font-weight-bold">
                plan events
              </p>
              <div class="menu-footer-second-menu-container">
                <ul
                  id="menu-footer-second-menu"
                  class="list-unstyled mb-4 mb-lg-0 mb-xl-0"
                >
                  <li
                    id="menu-item-127"
                    class="text-capitalize menu-item menu-item-type-custom menu-item-object-custom menu-item-127"
                  >
                    <Link
                      to={
                        localStorage.getItem('accessToken')
                          ? '/create-event'
                          : '/join'
                      }
                    >
                      Create An Event
                    </Link>
                  </li>
                </ul>
              </div>{' '}
            </div>
            <div class="col-lg-3 col-md-6 ">
              <p class="text-brand text-capitalize font-weight-bold">
                find events
              </p>
              <div class="menu-footer-third-menu-container">
                <ul
                  id="menu-footer-third-menu"
                  class="list-unstyled mb-4 mb-lg-0 mb-xl-0"
                >
                  <li
                    id="menu-item-132"
                    class="text-capitalize menu-item menu-item-type-custom menu-item-object-custom menu-item-132"
                  >
                    <Link to="/">Explore Events Near You</Link>
                  </li>
                </ul>
              </div>{' '}
            </div>
            <div class="col-lg-2 col-md-6 ">
              <p class="text-brand text-capitalize font-weight-bold">
                connect with us
              </p>
              <div class="menu-footer-fourth-menu-container">
                <ul
                  id="menu-footer-fourth-menu"
                  class="list-unstyled mb-4 mb-lg-0 mb-xl-0"
                >
                  <li
                    id="menu-item-356"
                    class="menu-item menu-item-type-post_type menu-item-object-page menu-item-356"
                  >
                    <a
                      target="_blank"
                      href="http://support.farechild.com/support/home"
                    >
                      Contact Support
                    </a>
                  </li>
                  <li
                    id="menu-item-364"
                    class="menu-item menu-item-type-custom menu-item-object-custom menu-item-364"
                  >
                    <a
                      href="https://www.facebook.com/thefarechild"
                      target="_blank"
                    >
                      <i class="fab fa-facebook-f font-size-16"></i>
                      &nbsp;&nbsp;&nbsp;Facebook
                    </a>
                  </li>
                  <li
                    id="menu-item-365"
                    class="menu-item menu-item-type-custom menu-item-object-custom menu-item-365"
                  >
                    <a
                      target="_blank"
                      href="https://www.instagram.com/thefarechild/"
                    >
                      <i class="fab fa-instagram font-size-16"></i>
                      &nbsp;&nbsp;Instagram
                    </a>
                  </li>
                  <li
                    id="menu-item-366"
                    class="menu-item menu-item-type-custom menu-item-object-custom menu-item-366"
                  >
                    <a
                      target="_blank"
                      href="https://www.youtube.com/channel/UCcuu-gnTQI0zXQa61baeeMw/featured"
                    >
                      <i class="fab fa-youtube font-size-16"></i>&nbsp;YouTube
                    </a>
                  </li>
                </ul>
              </div>{' '}
            </div>
          </div>
        </div>
        <div class="footer-privacy d-sm-flex justify-content-center justify-content-sm-between pt-4 pb-4">
          <span class="text-center text-sm-left d-block d-sm-inline-block">
            © 2021 Farechild Events{' '}
          </span>
        </div>
      </footer>
    );
  }
}

export default EventFooter;
