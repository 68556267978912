import promise from 'bluebird';
import ResponseFilter from '../config/response-handler';
import { usersConstants } from '../_constants';
import { usersService } from '../_services';

export const usersActions = {
  signup,
  login,
  forgot,
  signupWithFacebook,
  logout,
  reset,
  createEvent,
  ticket, 
};

function signup(params) {
  return function (dispatch) {
    return new promise(function (resolve, reject) {
      dispatch(request(params));

      usersService
        .signup(params)
        .then((response) => {
          resolve(response);
          let { resData } = ResponseFilter(response);
          if (resData.statusCode === 1) {
            localStorage.setItem(
              'isPayoutExists',
              resData.responseData.userProfile.isPayoutExists
            );
            localStorage.setItem(
              'isAccountDetailsExists',
              resData.responseData.userProfile.isAccountDetailsExists
            );
            localStorage.setItem('role', resData.responseData.userProfile.role);
            localStorage.setItem(
              'accessToken',
              resData.responseData.accessToken
            );
            localStorage.setItem(
              'profileImage',
              resData.responseData.userProfile.profileImage
            );
            dispatch(success(resData));
          }
        })
        .catch((err) => {
          reject(err);
          dispatch(failure(err));
        });
    });
  };
  function request(signupRequest) {
    return { type: usersConstants.SIGNUP_REQUEST, signupRequest };
  }
  function success(signupSuccess) {
    return { type: usersConstants.SIGNUP_SUCCESS, signupSuccess };
  }
  function failure(signupFail) {
    return { type: usersConstants.SIGNUP_ERROR, signupFail };
  }
}

function signupWithFacebook(params) {
  return function (dispatch) {
    return new promise(function (resolve, reject) {
      dispatch(request(params));
      usersService
        .signupWithFacebook(params)
        .then((response) => {
          resolve(response);
          let { resData } = ResponseFilter(response);
          if (resData.statusCode === 1) {
            localStorage.setItem(
              'accessToken',
              resData.responseData.accessToken
            );
            dispatch(success(resData));
          }
        })
        .catch((err) => {
          reject(err);
          dispatch(failure(err));
        });
    });
  };
  function request(signupRequest) {
    return { type: usersConstants.SIGNUP_REQUEST, signupRequest };
  }
  function success(signupSuccess) {
    return { type: usersConstants.SIGNUP_SUCCESS, signupSuccess };
  }
  function failure(signupFail) {
    return { type: usersConstants.SIGNUP_ERROR, signupFail };
  }
}

function login(params) {
  return function (dispatch) {
    return new promise(function (resolve, reject) {
      dispatch(request(params));
      usersService
        .login(params)
        .then((response) => {
          resolve(response);
          let { resData } = ResponseFilter(response);

          if (resData.statusCode === 1) {
            localStorage.setItem(
              'accessToken',
              resData.responseData.accessToken
            );
            dispatch(success(resData));
          }
        })
        .catch((err) => {
          reject(err);
          dispatch(failure(err));
        });
    });
  };
  function request(loginRequest) {
    return { type: usersConstants.LOGIN_REQUEST, loginRequest };
  }
  function success(loginSuccess) {
    return { type: usersConstants.LOGIN_SUCCESS, loginSuccess };
  }
  function failure(loginFail) {
    return { type: usersConstants.LOGIN_ERROR, loginFail };
  }
}

function forgot(params) {
  return function (dispatch) {
    return new promise(function (resolve, reject) {
      dispatch(request(params));
      usersService
        .forgot(params)
        .then((response) => {
          resolve(response);
          let { resData } = ResponseFilter(response);
          dispatch(success(resData));
        })
        .catch((err) => {
          reject(err);
          dispatch(failure(err));
        });
    });
  };
  function request(forgotRequest) {
    return { type: usersConstants.FORGOT_REQUEST, forgotRequest };
  }
  function success(forgotSuccess) {
    return { type: usersConstants.FORGOT_SUCCESS, forgotSuccess };
  }
  function failure(forgotFail) {
    return { type: usersConstants.FORGOT_ERROR, forgotFail };
  }
}

function logout(params) {
  return function (dispatch) {
    return new promise(function (resolve, reject) {
      usersService
        .logout(params)
        .then((response) => {
          resolve(response);
          let { resData } = ResponseFilter(response);
          dispatch(success(resData));
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  function success(logoutSuccess) {
    return { type: usersConstants.LOGOUT_SUCCESS, logoutSuccess };
  }
}

function reset(params) {
  return function (dispatch) {
    return new promise(function (resolve, reject) {
      dispatch(request(params));
      usersService
        .reset(params)
        .then((response) => {
          resolve(response);
          let { resData } = ResponseFilter(response);
          dispatch(success(resData));
        })
        .catch((err) => {
          reject(err);
          dispatch(failure(err));
        });
    });
  };
  function request(resetRequest) {
    return { type: usersConstants.RESET_REQUEST, resetRequest };
  }
  function success(resetSuccess) {
    return { type: usersConstants.RESET_SUCCESS, resetSuccess };
  }
  function failure(resetFail) {
    return { type: usersConstants.RESET_ERROR, resetFail };
  }
}

function createEvent(obj, params) {
  return function (dispatch) {
    return new promise(function (resolve, reject) {
      dispatch(request(params));
      usersService
        .createEvent(obj, params)
        .then((response) => {
          resolve(response);
          let { resData } = ResponseFilter(response);
          if (resData.statusCode === 1) {
            dispatch(success(resData));
          }
        })
        .catch((err) => {
          reject(err);
          dispatch(failure(err));
        });
    });
  };
  function request(createRequest) {
    return { type: usersConstants.CREATE_REQUEST, createRequest };
  }
  function success(createSuccess) {
    return { type: usersConstants.CREATE_SUCCESS, createSuccess };
  }
  function failure(createFail) {
    return { type: usersConstants.CREATE_ERROR, createFail };
  }
}

function ticket(obj, params) {
  return function (dispatch) {
    return new promise(function (resolve, reject) {
      dispatch(request(params));
      usersService
        .ticket(obj, params)
        .then((response) => {
          resolve(response);
          let { resData } = ResponseFilter(response);

          if (resData.statusCode === 1) {
            dispatch(success(resData));
          }
        })
        .catch((err) => {
          reject(err);
          dispatch(failure(err));
        });
    });
  };

  function request(ticketRequest) {
    return { type: usersConstants.TICKET_REQUEST, ticketRequest };
  }
  function success(ticketSuccess) {
    return { type: usersConstants.TICKET_SUCCESS, ticketSuccess };
  }
  function failure(ticketFail) {
    return { type: usersConstants.TICKET_ERROR, ticketFail };
  }
}
