/* eslint-disable no-undef */
import React from 'react';
import { Link } from 'react-router-dom';
import { usersActions } from '../../_actions';
import { connect } from 'react-redux';
import axios from 'axios';
import { isValidEmail } from '../../utils';
import ResponseFilter from '../../config/response-handler';
import { Loader } from '../../config/Svgicon';

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      errors: '',
      redirect: false,
      email: '',
      emailErrorMsg: '',
    };
    this.redirectTo = this.redirectTo.bind(this);
  }

  redirectTo() {
    this.props.history.push({
      pathname: '/success-message',
    });
  }

  handleValidation = () => {
    let validate = true;
    let { email } = this.state;

    if (email === '' || email === undefined) {
      validate = false;
      this.setState({
        emailErrorMsg: 'Please enter your email address.',
      });
    } else if (!isValidEmail(String(email.toLowerCase()))) {
      validate = false;
      this.setState({ emailErrorMsg: 'Please enter a valid email address.' });
    } else {
      this.setState({
        emailErrorMsg: '',
      });
    }

    return validate;
  };

  handleSubmit = (e) => {
    let { email } = this.state;
    this.setState({ redirect: false, errors: '' });
    e.preventDefault();
    let obj = {
      email: email.toLowerCase(),
    };

    let { dispatch } = this.props;
    if (this.handleValidation()) {
      this.setState({ redirect: false, loader: true });
      dispatch(usersActions.forgot(obj)).then((data) => {
        ResponseFilter(data);
        if (data.data.statusCode === 1) {
          this.setState({ loader: false });
          let accessToken = data.data.responseData.accessToken;
          axios.defaults.headers.common['accessToken'] = `${accessToken}`;
          this.redirectTo();
        } else {
          this.setState({
            loader: false,
            errors: data.data.error.responseMessage,
            errorCode: data.data.error.errorCode,
          });
        }
      });
    }
  };

  handleChange = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        [e.target.name + 'ErrorMsg']: '',
        errorMessage: '',
      },
      () => this.setState({ errors: '' })
    );
  };

  render() {
    let { loader, errors, emailErrorMsg } = this.state;
    return (
      <div className="base-color-option-2">
        {loader ? <Loader /> : null}
        <div className="main-container">
          <div className="container-fluid page-body-wrapper full-page-wrapper">
            <div className="user-login login-v2">
              <div className="row no-gutters">
                <div className="col-lg-12">
                  <div className="logo-container text-center">
                    <Link className="brand-logo login-page" to="/">
                      {' '}
                      <img
                        src={require('../../assets/images/mobile-logo.png')}
                        alt=""
                        title=""
                      />
                    </Link>
                  </div>
                </div>
              </div>

              <div className="justify-content-center align-self-center">
                <div className="center">
                  <div className="card-body cm_card card">
                    <div className="">
                      <div className="user-login-content">
                        <h4 className="login-title mt-2 cm_bold">
                          FORGOT PASSWORD?
                        </h4>
                        <p className="text-muted mb-4">
                          Enter your email and we'll send you instructions on
                          how to reset your password.
                        </p>

                        <form
                          className="needs-validation"
                          onSubmit={this.handleSubmit}
                        >
                          <div className="form-group inner-addon">
                            <input
                              type="email"
                              className="form-control"
                              id="exampleInputEmail1"
                              aria-describedby="emailHelp"
                              placeholder="Email"
                              maxLength="100"
                              autoComplete="off"
                              onChange={this.handleChange}
                              value={this.state.email}
                              name="email"
                            />
                            {errors ? (
                              <div className="error"> {errors} </div>
                            ) : null}
                            {emailErrorMsg ? (
                              <div className="error">{emailErrorMsg}</div>
                            ) : null}
                          </div>

                          <div className="text-center mb-3">
                            <button
                              type="submit"
                              className="btn btn-primary btn-block mt-3 mb-5"
                            >
                              SUBMIT
                            </button>
                          </div>
                          <div className="text-center">
                            <div className="text-muted  text-uppercase">
                              <Link to="/login" className="link">
                                Back to sign in
                              </Link>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  const { props, login, user } = state;
  return {
    props,
    login,
    user,
  };
}

export default connect(mapStateToProps)(ForgotPassword);
