/* eslint-disable no-undef */
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import TopBar from '../template/TopBar';
import Footer from '../template/Footer';
import SideNav from '../template/SideNav';
import Coupon from '../eventEditPage/Coupon';
import Attendee from '../eventEditPage/Attendee';
import EditDetails from '../eventEditPage/EditDetails';
import EditTicket from '../eventEditPage/EditTicket';
import UpdateEdit from '../eventEditPage/UpdateEdit';
import { Tab, Tabs } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ReactQuill from 'react-quill'; // ES6
import 'react-quill/dist/quill.snow.css';
import { usersService } from '../../_services/usersService';
import _, { toLower } from 'lodash';
import ResponseFilter from '../../config/response-handler';
import { message, Empty } from 'antd';
import {
  University,
  Details,
  Updates,
  AttendeeIcon,
  Checkout,
  Tickets,
  CouponIcon,
  Privacy,
  Payout,
  Plus,
  CrossIcon,
  Email,
  UrlIcon,
} from '../../config/Svgicon';
var parse = require('html-react-parser');
class EditEvent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      eventName: '',
      payoutList: [],
      ticketsList: [],
      eventPayoutId: '',
      phone: 0,
      gender: 0,
      dob: 0,
      isCertificateAvailable: 0,
      page: 1,
      address: 0,
      multiDays: '',
      endTimeStamp: '',
      startTimeStamp: '',
      city: 0,
      state: 0,
      zipcode: 0,
      eventType: '',
      disabledBtn: false,
      successPageMessage: '',
      theme: 'snow',
      msgErrorMsg: '',
      fieldNameErrorMsg: '',
      eventId: this.props.match.params.id,
      passwordErrorMsg: '',
      password: '',
      publicType: false,
      publicEventCheck: false,
      noChecK: true,
      privateEventCheck: false,
      privateEventProtectedCheck: false,
      privateTypeLink: false,
      privatetype: false,
      privateTypePassword: false,
      activeTab: 1,
      successPageMessageValid: '',
      openEventPasswordPopup: false,
      fieldName: '',
      checkoutCustomFields: [],
      checkoutFields: {},
      emailNotification: 0,
      customUrlSave: '',
      customUrlSaveErrorMsg: '',
      customURlShow: '',
    };
    this.updateCheckBox = this.updateCheckBox.bind(this);
    this.handleChangeEditor = this.handleChangeEditor.bind(this);
  }

  openAddFieldsPopup = () => {
    this.setState({ openEventPasswordPopup: true });
  };
  closeAddFieldsPopup = () => {
    this.setState({
      openEventPasswordPopup: false,
      fieldName: '',
      fieldNameErrorMsg: '',
    });
  };

  removeClick(i) {
    let checkoutCustomFields = [...this.state.checkoutCustomFields];
    checkoutCustomFields.splice(i, 1);
    this.setState({ checkoutCustomFields });
  }

  validateCheckoutFieldName = () => {
    let validate = true;
    let { checkoutCustomFields, fieldName, checkoutFields } = this.state;
    let found = checkoutCustomFields.some((el) => el.name === fieldName);
    if (found === true) {
      validate = false;
      this.setState({
        fieldNameErrorMsg: 'This checkout field is already exists.',
      });
    }
    if (
      fieldName === 'Street Address' ||
      fieldName.replace(/\s/g, '') === 'City' ||
      fieldName.replace(/\s/g, '') === 'city' ||
      fieldName.replace(/\s/g, '') === 'State' ||
      fieldName.replace(/\s/g, '') === 'state' ||
      fieldName === 'Zip Code' ||
      fieldName === 'zip code' ||
      fieldName === 'Zip code' ||
      fieldName.replace(/\s/g, '') === 'address' ||
      fieldName.replace(/\s/g, '') === 'Address' ||
      fieldName.replace(/\s/g, '') === 'zipcode' ||
      fieldName.replace(/\s/g, '') === 'zip' ||
      fieldName.replace(/\s/g, '') === 'Zipcode' ||
      fieldName.replace(/\s/g, '') === 'Zip' ||
      fieldName.replace(/\s/g, '') === 'name' ||
      fieldName.replace(/\s/g, '') === 'Name' ||
      fieldName.replace(/\s/g, '') === 'firstname' ||
      fieldName.replace(/\s/g, '') === 'firstName' ||
      fieldName === 'First Name' ||
      fieldName === 'first name' ||
      fieldName === 'First name' ||
      fieldName.replace(/\s/g, '') === 'lastname' ||
      fieldName.replace(/\s/g, '') === 'lastName' ||
      fieldName === 'Last Name' ||
      fieldName === 'last name' ||
      fieldName === 'Last name' ||
      fieldName.replace(/\s/g, '') === 'Email' ||
      fieldName.replace(/\s/g, '') === 'email' ||
      fieldName === 'Email Address' ||
      fieldName === 'Email address' ||
      fieldName === 'email address' ||
      fieldName === 'email Address' ||
      fieldName.replace(/\s/g, '') === 'emailAddress' ||
      fieldName.replace(/\s/g, '') === 'emailaddress'
    ) {
      validate = false;
      this.setState({
        fieldNameErrorMsg: 'This checkout field is already exists.',
      });
    }

    if (fieldName in checkoutFields) {
      validate = false;
      this.setState({
        fieldNameErrorMsg: 'This checkout field is already exists.',
      });
    }

    if (!fieldName.replace(/\s/g, '').length) {
      validate = false;
      this.setState({
        fieldNameErrorMsg: 'Please enter checkout field name.',
      });
    }
    if (fieldName.trim() === '' || fieldName.trim() === undefined) {
      validate = false;
      this.setState({
        fieldNameErrorMsg: 'Please enter checkout field name.',
      });
    }
    return validate;
  };
  addClick() {
    if (this.validateCheckoutFieldName()) {
      this.setState((prevState) => ({
        checkoutCustomFields: [
          ...prevState.checkoutCustomFields,
          {
            name: this.state.fieldName,
            value: '',
            checked: 0,
          },
        ],
      }));
      this.closeAddFieldsPopup();
    }
  }

  handleChangeFieldChecked(i, e) {
    let { checkoutCustomFields } = this.state;
    e.target.checked
      ? (checkoutCustomFields[i].checked = 1)
      : (checkoutCustomFields[i].checked = 0);
    this.setState({ checkoutCustomFields });
  }
  handleChangeEditor(content, delta, source, editor) {
    const text = editor.getText(content);
    this.setState({
      successPageMessage: content,
      successPageMessageValid: text,
      msgErrorMsg: '',
    });
  }
  checkCharacterCount = (event) => {
    if (
      this.state.successPageMessageValid.length > 1000 &&
      event.key !== 'Backspace'
    ) {
      event.preventDefault();
    }
  };
  componentDidMount() {
    if (
      localStorage.getItem('isAccountDetailsExists') == 'false' &&
      localStorage.getItem('role') != 2
    ) {
      this.props.history.push('/account-settings');
    }

    if (localStorage.getItem('role') == 4) {
      this.props.history.push('/admin');
    }

    this.getPayoutDetails();
    this.getEventDetails();
    this.getTicketList();
    if (localStorage.getItem('role') == 2) {
      if(!localStorage.getItem('promoterId')||localStorage.getItem('promoterId')==''){
        this.props.history.push('/events-feed');
      } else {
        this.setState({isAssistant:true,activeTab:'3'});
      }
    } else if (localStorage.getItem('activeTab') === '2') {
      this.setState({ activeTab: 5 });
    }
    document.title = 'Edit Event | Farechild';
  }
  handleTabChange = (e) => {
    localStorage.removeItem('activeTab');
    this.setState({ activeTab: e });
  };

  getTicketList = () => {
    let { search, sortOrder, sortBy, page } = this.state;
    let obj = {
      eventId: this.props.match.params.id,
      search: search,
      sortOrder: sortOrder,
      sortBy: sortBy,
      page: page - 1,
    };

    usersService.ticketList(obj).then((res) => {
      let { status, resData } = ResponseFilter(res);

      if (status) {
        this.setState({
          ticketsList: resData.result,
          totalCount: resData.count,
        });
      }
    });
  };
  getEventDetails = () => {
    let obj = {
      eventId: this.props.match.params.id,
    };

    usersService.eventDetails(obj).then((res) => {
      let { status, resData } = ResponseFilter(res);

      if (status) {
        let {
          eventName,
          privateType,
          typeOfEvent,
          eventPassword,
          checkoutFields,
          successPageMessage,
          eventPayout,
          checkoutCustomFields,
          emailNotification,
          customUrl,
          multiDays,
          endTimeStamp,
          startTimeStamp,
        } = resData.result;
        this.setState({
          multiDays: multiDays,
          endTimeStamp: endTimeStamp,
          startTimeStamp: startTimeStamp,
          customUrlSave: '',
          customURlShow: customUrl,
          eventName: eventName,
          checkoutFields: checkoutFields,
          emailNotification: emailNotification,
          publicType: typeOfEvent === 1 ? true : false,
          privateTypePassword: eventPassword ? true : false,
          privateTypeLink: privateType
            ? privateType[0] === 1
              ? true
              : false
            : '',
          successPageMessage: successPageMessage,
          eventPayoutId: eventPayout,
        });
        this.setState({
          checkoutCustomFields: checkoutCustomFields
            ? JSON.parse(checkoutCustomFields)
            : [],
        });
      }
    });
  };

  getPayoutDetails = () => {
    let { limit, page } = this.state;
    let params = `createdBy=${localStorage.getItem(
      'userId'
    )}&limit=${limit}&page=${page - 1}`;

    usersService.getPayout(params).then((res) => {
      let { status, resData } = ResponseFilter(res);
      if (status) {
        this.setState({
          payoutList: resData.result,
        });
      }
    });
  };

  clearSuccessPageMessage = () => {
    this.setState({
      successPageMessage: '',
    });
  };

  handleValidation = () => {
    let validate = true;
    let { successPageMessage } = this.state;
    if (successPageMessage === '' || successPageMessage === undefined) {
      validate = false;
      this.setState({
        msgErrorMsg: 'Please enter success message.',
      });
    } else {
      this.setState({
        msgErrorMsg: '',
      });
    }

    return validate;
  };

  handleSuccessMessage = (event) => {
    event.preventDefault();
    let self = this;
    if (this.handleValidation()) {
      let params = {
        eventId: this.props.match.params.id,
        successPageMessage: this.state.successPageMessage,
      };
      const accessToken = localStorage.getItem('accessToken');
      this.setState({ disabledBtn: true });
      usersService.successPageMessage(params, accessToken).then((res) => {
        ResponseFilter(res);
        if (res.data.statusCode === 1) {
          this.getEventDetails();

          message.success('The Message has been successfully added!.');
          self.setState({
            disabledBtn: false,
          });
        } else {
          self.setState({
            disabledBtn: false,
          });
        }
      });
    }
  };

  handleManageEventPayout = (event) => {
    event.preventDefault();
    let self = this;
    {
      let params = {
        eventId: this.props.match.params.id,
        eventPayout: this.state.eventPayoutId,
      };
      const accessToken = localStorage.getItem('accessToken');
      this.setState({ disabledBtn: true });
      usersService.manageEventPayout(params, accessToken).then((res) => {
        ResponseFilter(res);
        if (res.data.statusCode === 1) {
          message.success('The event payout successfully updated.');
          self.setState({
            disabledBtn: false,
          });
        } else {
          self.setState({
            disabledBtn: false,
          });
        }
      });
    }
  };

  handleChangeId = (eventPayoutId) => {
    this.setState({
      eventPayoutId: eventPayoutId,
    });
  };

  handleCheckoutFieldsSubmit = () => {
    let self = this;
    {
      let params = {
        eventId: this.props.match.params.id,
        checkoutFields: JSON.stringify(this.state.checkoutFields),
        checkoutCustomFields: JSON.stringify(this.state.checkoutCustomFields),
      };

      const accessToken = localStorage.getItem('accessToken');
      this.setState({ disabledBtn: true });
      usersService.checkoutFields(params, accessToken).then((res) => {
        ResponseFilter(res);
        if (res.data.statusCode === 1) {
          this.getEventDetails();
          message.success('Checkout fields managed successfully.');
          self.setState({
            disabledBtn: false,
          });
        } else {
          self.setState({
            disabledBtn: false,
          });
        }
      });
    }
  };

  redirectTo = () => {
    let eventId = this.props.match.params.id;
    localStorage.setItem('successPageMessage', this.state.successPageMessage);

    switch (process.env.REACT_APP_ENV) {
      case 'stag':
      case 'staging':
        const url1 = `https://stag.farechild.com/success-page/${eventId}`;
        window.open(url1, '_blank');
        break;

      case 'dev':
      case 'development':
        const url2 = `https://dev.farechild.com/success-page/${eventId}`;
        window.open(url2, '_blank');

        break;
      case 'prod':
      case 'production':
        const url3 = `https://events.farechild.com/success-page/${eventId}`;
        window.open(url3, '_blank');

        break;
      default:
        const url4 = `http://localhost:3000/success-page/${eventId}`;
        window.open(url4, '_blank');
    }
  };

  handleCheckBox = (e, key) => {
    let { checkoutFields } = this.state;
    e.target.checked ? (checkoutFields[key] = 1) : (checkoutFields[key] = 0);
    this.setState({ checkoutFields });
  };

  handleRemoveCheckBox = (key) => {
    let { checkoutFields } = this.state;
    delete checkoutFields[key];
    this.setState({ checkoutFields });
  };
  handleEditPrivacyEvent = () => {
    let { publicType, privateTypePassword, privateTypeLink, password } =
      this.state;
    let typeOfEvent = {
      type: publicType ? 1 : privateTypePassword ? 2 : privateTypeLink ? 2 : '',
      privateType:
        privateTypePassword && !privateTypeLink
          ? [2]
          : !privateTypePassword && privateTypeLink
          ? [1]
          : privateTypePassword && privateTypeLink
          ? [1, 2]
          : '',
      eventPassword: privateTypePassword ? password : '',
    };
    if (this.validationEventType()) {
      let params = {
        eventId: this.props.match.params.id,
        typeOfEvent: JSON.stringify(typeOfEvent),
      };
      let token = localStorage.getItem('accessToken');
      this.setState({ disabledBtn: true });

      usersService.updatePrivacy(params, token).then((data) => {
        ResponseFilter(data);
        if (data.data.statusCode === 1) {
          message.success('Privacy updated Successfully!', 7);
          this.setState({ disabledBtn: false });
          this.getEventDetails();
        } else {
          this.getEventDetails();
          this.setState({
            errors: data.data.error.responseMessage,
            miniLoader: false,
            disabledBtn: false,
          });
        }
      });
    }
  };
  validationEventType = () => {
    var validate = true;
    let { privateTypePassword } = this.state;

    if (privateTypePassword) {
      if (
        this.state.password.trim() === '' ||
        this.state.password.trim() === undefined
      ) {
        validate = false;
        this.setState({
          passwordErrorMsg: 'Please enter your password.',
        });
      } else if (this.state.password.trim().length <= 5) {
        validate = false;

        this.setState({
          passwordErrorMsg: ' Please enter minimum 6 characters.',
        });
      }
    }

    return validate;
  };
  updateCheckBox(event) {
    this.setState({
      publicType: !this.state.publicType,
      password: '',
      privateTypePassword: false,
      privateTypeLink: false,
      privatetype: false,
    });
  }
  handleInputChange = (event) => {
    if (event.target.name === 'privateTypeLink') {
      this.setState({
        privateTypeLink: !this.state.privateTypeLink,
        publicType: false,
      });
    }
    if (event.target.name === 'privateTypePassword') {
      this.setState({
        privateTypePassword: !this.state.privateTypePassword,
        publicType: false,
        privatetype: !this.state.privatetype,
      });
    }
  };

  handleEventEmailChecked = (e) => {
    this.setState({ emailNotification: e.target.checked ? 1 : 0 });
  };

  handleEventEmail = () => {
    let obj = {
      eventId: this.props.match.params.id,
      emailNotification: this.state.emailNotification,
    };
    let token = localStorage.getItem('accessToken');
    this.setState({ disabledBtn: true });
    usersService.eventEmailNotification(obj, token).then((data) => {
      ResponseFilter(data);
      if (data.data.statusCode === 1) {
        window.scrollTo(0, 0);
        this.getEventDetails();
        message.success('Updated Successfully!!');
      } else {
        message.success('Something went wrong!');
        window.scrollTo(0, 0);
        this.getEventDetails();
        this.setState({
          errors: data.data.error.responseMessage,
        });
      }
    });
  };

  handleEventCustomURL = () => {
    var checkurl = this.state.customUrlSave.slice(-'.farechild.com'.length);

    if (checkurl == '.farechild.com') {
      var newUrl = this.state.customUrlSave.substring(
        '.farechild.com'.length + 1,
        -1
      );
    } else {
      var newUrl = this.state.customUrlSave;
    }

    var name = this.state.eventName.replace(/ /g, '-');
    var eventName = toLower(name);
    let obj = {
      eventId: this.props.match.params.id,
      name: newUrl,
      eventName: encodeURIComponent(eventName ? eventName : 'farechild event'),
    };

    this.setState({ disabledBtn: true });
    usersService.dnsCreate(obj).then((data) => {
      ResponseFilter(data);
      if (data.data.responseData.success == false) {
        // message.success('Something went wrong!');
        window.scrollTo(0, 0);
        this.getEventDetails();

        if (data.data.responseData.errors[0].code == 81057) {
          this.setState({
            customUrlSaveErrorMsg:
              'URL already exists. Please choose a different URL.',
          });
        } else if (data.data.responseData.errors[0].code == 1004) {
          this.setState({
            customUrlSaveErrorMsg: "Invalid URL. Please enter a valid URL.",
          });
        } else {
          this.setState({
            customUrlSaveErrorMsg: data.data.responseData.errors[0].message,
          });
        }
        this.setState({
          disabledBtn: false,
        });
      } else {
        window.scrollTo(0, 0);
        this.getEventDetails();
        this.setState({ disabledBtn: false, customUrlSave: '' });
        message.success('Save Successfully!!');
      }
    });
  };

  handleChangeURL = (e) => {
    const { name } = e.target;
    var value = e.target.value.replace(/\s/g, '');
    this.setState({
      customUrlSave: value,
      [e.target.name + 'ErrorMsg']: '',
    });
  };

  handleChange = (e) =>
    this.setState({
      [e.target.name]: e.target.value,
      [e.target.name + 'ErrorMsg']: '',
      inputErrorMsg: '',
    });

  openEventPage = (eventId, name1) => {
    var name = name1.replace(/ /g, '-');
    var eventName = toLower(name);
    switch (process.env.REACT_APP_ENV) {
      case 'stag':
      case 'staging':
        const url1 = `https://stag.farechild.com/event/${eventId}/${encodeURIComponent(
          eventName ? eventName : 'farechild event'
        )}`;
        window.open(url1, '_blank');
        break;

      case 'dev':
      case 'development':
        const url2 = `https://dev.farechild.com/event/${eventId}/${encodeURIComponent(
          eventName ? eventName : 'farechild event'
        )}`;
        window.open(url2, '_blank');

        break;
      case 'prod':
      case 'production':
        const url3 = `https://events.farechild.com/event/${eventId}/${encodeURIComponent(
          eventName ? eventName : 'farechild event'
        )}`;

        window.open(url3, '_blank');

        break;
      default:
        const url4 = `http://localhost:3000/event/${eventId}/${encodeURIComponent(
          eventName ? eventName : 'farechild event'
        )}`;
        window.open(url4, '_blank');
    }
  };

  render() {
    let checkoutFieldsShow = {
      'Street Address': 1,
      City: 1,
      State: 1,
      'Zip Code': 1,
      'First Name': 1,
      'Last Name': 1,
      'Email Address': 1,
    };
    let {
      payoutList,
      eventId,
      msgErrorMsg,
      fieldNameErrorMsg,
      disabledBtn,
      passwordErrorMsg,
      openEventPasswordPopup,
      checkoutFields,
      customUrlSaveErrorMsg,
    } = this.state;

    let module = {
      toolbar: [
        [{ header: [1, 2, false] }],
        [{ size: [] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['link'],
        ['clean'],
      ],
    };
    let format = [
      'header',
      'font',
      'size',
      'bold',
      'italic',
      'underline',
      'strike',
      'blockquote',
      'list',
      'bullet',
      'indent',
      'link',
    ];

    return (
      <div className="dark-sidebar">
        <div className="main-container">
          <div className="container-fluid page-body-wrapper">
            <nav className="navbar fixed-top">
              <div className="navbar-menu-container d-flex align-items-center justify-content-center">
                <div className="sub-heading">
                  <h4 className="page-title ">MY EVENTS</h4>
                </div>
                <div className="text-center navbar-brand-container align-items-center justify-content-center">
                  <a className="brand-logo" href="/">
                    <img
                      src={require('../../assets/images/mobile-logo.png')}
                      alt=""
                      className="mb-3"
                    />
                  </a>
                </div>

                <TopBar {...this.props} />
              </div>
            </nav>

            <SideNav />
            <div className="main-panel">
              <div className="content-wrapper manage-event">
                <div className="row">
                  <div className="col-md-12">
                    <div className="card card-margin">
                      <div className="card-header card-header-block">
                        <div className="col-md-8">
                          <h5 className="card-title">Edit Your Event</h5>
                        </div>
                        <div className="col-md-4 text-sm-right mt-3 mt-sm-0">
                          <Link
                            onClick={() =>
                              this.openEventPage(eventId, this.state.eventName)
                            }
                            className="btn btn-sm btn-dark"
                          >
                            View Event Page
                          </Link>
                        </div>
                      </div>
                      <div className="card-body">
                        <Tabs
                          style={{display:this.state.assistant?'none':''}}
                          className={"edit-event-tabs "+(this.state.isAssistant?"hidden":"")}
                          activeKey={this.state.activeTab}
                          onSelect={(e) => this.handleTabChange(e)}
                        >
                          {/* ...........................................Tab 1............................................ */}
                          <Tab
                            eventKey={1}
                            title={
                              <span className="tab-head">
                                <Details /> Details
                              </span>
                            }
                          >
                            <EditDetails
                              eventId={eventId}
                              eventDetailsRefresh={this.getEventDetails}
                            />
                          </Tab>
                          {/* ..........................................Tab 2............................................ */}

                          <Tab
                            eventKey={2}
                            title={
                              <span className="tab-head">
                                <Updates /> Updates
                              </span>
                            }
                          >
                            <UpdateEdit eventId={eventId} />
                          </Tab>
                          {/* ....................................................Tab 3.............................................. */}

                          <Tab
                            eventKey={3}
                            title={
                              <span className="tab-head">
                                <AttendeeIcon /> Attendees
                              </span>
                            }
                          >
                            <Attendee
                              eventId={eventId}
                              ticketsList={this.state.ticketsList}
                            />
                          </Tab>
                          {/* ................................................Tab 4.................................... */}
                          <Tab
                            eventKey={4}
                            title={
                              <span className="tab-head">
                                <Checkout /> Checkout
                              </span>
                            }
                          >
                            <div
                              className="tab-pane fade show active"
                              id="details"
                              role="tabpanel"
                              aria-labelledby="details"
                            >
                              <div className="card">
                                <div className="card-header card-header-block">
                                  <div className="col-md-8">
                                    <h5 className="card-title">
                                      {' '}
                                      Manage Required Checkout Fields
                                    </h5>
                                  </div>
                                  <div className="col-md-4 text-sm-right mt-3 mt-sm-0">
                                    <button
                                      className="btn btn-sm btn-primary mr-2 mt-1"
                                      onClick={() => this.openAddFieldsPopup()}
                                    >
                                      <Plus /> Add checkout fields
                                    </button>
                                  </div>
                                </div>
                                <div className="card-body">
                                  <div className="row">
                                    {Object.entries(checkoutFieldsShow).map(
                                      ([key, val], i) => (
                                        <div className="col-md-4">
                                          <div className="checkbox">
                                            <input
                                              id="checkbox"
                                              type="checkbox"
                                              disabled
                                              checked={true}
                                            />
                                            <label className="checkbox-checkoutFields">
                                              {key}
                                            </label>
                                          </div>
                                        </div>
                                      )
                                    )}
                                    {Object.entries(checkoutFields).map(
                                      ([key, val], i) => (
                                        <>
                                          {key == 'phone' ||
                                          key == 'gender' ||
                                          key == 'dob'  || 
                                          key == 'isCertificateAvailable' ? (
                                            <div className="col-md-4">
                                              <div className="checkbox">
                                                {val == 1 ? (
                                                  ''
                                                ) : (
                                                  <span
                                                    title="Remove Field"
                                                    class="mr-2"
                                                    onClick={() =>
                                                      this.handleRemoveCheckBox(
                                                        key
                                                      )
                                                    }
                                                  >
                                                    <CrossIcon />
                                                  </span>
                                                )}

                                                <input
                                                  id="checkbox"
                                                  type="checkbox"
                                                  name="address"
                                                  value={key}
                                                  checked={
                                                    val === 1 ? true : false
                                                  }
                                                  onChange={(e) =>
                                                    this.handleCheckBox(e, key)
                                                  }
                                                />
                                                <label className="checkbox-checkoutFields">
                                                  {key !== 'isCertificateAvailable' ? key : 'Is Vaccine certificate required?'}
                                                </label>
                                              </div>
                                            </div>
                                          ) : (
                                            ''
                                          )}
                                        </>
                                      )
                                    )}

                                    {this.state.checkoutCustomFields.map(
                                      (data, i) => {
                                        return (
                                          <div className="col-md-4">
                                            <div className="checkbox">
                                              {data.checked === 1 ? (
                                                ''
                                              ) : (
                                                <span
                                                  title="Remove Field"
                                                  class="mr-2"
                                                  onClick={this.removeClick.bind(
                                                    this,
                                                    i
                                                  )}
                                                >
                                                  <CrossIcon />
                                                </span>
                                              )}

                                              <input
                                                id="checkbox"
                                                type="checkbox"
                                                value={data.checked || ''}
                                                onChange={(e) =>
                                                  this.handleChangeFieldChecked(
                                                    i,
                                                    e
                                                  )
                                                }
                                                checked={
                                                  data.checked === 1
                                                    ? true
                                                    : false
                                                }
                                              />

                                              <label className="checkbox-checkoutFields">
                                                {data.name}
                                              </label>
                                            </div>
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                  <div className="pull-right m-t-40">
                                    <button
                                      className="btn btn-light "
                                      onClick={this.getEventDetails}
                                    >
                                      Cancel
                                    </button>

                                    <button
                                      type="button"
                                      className="btn btn-primary mr-2"
                                      disabled={disabledBtn}
                                      onClick={this.handleCheckoutFieldsSubmit}
                                    >
                                      Save
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <div className="card m-t-40">
                                <div className="card-header card-header-block">
                                  <div className="col-md-8">
                                    <h5 className="card-title">
                                      {' '}
                                      Success Page Message
                                    </h5>
                                  </div>
                                  <div className="col-md-4 text-sm-right mt-3 mt-sm-0">
                                    <Link
                                      onClick={() => this.redirectTo()}
                                      disabled={
                                        parse(this.state.successPageMessage)
                                          .length === 0
                                      }
                                      className="btn btn-sm btn-dark"
                                    >
                                      View Success Page
                                    </Link>
                                  </div>
                                </div>

                                <div className="card-body">
                                  <div id="editor1">
                                    <ReactQuill
                                      onKeyDown={this.checkCharacterCount}
                                      theme={this.state.theme}
                                      onChange={this.handleChangeEditor}
                                      value={this.state.successPageMessage}
                                      bounds={'.app'}
                                      modules={module}
                                      formats={format}
                                      placeholder="Message to ticket buyer after checkout"
                                    />
                                    {msgErrorMsg ? (
                                      <div className="error ml-0">
                                        {msgErrorMsg}
                                      </div>
                                    ) : null}
                                  </div>
                                  <div className="pull-right m-t-40">
                                    <button
                                      type="button"
                                      className="btn btn-light"
                                      onClick={this.clearSuccessPageMessage}
                                    >
                                      Cancel
                                    </button>
                                    <button
                                      type="button"
                                      className="btn btn-primary mr-2"
                                      disabled={
                                        JSON.stringify(
                                          this.state.successPageMessageValid.substring(
                                            1,
                                            2
                                          )
                                        ) === '' ||
                                        JSON.stringify(
                                          this.state.successPageMessageValid.substring(
                                            1,
                                            2
                                          )
                                        ) === '\n' ||
                                        this.state.successPageMessageValid
                                          .substring(1, 2)
                                          .toString() === '' ||
                                        this.state.successPageMessageValid
                                          .substring(1, 2)
                                          .toString() === '\n' ||
                                        this.state.successPageMessageValid.toString() ===
                                          '\n' ||
                                        this.state.successPageMessage === '' ||
                                        this.state.successPageMessageValid.toString() ===
                                          '\n'
                                      }
                                      onClick={this.handleSuccessMessage}
                                    >
                                      Save
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Tab>

                          {/* ..........................................................Tab 5.............................................. */}
                          <Tab
                            eventKey={5}
                            title={
                              <span className="tab-head">
                                <Tickets /> Tickets
                              </span>
                            }
                          >
                            <EditTicket
                              eventId={eventId}
                              getTicketList={this.getTicketList}
                              multiDays={this.state.multiDays}
                              endTimeStamp={this.state.endTimeStamp}
                              startTimeStamp={this.state.startTimeStamp}
                              {...this.props}
                            />
                          </Tab>

                          {/* ...........................................................Tab 6.................................................. */}
                          <Tab
                            eventKey={6}
                            title={
                              <span className="tab-head">
                                <CouponIcon />
                                Promo Codes
                              </span>
                            }
                          >
                            <Coupon
                              eventId={eventId}
                              ticketsList={this.state.ticketsList}
                            />
                          </Tab>

                          {/* ............................................................Tab 7........................................... */}

                          <Tab
                            eventKey={7}
                            title={
                              <span className="tab-head">
                                <Privacy />
                                Privacy
                              </span>
                            }
                          >
                            <div
                              className="tab-pane fade show active"
                              id="details"
                              role="tabpanel"
                              aria-labelledby="details"
                            >
                              <div className="col-lg-12 card-margin">
                                <div className="card ">
                                  <div className="card-header">
                                    <div className="col-md-8">
                                      <h6 className="card-title m-0">
                                        Privacy
                                      </h6>
                                    </div>
                                  </div>
                                  <div className="card-body">
                                    <h4 className="m-t-20">Public Event</h4>
                                    <p>
                                      <small>
                                        Public Events will be discoverable by
                                        anyone on this site. If you do not wish
                                        to have your event publicly displayed
                                        please select Private Event.
                                      </small>
                                    </p>
                                    <div className="string-check string-check-bordered-danger mb-2">
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        name="publictype"
                                        value={this.state.publicType}
                                        checked={this.state.publicType}
                                        onClick={this.updateCheckBox}
                                      />
                                      <label
                                        className="string-check-label"
                                        for="formCheckInput25"
                                      >
                                        <span className="ml-2">
                                          Public Event
                                        </span>
                                      </label>
                                    </div>
                                    <hr />
                                    <h4 className="m-t-20">Private Event</h4>
                                    <p>
                                      <small>
                                        Private Events will not be discoverable
                                        by anyone on this site. If you wish to
                                        have your event publicly displayed
                                        please select Public Event.
                                      </small>
                                    </p>
                                    <div className="string-check string-check-bordered-danger mb-2">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        name="privateTypeLink"
                                        id="formCheckInput26"
                                        value={this.state.privateTypeLink}
                                        checked={this.state.privateTypeLink}
                                        onClick={this.handleInputChange}
                                      />

                                      <label
                                        className="string-check-label"
                                        for="formCheckInput25"
                                      >
                                        <span className="ml-2">
                                          Only People With the Link Can View the
                                          Event
                                        </span>
                                      </label>
                                    </div>
                                    <div className="string-check string-check-bordered-danger mb-2">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        name="privateTypePassword"
                                        id="formCheckInput27"
                                        value={this.state.privateTypePassword}
                                        checked={this.state.privateTypePassword}
                                        onClick={this.handleInputChange}
                                      />

                                      <label
                                        className="string-check-label"
                                        for="formCheckInput25"
                                      >
                                        <span className="ml-2">
                                          Password Protect
                                        </span>
                                      </label>
                                    </div>

                                    {this.state.privateTypePassword ? (
                                      <React.Fragment>
                                        <div className="col-md-6">
                                          <div className="form-group">
                                            <label
                                              className="font-weight-semibold"
                                              for="password"
                                            >
                                              Password:
                                            </label>
                                            <input
                                              type="password"
                                              className="form-control"
                                              id="password"
                                              maxLength="50"
                                              name="password"
                                              autoComplete="off"
                                              value={this.state.password}
                                              onChange={this.handleChange}
                                            />
                                            {passwordErrorMsg ? (
                                              <div className="error ml-0">
                                                {passwordErrorMsg}
                                              </div>
                                            ) : null}
                                          </div>
                                        </div>
                                      </React.Fragment>
                                    ) : (
                                      ''
                                    )}
                                    <div className="pull-right m-t-40">
                                      <button
                                        className="btn btn-light"
                                        onClick={this.getEventDetails}
                                      >
                                        Cancel
                                      </button>
                                      <button
                                        className="btn btn-primary mr-2"
                                        type="button"
                                        disabled={
                                          (!this.state.privateTypePassword &&
                                            !this.state.privateTypeLink &&
                                            !this.state.publicType) ||
                                          disabledBtn
                                        }
                                        onClick={() =>
                                          this.handleEditPrivacyEvent()
                                        }
                                      >
                                        Save
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Tab>


                          <Tab
                            eventKey={9}
                            title={
                              <span className="tab-head">
                                <Email />
                                Manage Emails
                              </span>
                            }
                          >
                            <div
                              className="tab-pane fade show active"
                              id="details"
                              role="tabpanel"
                              aria-labelledby="details"
                            >
                              <div className="col-lg-12 card-margin">
                                <div className="card ">
                                  <div className="card-header">
                                    <div className="col-md-8">
                                      <h6 className="card-title m-0">
                                        Manage Emails
                                      </h6>
                                    </div>
                                  </div>
                                  <div className="card-body">
                                    <div className="string-check string-check-bordered-danger mb-2">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        name="privateTypeLink"
                                        id="formCheckInput26"
                                        value={this.state.emailNotification}
                                        checked={
                                          this.state.emailNotification === 1
                                            ? true
                                            : false
                                        }
                                        onClick={(e) =>
                                          this.handleEventEmailChecked(e)
                                        }
                                      />

                                      <label
                                        className="string-check-label"
                                        for="formCheckInput25"
                                      >
                                        <span className="ml-2">
                                          Turn on email notifications for ticket
                                          sales.
                                        </span>
                                      </label>
                                    </div>

                                    <div className="pull-right m-t-40">
                                      <button
                                        className="btn btn-light"
                                        onClick={this.getEventDetails}
                                      >
                                        Cancel
                                      </button>
                                      <button
                                        className="btn btn-primary mr-2"
                                        type="button"
                                        onClick={() => this.handleEventEmail()}
                                      >
                                        Save
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Tab>
                          <Tab
                            eventKey={19}
                            title={
                              <span className="tab-head">
                                <UrlIcon />
                                Custom URL
                              </span>
                            }
                          >
                            <div
                              className="tab-pane fade show active"
                              id="details"
                              role="tabpanel"
                              aria-labelledby="details"
                            >
                              <div className="col-lg-12 card-margin">
                                <div className="card ">
                                  <div className="card-header">
                                    <div className="col-md-8">
                                      <h6 className="card-title m-0">
                                        Custom URL
                                      </h6>
                                    </div>
                                  </div>
                                  <div className="card-body">
                                    <p className="font-weight-semibold px-2">
                                      URL:
                                    </p>
                                    {this.state.customURlShow == '' ||
                                    this.state.customURlShow == null ||
                                    this.state.customURlShow == undefined ? (
                                      <>
                                        <div className="row px-3">
                                          <div className="col-4 px-0">
                                            <div className="form-group">
                                              <input
                                                type="text"
                                                className="form-control"
                                                maxLength="70"
                                                name="customUrlSave"
                                                autoComplete="off"
                                                placeholder="Custom URL"
                                                value={this.state.customUrlSave}
                                                onChange={this.handleChangeURL}
                                              />
                                              {customUrlSaveErrorMsg ? (
                                                <div className="error ml-0">
                                                  {customUrlSaveErrorMsg}
                                                </div>
                                              ) : null}
                                            </div>
                                          </div>
                                          <div className="col-3 px-0">
                                            <div className="form-group">
                                              <p className="p-2 px-0">
                                                .farechild.com
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        <div className="row px-3">
                                          <div className="col-4 px-0">
                                            <div className="form-group">
                                              <input
                                                type="text"
                                                className="form-control"
                                                disabled
                                                value={this.state.customURlShow}
                                              />
                                            </div>
                                          </div>
                                          <div className="col-3 px-0">
                                            <div className="form-group">
                                              <p className="p-2 px-0">
                                                .farechild.com
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    )}

                                    {this.state.customURlShow == '' ||
                                    this.state.customURlShow == null ||
                                    this.state.customURlShow == undefined ? (
                                      <div className="pull-right m-t-40">
                                        <button
                                          className="btn btn-light"
                                          onClick={this.getEventDetails}
                                        >
                                          Cancel
                                        </button>
                                        <button
                                          className="btn btn-primary mr-2"
                                          type="button"
                                          disabled={
                                            this.state.disabledBtn ||
                                            this.state.customUrlSave == '' ||
                                            this.state.customUrlSave.length < 5
                                          }
                                          onClick={() =>
                                            this.handleEventCustomURL()
                                          }
                                        >
                                          Save
                                        </button>
                                      </div>
                                    ) : (
                                      ''
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Tab>
                        </Tabs>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
        <Modal
          size="md"
          show={openEventPasswordPopup}
          onHide={this.closeAddFieldsPopup}
          aria-labelledby="example-modal-sizes-title-sm"
        >
          <div className="cm_modal">
            <div className="modal-body">
              <h4 className="mb-4 pb-1 mt-3">
                <b>Enter Field Name</b>
              </h4>

              <div className="form-group">
                <label for="exampleInputUsername12">
                  Checkout Field Name<span className="text-danger">*</span>
                </label>

                <input
                  type="text"
                  className="form-control mt-2"
                  maxLength="50"
                  id="event-sub"
                  placeholder="Field Name"
                  name="fieldName"
                  value={this.state.fieldName}
                  onChange={this.handleChange}
                />
                {fieldNameErrorMsg ? (
                  <div className="error ml-0">{fieldNameErrorMsg}</div>
                ) : null}
              </div>
              <br />

              <div className="bg-white pull-right mb-4">
                <button
                  className="btn btn-light"
                  type="button"
                  onClick={this.closeAddFieldsPopup}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  disabled={this.state.fieldName == '' ? true : false}
                  className="btn btn-primary ml-2"
                  onClick={this.addClick.bind(this)}
                >
                  Submit
                </button>
              </div>
              <br />
              <br />
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default EditEvent;
