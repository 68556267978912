/* eslint-disable no-undef */
import React from "react"
import TopBar from "../template/TopBar"
import Footer from "../template/Footer"
import SideNav from "../template/SideNav"
import { Loader, RangPicker } from "../../config/Svgicon"
import Pagination from "react-js-pagination"
import _ from "lodash"
import { usersService } from "../../_services/usersService"
import ResponseFilter from "../../config/response-handler"
import { CSVLink } from "react-csv"
import { Select, Empty } from "antd"
import moment from "moment"
import DateRangePicker from "react-bootstrap-daterangepicker"
// you will also need the css that comes with bootstrap-daterangepicker
import "bootstrap-daterangepicker/daterangepicker.css"

const { Option } = Select

class Reports extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hideSale: false,
      isOpenEvent: false,
      isOpenTicket: false,
      limit: 5,
      sortOrder: "desc",
      sortBy: "created",
      page: 1,
      eventId: "",
      ticketId: "",
      ticketIdShow: "",
      startDate: "",
      endDate: "",
      search: "",
      search1: "",
      loader: false,
      eventList: [],
      ticketList: [],
      orderList: [],
      orderListCSV: [],
      eventnameShow: "",
      ticketnameShow: "",
      pageVisitorsShow: "",
      pageVisitors: "",
      timeout: false,
    }
  }

  componentDidMount() {
    if (localStorage.getItem("isAccountDetailsExists") == "false" && localStorage.getItem("role") != 2) {
      this.props.history.push("/account-settings")
    }
    if (localStorage.getItem("role") == 2) {
      this.props.history.push("/events-feed")
    }
    if (localStorage.getItem("role") == 4) {
      this.props.history.push("/admin")
    }
    document.title = "Reports | Farechild"
    this.getOrderDetails(1)
    this.getEventList()
  }

  // handleSearch = (e) => {
  //   this.setState({ loader: false });
  //   let orderId1 = e.target.value.split('#').join('%23');
  //   this.setState({ search: orderId1, page: 1, search1: e.target.value }, () =>
  //     this.getOrderDetails()
  //   );
  // };
  handleSearch = (e) => {
    this.setState({ loader: false, search1: e.target.value }, () => {
      this.setState({ loader: false })
      if (this.state.timeout) {
        clearTimeout(this.state.timeout)
      }
      this.setState({
        timeout: setTimeout(() => {
          let orderId1 = this.state.search1.split("#").join("%23")
          this.setState({ search: orderId1, page: 1 }, () => this.getOrderDetails())
        }, 500),
      })
    })
  }

  changeLimit = (e) => {
    this.setState({ limit: e, page: 1 }, () => this.getOrderDetails())
  }

  handlePageChange = (page) => {
    this.setState({ page: page, loader: true }, () => this.getOrderDetails())
  }

  handleSort = (e) => {
    this.setState(
      {
        loader: false,
        page: 1,
        sortBy: e,
        sortOrder: this.state.sortOrder === "desc" ? "asc" : "desc",
      },
      () => this.getOrderDetails()
    )
  }

  getOrderDetails = (check) => {
    
    console.log('getting order details');
    this.setState({ loader: true })
    let params = `userId=${localStorage.getItem("userId")}&eventId=${this.state.eventId}&ticketId=${
      this.state.ticketId
    }&startDate=${this.state.startDate}&endDate=${this.state.endDate}&search=${this.state.search}&sortBy=${
      this.state.sortBy
    }&sortOrder=${this.state.sortOrder}&page=${this.state.page - 1}&limit=${this.state.limit}`
    usersService.orderDetails(params).then((res) => {
      console.log('got order details',res);
      this.setState({ loader: false })

      let { status, resData } = ResponseFilter(res)
      if (status) {
        this.getticketList()
        this.setState(
          {
            orderList: resData.result,
            totalCount: resData.count,
          },
          () => this.getOrderDetailsCSV(check, this.state.totalCount)
        )
      }
    })
  }

  getOrderDetailsCSV = (check, limit) => {
    console.log('making csv',this.state);
    let params = `userId=${localStorage.getItem("userId")}&eventId=${this.state.eventId}&ticketId=${
      this.state.ticketId
    }&startDate=${this.state.startDate}&endDate=${this.state.endDate}&search=${this.state.search}&sortBy=${
      this.state.sortBy
    }&sortOrder=${this.state.sortOrder}&limit=${limit + 1}`

    usersService.orderDetails(params).then((res) => {
      let { status, resData } = ResponseFilter(res)
      console.log('sales data',res);
      if (status) {
        if (check) {
          this.setState({ pageVisitorsShow: resData.pageVisitors })
        }

        this.setState({
          orderListCSV: resData.result,
          totalCount: resData.count,
          Sales: resData.Sales,
          ticketSales: resData.ticketSales,
          taxes:resData.taxes,
          pageVisitors: resData.pageVisitors,
        },()=>{console.log('csv made',this.state)})
      }
    })
  }

  getEventList = () => {
    const currentTimeZoneName = Intl.DateTimeFormat().resolvedOptions().timeZone

    var obj = {
      currentTimeZoneName: currentTimeZoneName,
      createdBy: localStorage.getItem("userId"),
    }
    usersService.eventList(obj).then((res) => {
      let { status, resData } = ResponseFilter(res)
      if (status) {
        this.setState({
          eventList: resData.result,
        })
      }
    })
  }

  eventFilter = (id) => {
    const orderTicketList = this.state.eventList.filter((e) => e._id === id)
    if (id !== "") {
      this.setState({ eventnameShow: orderTicketList[0].eventName })
    } else {
      this.setState({ eventnameShow: "" })
    }

    this.setState({ hideSale: false })
    this.setState(
      {
        eventId: id ? id : "",
        ticketId: "",
        ticketIdShow: "",
        ticketList: [],
        ticketName: "",
        ticketnameShow: "",
      },
      () => this.getOrderDetails(1)
    )
  }

  getticketList = () => {
    let obj = {
      eventId: this.state.eventId,
    }
    usersService.ticketList(obj).then((res) => {
      let { status, resData } = ResponseFilter(res)
      if (status) {
        this.setState({
          ticketList: resData.result,
        })
      }
    })
  }

  ticketFilter = (id) => {
    const orderTicketList = this.state.ticketList.filter((e) => e._id === id)

    if (orderTicketList.length !== 0) {
      this.setState({ ticketnameShow: orderTicketList[0].ticketName })
      if (
        orderTicketList[0].price == "null" ||
        orderTicketList[0].price == null ||
        orderTicketList[0].price == 0 ||
        orderTicketList[0].price == "0"
      ) {
        this.setState({ hideSale: true })
      } else {
        this.setState({ hideSale: false })
      }
    } else {
      this.setState({ ticketnameShow: "" })
      this.setState({ hideSale: false })
    }

    this.setState(
      {
        ticketId: id !== "1" && id ? id : "",
        ticketIdShow: id ? id : "",
      },
      () => this.getOrderDetails()
    )
  }

  showDropdown = () => {
    this.setState({ isOpenEvent: true })
    document.addEventListener("click", this.hideDropdown)
  }
  hideDropdown = () => {
    this.setState({ isOpenEvent: false })
    document.removeEventListener("click", this.hideDropdown)
  }

  ticketShowDropdown = () => {
    this.setState({ isOpenTicket: true })
    document.addEventListener("click", this.ticketHideDropdown)
  }
  ticketHideDropdown = () => {
    this.setState({ isOpenTicket: false })
    document.removeEventListener("click", this.ticketHideDropdown)
  }

  handleCancel = (event, picker) => {
    this.setState(
      {
        event: "",
        pageNo: 1,
        startDate: "",
        endDate: "",
      },
      () => {
        this.getOrderDetails()
      }
    )
    picker.element.val("")
  }

  handleRangePicker = (event, picker) => {
    var date = new Date(picker.endDate)
    picker.element.val(picker.startDate.format("MM/DD/YYYY") + " - " + picker.endDate.format("MM/DD/YYYY"))

    this.setState(
      {
        pageNo: 1,
        startDate: !_.isEmpty(picker.startDate) ? new Date(picker.startDate).getTime() : "",
        endDate: !_.isEmpty(picker.endDate) ? new Date(picker.endDate).getTime() : "",
        // endDate: !_.isEmpty(picker.endDate)
        //   ? date.setUTCHours(date.getDate())
        //   : '',
      },
      () => {
        this.getOrderDetails()
      }
    )
  }

  getCouponList = (id, eventId) => {
    let obj = {
      eventId: eventId,
      couponId: id,
    }
    this.setState({ loader: true })
    usersService.couponList(obj).then((res) => {
      let { status, resData } = ResponseFilter(res)
      if (status) {
        this.setState({ loader: false })

        if (resData.result.length !== 0) {
          let { _id, couponName, discount, discountType, selectedTickets } = resData.result[0]

          this.setState(
            {
              couponID: _id,
              couponDetails: resData.result,
              couponName,
              discount,
              discountType,
              selectedTickets,
            },
            () => this.validate()
          )
        } else {
          this.setState({
            couponNameErrorMsg: "Please enter valid promo code.",
          })
        }
      } else {
        this.setState({ loader: false })
      }
    })
  }

  getCouponAmount = (id, eventId) => {
    let price = 0
    this.getCouponList(id, eventId)
    return price
  }

  render() {
    let { loader, orderList, orderListCSV } = this.state
    var today = moment(new Date()).format("MM-DD-YYYY")

    const headers = [
      { label: "First", key: "First" },
      { label: "Last", key: "Last" },
      { label: "Email", key: "Email" },
      { label: "Phone", key: "Phone" },
      { label: "Address", key: "Address" },
      { label: "Ticket", key: "Ticket" },
      { label: "QTY.", key: "QTY" },
      { label: "Ticket Amount", key: "Amount" },
      { label: "Fees", key: "Fees" },
      { label: "Discount Amount", key: "DiscountAmount" },
      { label: "Promo applied", key: "DiscountCode" },
      { label: "Grand Total", key: "Total" },
      { label: "Purchase Date", key: "Date" },
    ]

    let order = orderListCSV.map((or) => {
      let tickets = '';
      let ticketsObj = {};
      

      return {
        First: or.contactInformation ? or.contactInformation.firstName : "-",
        Last: or.contactInformation ? or.contactInformation.lastName : "-",
        Email: or.contactInformation ? or.contactInformation.email : "-",
        Phone: or.contactInformation.phone ? `+${or.contactInformation.phone}` : "-",
        Address: or.billingInformation.address1
          ? `${or.billingInformation.address1},${or.billingInformation.city},${or.billingInformation.state},${or.billingInformation.zipCode}`
          : "-",
        Ticket: or.ticketDetails ? or.ticketDetails.ticketName : "-",
        QTY: or.ticketDetails ? or.ticketDetails.ticketQuantity : "-",
        Amount: or.ticketDetails.ticketPrice !== "0" ? "$" + Number(or.ticketDetails.ticketPrice).toFixed(2) : "Free",
        Fees: or.fees ? `$${Number(or.fees ? or.fees : 0).toFixed(2)}` : `$${Number(0).toFixed(2)}`,
        DiscountAmount:
          or.ticketDetails.ticketPrice != "0"
            ? or.grandTotal == 0
              ? `$${Number(or.ticketDetails.ticketPrice ? or.ticketDetails.ticketPrice : 0).toFixed(2)}`
              : `$${Number(or.discountedPrice ? or.discountedPrice : 0).toFixed(2)}`
            : `$${Number(0).toFixed(2)}`,
        DiscountCode:
          or.ticketDetails.ticketPrice != "0"
            ? or.grandTotal == 0
              ? "Yes"
              : `${or.discountedPrice ? "Yes" : "No"}`
            : "No",

        Total: or.grandTotal ? `$${Number(or.grandTotal ? or.grandTotal : 0).toFixed(2)}` : `$${Number(0).toFixed(2)}`,

        Date: moment(or.created).format("MM/DD/YYYY"),
      }
    })

    return (
      <div className="dark-sidebar">
        {loader ? <Loader /> : null}
        <div className="main-container">
          <div className="container-fluid page-body-wrapper">
            <nav className="navbar fixed-top">
              <div className="navbar-menu-container d-flex align-items-center justify-content-center">
                <div className="sub-heading">
                  <h4 className="page-title ">Reports</h4>
                </div>
                <div className="text-center navbar-brand-container align-items-center justify-content-center">
                  <a className="brand-logo" href="/">
                    <img src={require("../../assets/images/mobile-logo.png")} alt="" className="mb-3" />
                  </a>
                </div>

                <TopBar {...this.props} />
              </div>
            </nav>

            <SideNav />
            <div className="main-panel">
              <div className="content-wrapper manage-event">
                <div className="row ">
                  <div className="col-md-12">
                    <div className="card card-margin">
                      <div className="card-header">
                        <h5 className="card-title">Run a Report</h5>
                      </div>
                      <div className="card-body">
                        <div className="card-info">Choose from the options below to run a report.</div>
                        <div className="row">
                          <div className="col-md-4 col-sm-12 mb-2 mb-lg-0 mb-md-0">
                            <div className="input-group  rang-picker">
                              <DateRangePicker
                                initialSettings={{
                                  autoUpdateInput: false,
                                  locale: {
                                    cancelLabel: "Clear",
                                  },
                                }}
                                onApply={this.handleRangePicker}
                                onCancel={this.handleCancel}
                              >
                                <input
                                  type="text"
                                  className="form-control bg-white"
                                  name="date1"
                                  readOnly
                                  id="date1"
                                  placeholder="Select Date Range"
                                />
                              </DateRangePicker>
                              <span className="input-group-text">
                                <RangPicker />
                              </span>
                            </div>
                          </div>

                          <div className="col-md-6 ml-2">
                            <div className="d-flex flex-row order-select">
                              <div>
                                <Select
                                  showSearch
                                  style={{ width: 200 }}
                                  placeholder="Select an Event"
                                  optionFilterProp="children"
                                  className="order-filter"
                                  onFocus={this.onFocus}
                                  onBlur={this.onBlur}
                                  onSearch={this.onSearch}
                                  onChange={this.eventFilter}
                                >
                                  <Option value="">All</Option>
                                  {!_.isEmpty(this.state.eventList)
                                    ? this.state.eventList
                                        .filter((event) => event.status === 2 || event.status === 3)
                                        .map((item) => <Option value={item._id}>{item.eventName}</Option>)
                                    : ""}
                                </Select>
                              </div>
                              <div className="ml-md-3 mt-3 mt-md-0">
                                <Select
                                  showSearch
                                  style={{ width: 200 }}
                                  placeholder="Select Ticket Type"
                                  optionFilterProp="children"
                                  className="order-filter"
                                  onFocus={this.onFocus}
                                  onBlur={this.onBlur}
                                  onSearch={this.onSearch}
                                  value={this.state.ticketIdShow ? this.state.ticketIdShow : "Select Ticket Type"}
                                  onChange={this.ticketFilter}
                                  disabled={!_.isEmpty(this.state.ticketList) ? false : true}
                                >
                                  <Option value="1">All</Option>
                                  {!_.isEmpty(this.state.ticketList)
                                    ? this.state.ticketList.map((item) => (
                                        <Option value={item._id}>{item.ticketName}</Option>
                                      ))
                                    : ""}
                                </Select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-4 col-md-4 col-sm-12">
                    <div className="card card-margin">
                      <div className="card-body p-0">
                        <div className="widget-20">
                          <div className="widget-20-header">
                            <div className="widget-20-content">
                              <h5 className="widget-20-title">Ticket Sales</h5>
                              <span className="widget-20-info">
                                {this.state.ticketnameShow && this.state.ticketnameShow !== "" ? (
                                  this.state.ticketnameShow
                                ) : (
                                  <>
                                    {this.state.eventnameShow && this.state.eventnameShow !== ""
                                      ? this.state.eventnameShow
                                      : "All"}
                                  </>
                                )}
                              </span>
                            </div>
                            <h3 className="widget-20-figure">{this.state.ticketSales ? this.state.ticketSales : 0}</h3>
                          </div>
                          <div className="widget-20-chart">
                            <img src={require("../../assets/images/red-graph.png")} className="img-fluid" alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-12">
                    <div className="card card-margin">
                      <div className="card-body p-0">
                        <div className="widget-20">
                          <div className="widget-20-header">
                            <div className="widget-20-content">
                              <h5 className="widget-20-title">Sales</h5>
                              <span className="widget-20-info">
                                {this.state.ticketnameShow && this.state.ticketnameShow !== "" ? (
                                  this.state.ticketnameShow
                                ) : (
                                  <>
                                    {this.state.eventnameShow && this.state.eventnameShow !== ""
                                      ? this.state.eventnameShow
                                      : "All"}
                                  </>
                                )}
                              </span>
                            </div>
                            <h3 className="widget-20-figure">
                              $
                              {this.state.hideSale ? (
                                "0"
                              ) : (
                                <>
                                  {!isNaN(this.state.Sales) ||
                                  this.state.Sales !== NaN ||
                                  this.state.Sales ||
                                  this.state.Sales !== "NaN"
                                    ? this.state.Sales
                                    : "0.00"}
                                </>
                              )}
                            </h3>
                          </div>
                          {this.state.taxes?(
                            <div className="widget-20-header">
                              <div className="widget-20-content">
                                <h5 className="widget-20-title">Taxes</h5>
                              </div>
                              <h3 className="widget-20-figure">
                                ${this.state.taxes.toFixed(2)}                             
                              </h3>
                            </div>
                          ):(
                            <div className="widget-20-chart">
                              <img src={require("../../assets/images/green-bar-graph.png")} className="img-fluid" />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-12">
                    <div className="card card-margin mb-4 mb-lg-0 mb-md-0">
                      <div className="card-body p-0">
                        <div className="widget-20">
                          <div className="widget-20-header">
                            <div className="widget-20-content">
                              <h5 className="widget-20-title">Page Visitors</h5>
                              <span className="widget-20-info">
                                {this.state.eventnameShow && this.state.eventnameShow !== ""
                                  ? this.state.eventnameShow
                                  : "All"}
                              </span>
                            </div>
                            <h3 className="widget-20-figure">
                              {this.state.pageVisitorsShow ? this.state.pageVisitorsShow : 0}
                            </h3>
                          </div>
                          <div className="widget-20-chart">
                            <img src={require("../../assets/images/black-graph.jpg")} className="img-fluid" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row card-margin">
                  <div className="col-lg-12 card-margin">
                    <div className="card">
                      <div className="card-header justify-content-between">
                        <h5 className="card-title">Results</h5>
                        {order.length == 0 ? (
                          <button className="btn btn-sm btn-dark" disabled>
                            Export Results
                          </button>
                        ) : (
                          <CSVLink
                            filename={`Reports-Results_${today ? today : ""}.csv`}
                            data={order}
                            headers={headers}
                            className="btn btn-sm btn-dark"
                          >
                            Export Results
                          </CSVLink>
                        )}
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-6 col-sm-6 mb-2 mb-lg-0 mb-md-0">
                            <div className="d-flex flex-row select-rang">
                              Show
                              <Select
                                className="select-limit mr-1 ml-1"
                                value={this.state.limit}
                                defaultValue={this.state.limit}
                                style={{ width: 120 }}
                                onChange={this.changeLimit}
                              >
                                <Option value={5}>5</Option>
                                <Option value={10}>10</Option>
                                <Option value={15}>15</Option>
                              </Select>
                              <span>entries</span>
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-6 mb-lg-0 mb-md-0 text-md-right">
                            <input
                              onKeyPress={(e) => {
                                if (e.key === "Enter") e.preventDefault()
                              }}
                              className="form-control search-box"
                              name="search"
                              placeholder="Search"
                              type="search"
                              value={this.state.search1}
                              onChange={this.handleSearch}
                            />
                          </div>
                        </div>
                        <div className="table-responsive">
                          <table id="example" className="table table-striped table-bordered">
                            <thead>
                              <tr>
                                <th className="p-3">First Name</th>
                                <th className="p-3">Last Name</th>
                                <th className="p-3">Email</th>
                                <th className="p-3">Phone</th>
                                <th className="p-3">Address</th>
                                {/* <th className="p-3">Ticket</th>
                                <th className="p-3">QTY.</th>
                                <th className="p-3">Ticket Amount</th> */}
                                <th className="p-3">Subtotal</th>
                                <th className="p-3">Fees</th>
                                <th className="p-3">Vaccine Card</th>
                                <th className="p-3">Taxes</th>
                                <th className="p-3">Discount Amount</th>
                                <th className="p-3">Promo Applied</th>
                                <th className="p-3">Grand Total</th>
                                <th className="p-3">Purchase Date</th>
                                <th className="p-3">Order Status</th>
                              </tr>
                            </thead>
                            <tbody>
                              {!_.isEmpty(orderList) ? (
                                <React.Fragment>
                                  {orderList.map((item, i) => {
                                    return (
                                      <tr>
                                        <td>
                                          {item.contactInformation.firstName ? item.contactInformation.firstName : "_"}
                                        </td>
                                        <td>
                                          {item.contactInformation.lastName ? item.contactInformation.lastName : "_"}
                                        </td>
                                        <td>{item.contactInformation.email ? item.contactInformation.email : "_"}</td>
                                        <td>
                                          {item.contactInformation.phone ? `+${item.contactInformation.phone}` : "_"}
                                        </td>
                                        <td>
                                          {item.billingInformation.address1
                                            ? `${item.billingInformation.address1}, ${item.billingInformation.city}, ${item.billingInformation.state}, ${item.billingInformation.zipCode}`
                                            : "_"}
                                        </td>
                                        {/* <td>{item.ticketDetails ? item.ticketDetails.ticketName : ""}</td>
                                        <td>{item.ticketDetails ? item.ticketDetails.ticketQuantity : ""}</td>
                                        <td>
                                          {item.ticketDetails.ticketPrice != "0"
                                            ? `$${Number(item.ticketDetails.ticketPrice).toFixed(2)}`
                                            : "Free"}
                                        </td> */}
                                        <td>
                                          {`$${item.subtotal}`}
                                        </td>
                                        <td>
                                          {" "}
                                          {item.fees ? `$${Number(item.fees).toFixed(2)}` : `$${Number(0).toFixed(2)}`}
                                        </td>
                                        <td>
                                          {item?.isReportRequired[0] ? (
                                            item.vaccinationCertificate.length ? (
                                              <a href={item.vaccinationCertificate[0]} target="_blank">
                                                View Certificate
                                              </a>
                                            ) : (
                                              "Will provide negative test"
                                            )
                                          ) : (
                                            ""
                                          )}
                                        </td>
                                        <td>{item?.taxes && item?.taxes>0?'$'+item?.taxes.toFixed(2):''}</td>
                                        <td>
                                          {item.ticketDetails.ticketPrice != 0 ? (
                                            <>
                                              {item.grandTotal == 0 ? (
                                                <>
                                                  {item.ticketDetails.ticketPrice
                                                    ? `$${Number(item.ticketDetails.ticketPrice).toFixed(2)}`
                                                    : `$${Number(0).toFixed(2)}`}
                                                </>
                                              ) : (
                                                <>
                                                  {item.discountedPrice
                                                    ? `$${Number(item.discountedPrice).toFixed(2)}`
                                                    : `$${Number(0).toFixed(2)}`}
                                                </>
                                              )}
                                            </>
                                          ) : (
                                            `$${Number(0).toFixed(2)}`
                                          )}
                                        </td>
                                        <td>{item.couponName ? item.couponName : ""}</td>
                                        <td>
                                          {item.grandTotal
                                            ? `$${Number(item.grandTotal).toFixed(2)}`
                                            : `$${Number(0).toFixed(2)}`}
                                        </td>
                                        <td>{item.created ? moment(item.created).format("MM/DD/YYYY") : ""}</td>
                                        {/* {item.status === 1 && item.ticketDetails.status === 1 ? (
                                          <React.Fragment>
                                            {item.ticketDetails.ticketPrice === "0" ||
                                            item.cardLastFourDigit === undefined ||
                                            item.cardLastFourDigit === "" ? (
                                              <td style={{ width: 150 }}>
                                                {item.eventStatus === 2 ? <>Upcoming</> : "_"}
                                              </td>
                                            ) : (
                                              <td style={{ width: 150 }}>
                                                {item.eventStatus === 2 ? <>Upcoming</> : "_"}
                                              </td>
                                            )}
                                          </React.Fragment>
                                        ) : (
                                          <React.Fragment>
                                            {item.ticketDetails.status === 3 ? <td>Refunded</td> : <td>Cancelled</td>}
                                          </React.Fragment>
                                        )} */}
                                        <td>{item.status === 1 ? "Completed" : (item.status === 3 ? "Refunded" : item.status.charAt(0).toUpperCase() + item.status.slice(1))}</td>
                                      </tr>
                                    )
                                  })}
                                </React.Fragment>
                              ) : (
                                ""
                              )}
                            </tbody>
                          </table>
                        </div>
                        {!_.isEmpty(orderList) ? (
                          ""
                        ) : (
                          <div className="text-center">
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                          </div>
                        )}

                        <div className="card-footer bg-white">
                          <div className="d-flex align-items-end justify-content-between flex-column flex-sm-row mt-4">
                            {!_.isEmpty(orderList) ? (
                              <p className="mb-3 mb-sm-0">
                                Showing{" "}
                                {this.state.page === 1 ? (
                                  <React.Fragment>1</React.Fragment>
                                ) : (
                                  this.state.page * this.state.limit - this.state.limit + 1
                                )}{" "}
                                to{" "}
                                {this.state.orderList
                                  ? this.state.orderList.length === this.state.limit
                                    ? this.state.page * this.state.limit - this.state.limit + this.state.limit
                                    : this.state.page * this.state.limit -
                                      this.state.limit +
                                      this.state.orderList.length
                                  : ""}{" "}
                                of {this.state.totalCount} entries
                              </p>
                            ) : (
                              <p className="mb-3 mb-dm-0">Showing 0 to 0 of 0 entries</p>
                            )}
                            <nav className="pagination-nav">
                              <ul>
                                <Pagination
                                  hideFirstLastPages={true}
                                  itemClassPrev="page-change"
                                  prevPageText="Previous"
                                  itemClassNext="page-change"
                                  nextPageText="Next"
                                  activePage={this.state.page}
                                  itemsCountPerPage={this.state.limit}
                                  totalItemsCount={this.state.totalCount}
                                  pageRangeDisplayed={3}
                                  onChange={this.handlePageChange}
                                />
                              </ul>
                            </nav>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Reports
