/* eslint-disable no-undef */
import React from 'react';
import { Link } from 'react-router-dom';
import ResponseFilter from '../../config/response-handler';
import { usersActions } from '../../_actions';
import { connect } from 'react-redux';
import { EyeIcon, Loader } from '../../config/Svgicon';

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      email: '',
      password: '',
      confirmPassword: '',
      passwordErrorMsg: '',
      confirmPasswordErrorMsg: '',
      showConfirmPassword: false,
      showPassword: false,
      errors: '',
      redirect: false,
    };
    this.redirectTo = this.redirectTo.bind(this);
  }

  componentDidMount() {
    document.title = 'Reset Password | Farechild';
  }

  redirectTo() {
    this.props.history.push({
      pathname: '/reset-successfully',
      state: { email: this.state.email },
    });
  }

  handleChange = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        [e.target.name + 'ErrorMsg']: '',
        errorMessage: '',
      },
      () => this.setState({ errors: '' })
    );
  };

  handleValidation = () => {
    let validate = true;
    let { password, confirmPassword } = this.state;
    if (password.trim() === '' || password.trim() === undefined) {
      validate = false;
      this.setState({
        passwordErrorMsg: 'Please enter password.',
      });
    } else if (password.trim().length <= 5) {
      validate = false;
      this.setState({
        passwordErrorMsg: ' Please enter minimum 6 characters.',
      });
    } else {
      this.setState({
        passwordErrorMsg: '',
      });
    }

    if (confirmPassword.trim() === '' || confirmPassword.trim() === undefined) {
      validate = false;
      this.setState({
        confirmPasswordErrorMsg: 'Please enter confirm password.',
      });
    } else if (confirmPassword.trim() !== password.trim()) {
      validate = false;
      this.setState({
        confirmPasswordErrorMsg:
          'Password and confirm password does not match.',
      });
    } else {
      this.setState({
        confirmPasswordErrorMsg: '',
      });
    }

    return validate;
  };

  handleSubmit = (e) => {
    e.preventDefault();

    let token = this.props.match.params.token

    if (this.handleValidation()) {
      this.setState({ redirect: false, loader: true });

      let obj = {
        token: token,
        password: this.state.password,
      };

      let { dispatch } = this.props;
      dispatch(usersActions.reset(obj)).then((data) => {
        ResponseFilter(data);
        if (data.data.statusCode === 1) {
          this.setState({ loader: false });
          this.redirectTo();
        } else {
          this.setState({ loader: false });
          this.setState({
            errors: data.data.error.responseMessage,
            errorCode: data.data.error.errorCode,
          });
        }
      });
    }
  };

  handleShowPassword = () =>
    this.setState({ showPassword: !this.state.showPassword });
  handleShowConfirmPassword = () =>
    this.setState({ showConfirmPassword: !this.state.showConfirmPassword });

  render() {
    let {
      loader,
      errors,
      passwordErrorMsg,
      confirmPasswordErrorMsg,
      showConfirmPassword,
      showPassword,
    } = this.state;

    return (
      <div className="base-color-option-2">
        {loader ? <Loader /> : null}
        <div classNameName="main-container">
          <div className="container-fluid page-body-wrapper full-page-wrapper">
            <div className="user-login login-v2 reset-card">
              <div className="row no-gutters">
                <div className="col-lg-12">
                  <div className="logo-container text-center">
                    <Link className="brand-logo login-page" to="/">
                      {' '}
                      <img
                        src={require('../../assets/images/mobile-logo.png')}
                        alt=""
                        title=""
                      />
                    </Link>
                  </div>
                </div>
              </div>

              <div className="justify-content-center align-self-center">
                <div className="center">
                  <div className="card-body card reset-card">
                    <div className="user-login-content">
                      <h4 className="login-title mt-2 cm_bold">
                        Reset Your Password
                      </h4>
                      <p className="text-muted mb-4">
                        Please enter your new password.
                      </p>
                      <form onSubmit={this.handleSubmit}>
                        <div className="form-group inner-addon">
                          <span
                            onClick={this.handleShowPassword}
                            className={`eye_icon ${
                              this.state.showPassword ? '' : 'slesh'
                            }`}
                          >
                            <EyeIcon />
                          </span>
                          <input
                            type={`${showPassword ? 'text' : 'password'}`}
                            className="form-control"
                            autoComplete="off"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            placeholder="New Password"
                            maxLength="50"
                            name="password"
                            value={this.state.password}
                            onChange={this.handleChange}
                          />

                          {passwordErrorMsg ? (
                            <div className="error">{passwordErrorMsg}</div>
                          ) : null}
                        </div>
                        <div className="form-group inner-addon">
                          <span
                            onClick={this.handleShowConfirmPassword}
                            className={`eye_icon ${
                              this.state.showConfirmPassword ? '' : 'slesh'
                            }`}
                          >
                            <EyeIcon />
                          </span>

                          <input
                            type={`${
                              showConfirmPassword ? 'text' : 'password'
                            }`}
                            className="form-control"
                            autoComplete="off"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            placeholder="Confirm password"
                            onChange={this.handleChange}
                            value={this.state.confirmPassword}
                            name="confirmPassword"
                            maxLength="50"
                          />
                          {confirmPasswordErrorMsg ? (
                            <div className="error">
                              {confirmPasswordErrorMsg}
                            </div>
                          ) : null}
                          {errors ? (
                            <div className="error">{errors}</div>
                          ) : null}
                        </div>

                        <div className="text-center mb-3">
                          <button
                            type="submit"
                            className="btn btn-primary btn-block mt-3 mb-5"
                          >
                            SUBMIT
                          </button>
                        </div>
                        <div className="text-center">
                          <div className="text-muted  text-uppercase">
                            <Link to="/login" className="link">
                              Back to sign in
                            </Link>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { props, login, user } = state;
  return {
    props,
    login,
    user,
  };
}

export default connect(mapStateToProps)(ResetPassword);
