/* eslint-disable no-undef */
import React from "react"
import Footer from "../template/Footer"
import SideNav from "../template/SideNav"
import TopBar from "../template/TopBar"
import Modal from "react-bootstrap/Modal"
import { usersService } from "../../_services/usersService"
import Pagination from "react-js-pagination"
import { FormDataList } from "../pages/FormData"
import { CSVLink } from "react-csv"
import ResponseFilter from "../../config/response-handler"
import { Loader, Edit, EyeIcon, Active, Draft, Completed, Image, Delete, RangPicker } from "../../config/Svgicon"
import moment from "moment"
import _, { toLower } from "lodash"
import { Select, Empty, message } from "antd"
import { Link } from "react-router-dom"
import DateRangePicker from "react-bootstrap-daterangepicker"
// you will also need the css that comes with bootstrap-daterangepicker
import "bootstrap-daterangepicker/daterangepicker.css"
const { Option } = Select

class AdminEvents extends React.Component {
  constructor(props) {
    super(props)
    let date = new Date()
    let y = date.getFullYear()
    let m = date.getMonth()
    let firstDay = new Date(y, m, 1).getTime()
    let lastDay = new Date(y, m, 31).getTime()
    this.state = {
      loader: false,
      openDeletePopup: false,
      eventListDetails: [],
      limit: 20,
      page: 1,
      status: "",
      eventIdDelete: "",
      sortOrder: "desc",
      sortBy: "created",
      promotersList: [],
      totals: {},
      startDate: firstDay,
      endDate: lastDay,
    }
  }

  componentDidMount() {
    if (localStorage.getItem("isAccountDetailsExists") == "false" && localStorage.getItem("role") != 4) {
      this.props.history.push("/account-settings")
    }
    if (localStorage.getItem("role") == 2) {
      this.props.history.push("/events-feed")
    }
    // if (localStorage.getItem('role') == 4) {
    //   this.props.history.push('/admin');
    // }

    document.title = "My Events | Farechild"
    this.eventList()
    this.getPromotersList()
    this.getTotals()
  }

  handlePageChange = (page) => {
    this.setState({ page: page, loader: true }, () => this.eventList())
  }
  closeModal = () => {
    this.setState({
      eventIdDelete: "",
      openDeletePopup: false,
    })
  }

  getTotals = () => {
    const accessToken = localStorage.getItem("accessToken")
    usersService.getEventsTotals({}, accessToken).then((res) => {
      let { status, resData } = ResponseFilter(res)
      if (status) {
        this.setState({
          totals: resData,
        })
      }
    })
  }

  getPromotersList = () => {
    let params = `sortBy=${"nameLower"}&sortOrder=${"desc"}`
    const accessToken = localStorage.getItem("accessToken")
    this.setState({ loader: true })
    usersService.getPromoters(params, accessToken).then((res) => {
      let { status, resData } = ResponseFilter(res)
      if (status) {
        this.setState({
          // loader: false,
          promotersList: resData.result,
          // totalCount: resData.count,
        })
      } else {
        this.setState({ loader: false })
      }
    })
  }

  promoterFilter = (id) => {
    this.setState(
      {
        promoterId: id || "",
      },
      () => this.eventList()
    )
  }

  handleCheckBox = (event, info) => {
    console.log(event, info)
    // let { _id, hasRevenueBeenPaidOut } = event;
    const accessToken = localStorage.getItem("accessToken")
    let id = event.target.value

    console.log(event.target.value)
    this.setState(
      (prevState) => {
        return {
          ...prevState,
          eventListDetails: this.state.eventListDetails.map((item) => {
            return item._id === id ? { ...item, hasRevenueBeenPaidOut: !item.hasRevenueBeenPaidOut } : item
          }),
        }
      },
      () => {
        let item = this.state.eventListDetails.filter((item) => item._id === id)[0]
        // let arr = [
        //   {
        //     key: "eventId",
        //     value: id,
        //   },
        //   {
        //     key: "hasRevenueBeenUpdated",
        //     value: item.hasRevenueBeenPaidOut
        //   },
        //   {
        //     key: "startTime",
        //     value: item.startTime,
        //   },
        //   {
        //     key: "endTime",
        //     value: item.endTime,
        //   },
        //   {
        //     key: "eventName",
        //     value: item.eventName,
        //   },
        //   {
        //     key: "eventDate",
        //     value: item.eventDate,
        //   },
        //   {
        //     key: "categoryId",
        //     value: item.categoryId
        //   }
        // ]
        usersService
          .updatePayoutStatus({ _id: item._id, hasRevenueBeenPaidOut: item.hasRevenueBeenPaidOut }, accessToken)
          .then((res) => {
            ResponseFilter(res)
            if (res.data.statusCode === 1) {
              console.log("success")
            } else {
              console.log("failure")
            }
          })
      }
    )
  }

  handleDeleteDraftEvent = (eventId) => {
    let obj = {
      eventId: eventId,
    }
    const accessToken = localStorage.getItem("accessToken")
    usersService.deleteDraftEvent(obj, accessToken).then((res) => {
      ResponseFilter(res)
      if (res.data.statusCode === 1) {
        message.success("Event deleted Successfully!")
        this.closeModal()
        this.eventList()
      } else {
        this.closeModal()
        this.eventList()
      }
    })
  }
  eventList = () => {
    const currentTimeZoneName = Intl.DateTimeFormat().resolvedOptions().timeZone

    let { limit, sortOrder, sortBy, page, status, startDate, endDate, promoterId } = this.state
    let obj = {
      currentTimeZoneName: currentTimeZoneName,
      limit: limit,
      // createdBy: localStorage.getItem('userId'),
      sortOrder: sortOrder,
      sortBy: sortBy,
      status: status,
      createdAfter: startDate || "",
      createdBefore: endDate || "",
      page: page - 1,
      createdBy: promoterId || "",
    }

    this.setState({ loader: true })
    usersService.eventList(obj).then((res) => {
      ResponseFilter(res)
      if (res.data.statusCode === 1) {
        this.setState({
          eventListDetails: res.data.responseData.result.map((item) => {
            return item.hasOwnProperty("hasRevenueBeenPaidOut") ? item : { ...item, hasRevenueBeenPaidOut: false }
          }),
          totalCount: res.data.responseData.count,
          loader: false,
        })
      } else {
        this.setState({ loader: false })
      }
    })
  }

  handleOpenDeletePopup = (eventIdDelete) =>
    this.setState({
      openDeletePopup: !this.state.openDeletePopup,
      eventIdDelete,
    })

  openEventPage = (eventId, name1) => {
    var name = name1.replace(/ /g, "-")
    var eventName = toLower(name)
    switch (process.env.REACT_APP_ENV) {
      case "stag":
      case "staging":
        const url1 = `https://stag.farechild.com/event/${eventId}/${encodeURIComponent(eventName)}`
        window.open(url1, "_blank")
        break

      case "dev":
      case "development":
        const url2 = `https://dev.farechild.com/event/${eventId}/${encodeURIComponent(eventName)}`
        window.open(url2, "_blank")

        break
      case "prod":
      case "production":
        const url3 = `https://events.farechild.com/event/${eventId}/${encodeURIComponent(eventName)}`

        window.open(url3, "_blank")

        break
      default:
        const url4 = `http://localhost:3000/event/${eventId}/${encodeURIComponent(eventName)}`
        window.open(url4, "_blank")
    }
  }
  handleFilter = (e) => {
    this.setState({ status: e ? e : "", page: 1 }, () => this.eventList())
  }

  //sort by name
  handleSort = (name) => {
    this.setState({ sortBy: name, sortOrder: this.state.sortOrder === "asc" ? "desc" : "asc" }, () => {
      this.eventList()
    })
  }

  handleCancel = (event, picker) => {
    this.setState(
      {
        event: "",
        pageNo: 1,
        startDate: "",
        endDate: "",
      },
      () => {
        this.eventList()
      }
    )
    picker.element.val("")
    console.log("cancel")
  }

  handleRangePicker = (event, picker) => {
    // var date = new Date(picker.endDate);
    picker.element.val(picker.startDate.format("MM/DD/YYYY") + " - " + picker.endDate.format("MM/DD/YYYY"))

    this.setState(
      {
        pageNo: 1,
        startDate: !_.isEmpty(picker.startDate) ? new Date(picker.startDate).getTime() : "",
        endDate: !_.isEmpty(picker.endDate) ? new Date(picker.endDate).getTime() : "",
        // endDate: !_.isEmpty(picker.endDate)
        //   ? date.setUTCHours(date.getDate())
        //   : '',
      },
      () => {
        this.eventList()
      }
    )
  }

  render() {
    let { loader } = this.state

    return (
      <div className="dark-sidebar">
        {loader ? <Loader /> : null}
        <div className="main-container">
          <div className="container-fluid page-body-wrapper">
            <nav className="navbar fixed-top">
              <div className="navbar-menu-container d-flex align-items-center justify-content-center">
                <div className="sub-heading">
                  <h4 className="page-title ">ALL EVENTS</h4>
                </div>
                <div className="text-center navbar-brand-container align-items-center justify-content-center">
                  <a className="brand-logo" href="/">
                    <img src={require("../../assets/images/mobile-logo.png")} alt="" className="mb-3" />
                  </a>
                </div>

                <TopBar {...this.props} />
              </div>
            </nav>
            <SideNav />
            <div className="main-panel">
              <div className="content-wrapper manage-event">
                <div className="row card-margin">
                  <div className="col-lg-4 col-md-4 col-sm-12">
                    <div className="card card-margin mb-4 mb-lg-0 mb-md-0">
                      <div className="card-body p-0">
                        <div className="widget-20" style={{ minHeight: "unset" }}>
                          <div className="widget-20-header">
                            <div className="widget-20-content">
                              <h5 className="widget-20-title">Total Events</h5>
                              {/* <span className="widget-20-info">
                                {this.state.eventnameShow &&
                                this.state.eventnameShow !== ''
                                  ? this.state.eventnameShow
                                  : 'All'}
                                  All
                              </span> */}
                            </div>
                            <h3 className="widget-20-figure">
                              {/* {this.state.pageVisitorsShow
                                ? this.state.pageVisitorsShow
                                : 0} */}
                              {this.state.totals.total ? this.state.totals.total : 0}
                            </h3>
                          </div>
                          {/* <div className="widget-20-chart">
                            <img
                              src={require('../../assets/images/black-graph.jpg')}
                              className="img-fluid"
                            />
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-12">
                    <div className="card card-margin mb-4 mb-lg-0 mb-md-0">
                      <div className="card-body p-0">
                        <div className="widget-20" style={{ minHeight: "unset" }}>
                          <div className="widget-20-header">
                            <div className="widget-20-content">
                              <h5 className="widget-20-title">Active Events</h5>
                              {/* <span className="widget-20-info">
                                {this.state.eventnameShow &&
                                this.state.eventnameShow !== ''
                                  ? this.state.eventnameShow
                                  : 'All'}
                                  All
                              </span> */}
                            </div>
                            <h3 className="widget-20-figure">
                              {/* {this.state.pageVisitorsShow
                                ? this.state.pageVisitorsShow
                                : 0} */}
                              {this.state.totals.active ? this.state.totals.active : 0}
                            </h3>
                          </div>
                          {/* <div className="widget-20-chart">
                            <img
                              src={require('../../assets/images/black-graph.jpg')}
                              className="img-fluid"
                            />
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-12">
                    <div className="card card-margin mb-4 mb-lg-0 mb-md-0">
                      <div className="card-body p-0">
                        <div className="widget-20" style={{ minHeight: "unset" }}>
                          <div className="widget-20-header">
                            <div className="widget-20-content">
                              <h5 className="widget-20-title">Completed Events</h5>
                              {/* <span className="widget-20-info">
                                {this.state.eventnameShow &&
                                this.state.eventnameShow !== ''
                                  ? this.state.eventnameShow
                                  : 'All'}
                                  All
                              </span> */}
                            </div>
                            <h3 className="widget-20-figure">
                              {/* {this.state.pageVisitorsShow
                                ? this.state.pageVisitorsShow
                              : 0} */}
                              {this.state.totals.completed ? this.state.totals.completed : 0}
                            </h3>
                          </div>
                          {/* <div className="widget-20-chart">
                            <img
                              src={require('../../assets/images/black-graph.jpg')}
                              className="img-fluid"
                            />
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row card-margin">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-header card-new-header">
                        <div className="row d-flex">
                          <div className="col-sm-5">
                            <h5 className="card-title">
                              All Events
                              <small className="card-title-secondary">View and filter events below</small>
                            </h5>
                          </div>
                          <div className="col-md-3 col-sm-12 mb-2 mb-lg-0 mb-md-0">
                            <div className="input-group  rang-picker">
                              <DateRangePicker
                                initialSettings={{
                                  autoUpdateInput: true,
                                  locale: {
                                    cancelLabel: "Clear",
                                  },
                                  // startDate: moment().subtract(1, 'months').format("MM/DD/YYYY"),
                                  // endDate: moment().format("MM/DD/YYYY"),
                                  startDate: moment(this.state.startDate).format("MM/DD/YYYY"),
                                  endDate: moment(this.state.endDate).format("MM/DD/YYYY"),
                                }}
                                onApply={this.handleRangePicker}
                                onCancel={this.handleCancel}
                              >
                                <input
                                  type="text"
                                  className="form-control bg-white"
                                  name="date1"
                                  readOnly
                                  id="date1"
                                  placeholder="Select Date Range"
                                />
                              </DateRangePicker>
                              <span className="input-group-text">
                                <RangPicker />
                              </span>
                            </div>
                          </div>
                          <div className="col-md-2">
                            {" "}
                            {/* mt-3 mt-md-0 */}
                            <Select
                              showSearch
                              style={{ width: 200 }}
                              placeholder="Select a Promoter"
                              optionFilterProp="children"
                              className="order-filter"
                              onFocus={this.onFocus}
                              onBlur={this.onBlur}
                              onSearch={this.onSearch}
                              onChange={this.promoterFilter}
                            >
                              <Option value="">All</Option>
                              {!_.isEmpty(this.state.promotersList)
                                ? this.state.promotersList
                                    // .filter(
                                    //   (event) =>
                                    //     event.status === 2 ||
                                    //     event.status === 3
                                    // )
                                    .map((item) => <Option value={item._id}>{item.name}</Option>)
                                : ""}
                            </Select>
                          </div>
                          <div className="col-sm-2 text-sm-right mt-3 mt-sm-0">
                            <Select
                              className="status_button"
                              value={this.state.status ? this.state.status : "Filter by Event Type"}
                              style={{ minWidth: 140 }}
                              onChange={(e) => this.handleFilter(e)}
                            >
                              <Option value="">
                                <i class="far fa-check-square"></i>All
                              </Option>
                              <Option value="2">
                                <Active className="m-3" />
                                Live
                              </Option>
                              <Option value="1">
                                <Draft />
                                Draft
                              </Option>
                              <Option value="3">
                                <Completed />
                                Completed
                              </Option>
                            </Select>
                          </div>
                          {/* <div className="col-md-2">
                          {order.length == 0 ? (
                          <button className="btn btn-sm btn-dark" disabled>
                            Export Results
                          </button>
                        ) : (
                          <CSVLink
                            filename={`Event_Report-Results_${
                              today ? today : ''
                            }.csv`}
                            data={order}
                            headers={headers}
                            className="btn btn-sm btn-dark"
                          >
                            Export Results
                          </CSVLink>
                        )}
                          </div> */}
                        </div>
                      </div>

                      <div className="card-body">
                        <div className="table-responsive">
                          <table className="table widget-8">
                            <thead>
                              <tr>
                                <th className="border-0">Promoter</th>
                                <th className="border-0 cursor-pointer" onClick={() => this.handleSort("startTimeStamp")}>
                                  <i className="fa fa-sort"></i>Event
                                </th>
                                <th className="border-0">Status</th>
                                <th className="border-0">Quick Stats</th>
                                <th className="border-0 text-center">Paid Out?</th>
                                <th className="border-0 text-center">View</th>
                                <th className="border-0 text-center">Edit</th>
                                <th className="border-0 text-center">Delete</th>
                              </tr>
                            </thead>
                            <tbody>
                              {!_.isEmpty(this.state.eventListDetails) ? (
                                <React.Fragment>
                                  {this.state.eventListDetails.map((info) => (
                                    <tr>
                                      <td>
                                        <div className="widget-8-product-info">
                                        <Link
                                            className="btn btn-sm btn-flash-primary"
                                            to={`/promoter-profile/${info.userId}`}
                                            
                                          >
                                          {info.userName ? info.userName : ""}
                                          </Link>
                                        </div>
                                      </td>
                                      <td>
                                        <div className="widget-8-title-wrapper">
                                          {info.eventImage ? (
                                            <img
                                              className="widget-8-img"
                                              src={info.eventImage ? info.eventImage : ""}
                                              alt=""
                                              title=""
                                            />
                                          ) : (
                                            <span className="widget-8-img">
                                              <Image />
                                            </span>
                                          )}

                                          <div className="widget-8-product-info">
                                            <div className="title event-title-name">
                                              {info.eventName ? info.eventName : ""}
                                            </div>
                                            <div className="attribute">
                                              {info.eventPlatform == 1 ? (
                                                <>
                                                  {info.eventDate[0]
                                                    ? moment(info.eventDate[0]).format("MMMM DD ,yyyy")
                                                    : moment(info.eventDate[0]).format("MMMM DD ,yyyy")}
                                                </>
                                              ) : (
                                                <>
                                                  {info.startTimeStamp
                                                    ? moment(info.startTimeStamp).format("MMMM DD, yyyy")
                                                    : moment(info.startTimeStamp).format("MMMM DD, yyyy")}
                                                </>
                                              )}{" "}
                                              {info.eventPlatform == 2 ? (
                                                <>{info.timeZone ? <>({info.timeZone})</> : ""}</>
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </td>
                                      <td>
                                        {info.status === 1 ? (
                                          <div className="widget-8-status-wrapper">
                                            <div className="badge badge-pill badge-outline-secondary">Draft</div>
                                          </div>
                                        ) : info.status === 2 ? (
                                          <div className="widget-8-status-wrapper">
                                            <div className="badge badge-pill badge-outline-success">Live</div>
                                          </div>
                                        ) : (
                                          <div className="widget-8-status-wrapper">
                                            <div className="badge badge-pill badge-outline-warning">Completed</div>
                                          </div>
                                        )}
                                      </td>
                                      <td>
                                        <div className="widget-8-desc row">
                                          <div className="widget-8-order-wrapper" style={{ width: 150 }}>
                                            <div className="figure">
                                              {info.totalTicketSold ? info.totalTicketSold : 0}/
                                              {(info.totalTickets ? info.totalTickets : 0) +
                                                (info.totalTicketSold ? info.totalTicketSold : 0)}
                                              <small className="pl-1 text-success">
                                                <strong>
                                                  {info.totalTicketSold === 0
                                                    ? 0
                                                    : Number(
                                                        ((info.totalTicketSold ? info.totalTicketSold : 0) /
                                                          ((info.totalTickets ? info.totalTickets : 0) +
                                                            (info.totalTicketSold ? info.totalTicketSold : 0))) *
                                                          100
                                                      ).toFixed(2)}
                                                  %
                                                </strong>
                                              </small>
                                            </div>
                                            <div className="desc">Tickets Sold</div>
                                          </div>
                                          <div className="widget-8-return-wrapper" style={{ width: 100 }}>
                                            <div className="figure">
                                              ${info.subTotal ? Number(info.subTotal).toFixed(2) : 0}
                                            </div>
                                            <div className="desc">Promoter Revenue</div>
                                          </div>
                                          <div className="widget-8-return-wrapper" style={{ width: 150 }}>
                                            <div className="figure">
                                              ${info.grossRevenue ? Number(info.grossRevenue).toFixed(2) : 0}
                                            </div>
                                            <div className="desc">Ticketing Fees</div>
                                          </div>
                                          <div className="widget-8-earning-wrapper " style={{ width: 100 }}>
                                            <div className="figure">{info.eventVisitor ? info.eventVisitor : 0}</div>
                                            <div className="desc">Page Visitors</div>
                                          </div>
                                        </div>
                                      </td>
                                      <td>
                                        <input
                                          type="checkbox"
                                          className="ml-3"
                                          name="hasBeenPaidOut"
                                          value={info._id}
                                          checked={info.hasRevenueBeenPaidOut}
                                          onClick={(event) => this.handleCheckBox(event, info)}
                                        />
                                        {/* <label className="string-check-label" for="formCheckInput25">
                                                    <span className="ml-2">Create Public Event</span>
                                                  </label> */}
                                      </td>
                                      <td className="text-center">
                                        {info.status === 1 ? (
                                          "_"
                                        ) : (
                                          <Link onClick={() => this.openEventPage(info._id, info.eventName)}>
                                            <span className="view_page">
                                              <EyeIcon />
                                            </span>
                                          </Link>
                                        )}
                                      </td>

                                      <td className="text-center">
                                        {info.status === 1 ? (
                                          <Link
                                            className="btn btn-sm btn-flash-primary"
                                            to={`/create-event/${info._id}`}
                                            type="button"
                                            title="Edit event"
                                          >
                                            <Edit />
                                          </Link>
                                        ) : info.status === 2 ? (
                                          <React.Fragment>
                                            <Link
                                              className="btn btn-sm btn-flash-primary"
                                              to={`/manage-event/edit-event/${info._id}`}
                                              type="button"
                                              title="Edit event"
                                            >
                                              <Edit />
                                            </Link>
                                          </React.Fragment>
                                        ) : (
                                          <React.Fragment>
                                            <Link
                                              className="btn btn-sm btn-flash-primary"
                                              to={{
                                                pathname: `/manage-event/after-event-update/${info._id}`,
                                                state: {
                                                  eventName: info.eventName,
                                                },
                                              }}
                                              type="button"
                                              title="Edit event"
                                            >
                                              <Edit />
                                            </Link>
                                          </React.Fragment>
                                        )}
                                      </td>

                                      <td className="text-center">
                                        {info.totalTicketSold === 0 ? (
                                          <span
                                            className="view_page"
                                            onClick={() => this.handleOpenDeletePopup(info._id)}
                                          >
                                            <Delete />
                                          </span>
                                        ) : (
                                          "_"
                                        )}
                                      </td>
                                    </tr>
                                  ))}
                                </React.Fragment>
                              ) : (
                                ""
                              )}
                            </tbody>
                          </table>
                        </div>
                        {this.state.eventListDetails.length > 0 ? (
                          ""
                        ) : (
                          <div className="text-center">
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                          </div>
                        )}
                      </div>
                      <div className="card-footer bg-white">
                        <div className="d-flex align-items-center justify-content-between flex-column flex-sm-row mt-4">
                          {this.state.eventListDetails.length > 0 ? (
                            <p className="mb-3 mb-sm-0">
                              Showing{" "}
                              {this.state.page == 1 ? (
                                <React.Fragment>1</React.Fragment>
                              ) : (
                                this.state.page * this.state.limit - this.state.limit + 1
                              )}{" "}
                              to{" "}
                              {this.state.eventListDetails
                                ? this.state.eventListDetails.length === this.state.limit
                                  ? this.state.page * this.state.limit - this.state.limit + this.state.limit
                                  : this.state.page * this.state.limit -
                                    this.state.limit +
                                    this.state.eventListDetails.length
                                : ""}{" "}
                              of {this.state.totalCount} events
                            </p>
                          ) : (
                            <p className="mb-3 mb-sm-0">Showing 0 to 0 of 0 events</p>
                          )}

                          <nav>
                            <ul>
                              <Pagination
                                activePage={this.state.page}
                                itemsCountPerPage={this.state.limit}
                                totalItemsCount={this.state.totalCount}
                                pageRangeDisplayed={5}
                                onChange={this.handlePageChange}
                              />
                            </ul>
                          </nav>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
        <Modal
          size="md"
          show={this.state.openDeletePopup}
          onHide={this.closeModal}
          aria-labelledby="example-modal-sizes-title-sm"
        >
          <Modal.Header closeButton></Modal.Header>
          <div className="cm_modal">
            <div className="modal-body">
              <h4 className="mb-4">Are you sure you want to delete this event?</h4>
              <br />

              <div className="bg-white pull-right mb-4">
                <button className="btn btn-light" type="button" onClick={this.closeModal}>
                  No
                </button>
                <button
                  type="submit"
                  className="btn btn-primary ml-2"
                  onClick={() => this.handleDeleteDraftEvent(this.state.eventIdDelete)}
                >
                  Yes
                </button>
              </div>
              <br />
              <br />
            </div>
          </div>
        </Modal>
      </div>
    )
  }
}

export default AdminEvents
