/* eslint-disable no-undef */
import React, { Component } from 'react';
import EventTopBar from '../event-template/EventTopBar';
import EventFooter from '../event-template/EventFooter';
import { Zoom } from 'react-reveal';
import { Tooltip } from 'antd';

class Privacy extends Component {
  constructor() {
    super();
    this.copy = this.copy.bind(this);
  }

  componentDidMount() {
    document.title = 'Privacy | Farechild';
    window.scrollTo(0, 0);
  }
  redirectTo = () => {
    switch (process.env.REACT_APP_ENV) {
      case 'stag':
      case 'staging':
        const url1 = `https://stag.farechild.com/`;
        window.open(url1, '_blank');
        break;
      case 'dev':
      case 'development':
        const url2 = `https://dev.farechild.com/`;
        window.open(url2, '_blank');
        break;
      case 'prod':
      case 'production':
        const url3 = `https://events.farechild.com`;
        window.open(url3, '_blank');
        break;
      default:
        const url4 = `http://localhost:3000/`;
        window.open(url4, '_blank');
    }
  };
  copy() {
    const el = document.createElement('input');
    el.value = 'info@farechild.com';
    el.id = 'url-input';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    el.remove();
  }

  render() {
    const text = (
      <div>
        <span>
          <i
            className="fas fa-copy mr-2 ml-2"
            title="Copy Email Address"
            onClick={this.copy}
          ></i>
        </span>{' '}
        <span>
          {' '}
          <a
            className="website-link-open"
            href="mailto:info@farechild.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fas fa-envelope ml-2 mr-2" title="Send Email"></i>
          </a>
        </span>
      </div>
    );

    return (
      <React.Fragment>
        <EventTopBar {...this.props} />

        <div className="success-content privacy-terms-page">
          <Zoom>
            <h1 className="pb-2 mt-3">Privacy Policy</h1>
          </Zoom>

          <p>
            {' '}
            <br />
            <br />
            Protecting your private information is our priority. This Statement
            of Privacy applies to{' '}
            <span onClick={this.redirectTo} className="website-link">
              www.farechild.com
            </span>{' '}
            and Farechild Inc. and governs data collection and usage. For the
            purposes of this Privacy Policy, unless otherwise noted, all
            references to Farechild Inc. include{' '}
            <span onClick={this.redirectTo} className="website-link">
              www.farechild.com
            </span>{' '}
            and Farechild. The Farechild website is a ecommerce site. By using
            the Farechild website, you consent to the data practices described
            in this statement.
          </p>

          <Zoom>
            <h2>Collection of your Personal Information </h2>
            <hr className="term-hr"></hr>
          </Zoom>

          <p>
            In order to better provide you with products and services offered on
            our Site, Farechild may collect personally identifiable information,
            such as your:
            <ul type="square">
              <span>
                <i className="fas fa-square mr-2"></i>
              </span>{' '}
              First and Last Name <br />
              <span>
                <i className="fas fa-square mr-2"></i>
              </span>{' '}
              Mailing Address
              <br />
              <span>
                <i className="fas fa-square mr-2"></i>
              </span>{' '}
              E-mail Address <br />
              <span>
                <i className="fas fa-square mr-2"></i>
              </span>{' '}
              Phone Number .<br />
              <br />
            </ul>
            If you purchase Farechild's products and services, we collect
            billing and credit card information. This information is used to
            complete the purchase transaction.
            <br />
            <br />
            We do not collect any personal information about you unless you
            voluntarily provide it to us. However, you may be required to
            provide certain personal information to us when you elect to use
            certain products or services available on the Site. These may
            include:
            <ul>
              <span>(a).</span> registering for an account on our Site;
              <br />
              <span>(b).</span> entering a sweepstakes or contest sponsored by
              us or one of our partners;
              <br />
              <span>(c).</span> signing up for special offers from selected
              third parties; <br />
              <span>(d).</span> sending us an email message;
              <br />
              <span>(e).</span> submitting your credit card or other payment
              information when ordering and purchasing products and services on
              our Site.
              <br />
              <br />
            </ul>
            To wit, we will use your information for, but not limited to,
            communicating with you in relation to services and/or products you
            have requested from us. We also may gather additional personal or
            non-personal information in the future. <br />
            <br />
          </p>
          <br />
          <br />

          <Zoom>
            <h2>Use of your Personal Information </h2>
            <hr className="term-hr"></hr>
          </Zoom>
          <p>
            Farechild collects and uses your personal information to operate its
            website(s) and deliver the services you have requested.
            <br />
            <br />
            Farechild may also use your personally identifiable information to
            inform you of other products or services available from Farechild
            and its affiliates.
          </p>
          <br />
          <br />

          <Zoom>
            {' '}
            <h2>Sharing Information with Third Parties </h2>
            <hr className="term-hr"></hr>
          </Zoom>
          <p>
            Farechild does not sell, rent or lease its customer lists to third
            parties.
            <br />
            <br />
            Farechild may, from time to time, contact you on behalf of external
            business partners about a particular offering that may be of
            interest to you. In those cases, your unique personally identifiable
            information (e-mail, name, address, telephone number) is not
            transferred to the third party. Farechild may share data with
            trusted partners to help perform statistical analysis, send you
            email or postal mail, provide customer support, or arrange for
            deliveries. All such third parties are prohibited from using your
            personal information except to provide these services to Farechild,
            and they are required to maintain the confidentiality of your
            information
            <br />
            <br />
            Farechild may disclose your personal information, without notice, if
            required to do so by law or in the good faith belief that such
            action is necessary to:
            <ul>
              <span>(a).</span>conform to the edicts of the law or comply with
              legal process served on Farechild or the site; <br />
              <span>(b).</span>protect and defend the rights or property of
              Farechild; and/or <br />
              <span>(c).</span>act under exigent circumstances to protect the
              personal safety of users of Farechild, or the public. <br />
            </ul>
          </p>
          <br />
          <br />

          <Zoom>
            {' '}
            <h2>Tracking User Behavior </h2>
            <hr className="term-hr"></hr>
          </Zoom>
          <p>
            Farechild may keep track of the websites and pages our users visit
            within Farechild, in order to determine what Farechild services are
            the most popular. This data is used to deliver customized content
            and advertising within Farechild to customers whose behavior
            indicates that they are interested in a particular subject area.
          </p>
          <br />
          <br />

          <Zoom>
            {' '}
            <h2>Automatically Collected Information </h2>
            <hr className="term-hr"></hr>
          </Zoom>
          <p>
            Information about your computer hardware and software may be
            automatically collected by Farechild. This information can include:
            your IP address, browser type, domain names, access times and
            referring website addresses. This information is used for the
            operation of the service, to maintain quality of the service, and to
            provide general statistics regarding use of the Farechild website.
          </p>
          <br />
          <br />

          <Zoom>
            {' '}
            <h2>Use of Cookies </h2>
            <hr className="term-hr"></hr>
          </Zoom>
          <p>
            The Farechild website may use "cookies" to help you personalize your
            online experience. A cookie is a text file that is placed on your
            hard disk by a web page server. Cookies cannot be used to run
            programs or deliver viruses to your computer. Cookies are uniquely
            assigned to you, and can only be read by a web server in the domain
            that issued the cookie to you.
            <br />
            <br />
            One of the primary purposes of cookies is to provide a convenience
            feature to save you time. The purpose of a cookie is to tell the Web
            server that you have returned to a specific page. For example, if
            you personalize Farechild pages, or register with Farechild site or
            services, a cookie helps Farechild to recall your specific
            information on subsequent visits. This simplifies the process of
            recording your personal information, such as billing addresses,
            shipping addresses, and so on. When you return to the same Farechild
            website, the information you previously provided can be retrieved,
            so you can easily use the Farechild features that you customized.
            <br />
            <br />
            You have the ability to accept or decline cookies. Most Web browsers
            automatically accept cookies, but you can usually modify your
            browser setting to decline cookies if you prefer. If you choose to
            decline cookies, you may not be able to fully experience the
            interactive features of the Farechild services or websites you
            visit.
          </p>
          <br />
          <br />

          <Zoom>
            {' '}
            <h2>Links </h2>
            <hr className="term-hr"></hr>
          </Zoom>
          <p>
            This website contains links to other sites. Please be aware that we
            are not responsible for the content or privacy practices of such
            other sites. We encourage our users to be aware when they leave our
            site and to read the privacy statements of any other site that
            collects personally identifiable information.
          </p>
          <br />
          <br />

          <Zoom>
            {' '}
            <h2>Security of your Personal Information </h2>
            <hr className="term-hr"></hr>
          </Zoom>
          <p>
            Farechild secures your personal information from unauthorized
            access, use, or disclosure. Farechild uses the following methods for
            this purpose:
            <ul>
              <span>
                <i className="fas fa-square mr-2"></i>
              </span>
              SSL Protocol
            </ul>
            If you purchase Farechild's products and services, we collect
            billing and credit card information. This information is used to
            complete the purchase transaction.
            <br />
            <br />
            When personal information (such as a credit card number) is
            transmitted to other websites, it is protected through the use of
            encryption, such as the Secure Sockets Layer (SSL) protocol.
            <br />
            <br />
            We strive to take appropriate security measures to protect against
            unauthorized access to or alteration of your personal information.
            Unfortunately, no data transmission over the Internet or any
            wireless network can be guaranteed to be 100% secure. As a result,
            while we strive to protect your personal information, you
            acknowledge that:
            <ul>
              <span>(a).</span>there are security and privacy limitations
              inherent to the Internet which are beyond our control; and
              <br />
              <span>(b).</span>security, integrity, and privacy of any and all
              information and data exchanged between you and us through this
              Site cannot be guaranteed.
            </ul>
          </p>
          <br />
          <br />

          <Zoom>
            {' '}
            <h2>Right to Deletion </h2>
            <hr className="term-hr"></hr>
          </Zoom>
          <p>
            Subject to certain exceptions set out below, on receipt of a
            verifiable request from you, we will:
            <ul>
              <span>
                <i className="fas fa-square mr-2"></i>
              </span>
              Delete your personal information from our records; and <br />
              <span>
                <i className="fas fa-square mr-2"></i>
              </span>
              Direct any service providers to delete your personal information
              from their records.
            </ul>
            <br />
            Please note that we may not be able to comply with requests to
            delete your personal information if it is necessary to:
            <ul>
              <span>
                <i className="fas fa-square mr-2"></i>
              </span>
              Complete the transaction for which the personal information was
              collected, fulfill the terms of a written warranty or product
              recall conducted in accordance with federal law, provide a good or
              service requested by you, or reasonably anticipated within the
              context of our ongoing business relationship with you, or
              otherwise perform a contract between you and us; <br />
              <span>
                <i className="fas fa-square mr-2"></i>
              </span>
              Detect security incidents, protect against malicious, deceptive,
              fraudulent, or illegal activity; or prosecute those responsible
              for that activity; <br />
              <span>
                <i className="fas fa-square mr-2"></i>
              </span>
              Debug to identify and repair errors that impair existing intended
              functionality; <br />
              <span>
                <i className="fas fa-square mr-2"></i>
              </span>
              Exercise free speech, ensure the right of another consumer to
              exercise his or her right of free speech, or exercise another
              right provided for by law; <br />
              <span>
                <i className="fas fa-square mr-2"></i>
              </span>
              Comply with the California Electronic Communications Privacy Act;{' '}
              <br />
              <span>
                <i className="fas fa-square mr-2"></i>
              </span>
              Engage in public or peer-reviewed scientific, historical, or
              statistical research in the public interest that adheres to all
              other applicable ethics and privacy laws, when our deletion of the
              information is likely to render impossible or seriously impair the
              achievement of such research, provided we have obtained your
              informed consent; <br />
              <span>
                <i className="fas fa-square mr-2"></i>
              </span>
              Enable solely internal uses that are reasonably aligned with your
              expectations based on your relationship with us; <br />
              <span>
                <i className="fas fa-square mr-2"></i>
              </span>
              Comply with an existing legal obligation; or <br />
              <span>
                <i className="fas fa-square mr-2"></i>
              </span>
              Otherwise use your personal information, internally, in a lawful
              manner that is compatible with the context in which you provided
              the information. <br />
            </ul>
          </p>
          <br />
          <br />
          <Zoom>
            {' '}
            <h2>Children Under Thirteen </h2>
            <hr className="term-hr"></hr>
          </Zoom>
          <p>
            Farechild does not knowingly collect personally identifiable
            information from children under the age of thirteen. If you are
            under the age of thirteen, you must ask your parent or guardian for
            permission to use this website.
          </p>
          <br />
          <br />
          <Zoom>
            {' '}
            <h2>E-mail Communications </h2>
            <hr className="term-hr"></hr>
          </Zoom>
          <p>
            From time to time, Farechild may contact you via email for the
            purpose of providing announcements, promotional offers, alerts,
            confirmations, surveys, and/or other general communication. In order
            to improve our Services, we may receive a notification when you open
            an email from Farechild or click on a link therein.
            <br />
            <br />
            If you would like to stop receiving marketing or promotional
            communications via email from Farechild, you may opt out of such
            communications by clicking on the UNSUBSCRIBE button.
          </p>
          <br />
          <br />

          <Zoom>
            {' '}
            <h2>External Data Storage Sites </h2>
            <hr className="term-hr"></hr>
          </Zoom>
          <p>
            We may store your data on servers provided by third party hosting
            vendors with whom we have contracted.
          </p>
          <br />
          <br />

          <Zoom>
            {' '}
            <h2>Changes to this Statement </h2>
            <hr className="term-hr"></hr>
          </Zoom>
          <p>
            Farechild reserves the right to change this Privacy Policy from time
            to time. We will notify you about significant changes in the way we
            treat personal information by sending a notice to the primary email
            address specified in your account, by placing a prominent notice on
            our site, and/or by updating any privacy information on this page.
            Your continued use of the Site and/or Services available through
            this Site after such modifications will constitute your:
            <br />
            <ul>
              <span>(a).</span>acknowledgment of the modified Privacy Policy;
              and <br />
              <span>(b).</span>agreement to abide and be bound by that Policy.{' '}
              <br />
            </ul>
          </p>
          <br />
          <br />

          <Zoom>
            {' '}
            <h2>Contact Us </h2>
            <hr className="term-hr"></hr>
          </Zoom>
          <p>
            Farechild welcomes your questions or comments regarding the Terms:
            <br />
           
            <span>Email Address:</span> <br />
            <Tooltip placement="top" title={text}>
              <a className="website-link">info@farechild.com</a>
            </Tooltip>
            <br />
         
           
            <hr></hr>
            Effective as of September 15, 2020
          </p>
          <br />
          <br />
          <br />
          <br />
          <br />
        </div>
        <EventFooter />
      </React.Fragment>
    );
  }
}
export default Privacy;
