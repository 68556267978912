import Cookies from 'js-cookie';
const ResponseFilter = (response) => {
  var status = response.data.statusCode;
  var resData;

  if (status) {
    resData = response.data.responseData;
  } else if (
    response.data.error.errorCode === 12 ||
    response.data.error.errorCode === 10
  ) {
    localStorage.clear();
    localStorage.removeItem('quantTotal');
    localStorage.removeItem('selectObjList');
    localStorage.removeItem('subTotal');
    localStorage.removeItem('eventID');
    localStorage.removeItem('orderTicketList');
    localStorage.removeItem('PerTicketFee');
    localStorage.removeItem('CCFees');
    localStorage.removeItem('TransactionFee');
    localStorage.removeItem('perTicketFeesValue');
    localStorage.removeItem('ccFeesValue');
    localStorage.removeItem('transactionFeesValue');
    localStorage.removeItem('checkoutCustomFields');
    localStorage.removeItem('checkoutFields');
    window.location.href = '/';
  } else if (response.data.error.errorCode === 22) {
    localStorage.clear();
    localStorage.removeItem('quantTotal');
    localStorage.removeItem('selectObjList');
    localStorage.removeItem('subTotal');
    localStorage.removeItem('eventID');
    localStorage.removeItem('orderTicketList');
    localStorage.removeItem('PerTicketFee');
    localStorage.removeItem('CCFees');
    localStorage.removeItem('TransactionFee');
    localStorage.removeItem('perTicketFeesValue');
    localStorage.removeItem('ccFeesValue');
    localStorage.removeItem('transactionFeesValue');
    localStorage.removeItem('checkoutCustomFields');
    localStorage.removeItem('checkoutFields');
    window.location.href = '/';
  } else if (response.data.error.errorCode === 2) {
    localStorage.clear();
    localStorage.removeItem('quantTotal');
    localStorage.removeItem('selectObjList');
    localStorage.removeItem('subTotal');
    localStorage.removeItem('eventID');
    localStorage.removeItem('orderTicketList');
    localStorage.removeItem('PerTicketFee');
    localStorage.removeItem('CCFees');
    localStorage.removeItem('TransactionFee');
    localStorage.removeItem('perTicketFeesValue');
    localStorage.removeItem('ccFeesValue');
    localStorage.removeItem('transactionFeesValue');
    localStorage.removeItem('checkoutCustomFields');
    localStorage.removeItem('checkoutFields');
    window.location.href = '/';
  } else {
    resData = response.data.error.responseMessage;
  }
  return {
    status,
    resData,
  };
};
export default ResponseFilter;
