/* eslint-disable no-undef */
import React from 'react';
import ReactImageVideoLightbox from '../../../../node_modules/react-image-video-lightbox';
import { Link } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel2';
import EventTopBar from '../event-template/EventTopBar';
import EventFooter from '../event-template/EventFooter';
import ImageSharePopup from '../event-pages/ImageSharePopup';
import { Loader } from '../../../config/Svgicon';
import _, { toLower } from 'lodash';
import { Helmet } from 'react-helmet';
import { message, Input } from 'antd';
import moment from 'moment';
import { mapStyle } from '../../../utils';
import { usersService } from '../../../_services/usersService';
import ResponseFilter from '../../../config/response-handler';
import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from 'react-share';
import ModalReact from 'react-bootstrap/Modal';

const getFeedImageVideoArray = (image, video) => {
  let imagearr = image.map((or) => ({
    url: or,
    type: 'photo',
  }));

  let videosarr = video.map((or) => ({
    url: or,
    type: 'video',
  }));
  var images11 = [...imagearr, ...videosarr];

  return images11;
};
const { compose, withProps, withStateHandlers } = require('recompose');
const {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow,
} = require('react-google-maps');

const MapComponent = compose(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_MAP_KEY}&v=3.exp&libraries=geometry,drawing,places`,
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `600px` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withStateHandlers(
    () => ({
      isOpen: false,
    }),
    {
      onToggleOpen:
        ({ isOpen }) =>
        () => ({
          isOpen: !isOpen,
        }),
    }
  ),
  withScriptjs,
  withGoogleMap
)((props) => (
  <GoogleMap
    defaultCenter={{ lat: props.lat, lng: props.lng }}
    defaultZoom={13}
    defaultOptions={{ styles: props.mapStyle }}
  >
    <Marker
      position={{ lat: props.lat, lng: props.lng }}
      onClick={props.onToggleOpen}
      icon={{
        url: require('../../../assets/images/marker.svg'),
      }}
    >
      {props.isOpen && (
        <InfoWindow onCloseClick={props.onToggleOpen}>
          <h6>
            {props.loaction}, {props.city}, {props.state}
          </h6>
        </InfoWindow>
      )}
    </Marker>
  </GoogleMap>
));

class EventAfter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openVideoPopup: false,
      comment: '',
      reply: '',
      disabledBtn: false,
      eventPlatform: '',
      loaction: '',
      lng: '',
      lat: '',
      newsFeedDetails: [],
      eventAfterImage: [],
      imagesArr: [],
      videoArr: [],
      like: 0,
      loader: false,
      actionType: false,
      index: 0,
      indexNewfeed: 0,
      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {},
      eventID: this.props.eventId,
      selectedReplyBox: '',
      eventFollowers: [],
      promoterFollowers: [],
      editCommentBox: '',
      editReplyBox: '',
      allNewsFeedImages: [],
      visible: 2,
      newsfeedIndex: '',
      commentIndex: '',
      relatedEventList: [],
      confirmPasswordErrorMsg: '',
      zip: '',
      items: [],
      allNewsFeedVideos: [],
      eventDate: [],
      timeZone: '',
      eventDays: [],
      endTimeStamp: '',
      startTimeStamp: '',
      allNewsFeedVideosPlay: [],
      lengthNo: '',
      indxNo: '',
      openEventPasswordPopup: false,
      modal2Visible: false,
      imageModalVisible: false,
    };
    this.showCommentMore = this.showCommentMore.bind(this);
    this.hideComment = this.hideComment.bind(this);
    this.loadMore = this.loadMore.bind(this);
    this.loadLess = this.loadLess.bind(this);
    this.openReplyBox = this.openReplyBox.bind(this);
    this.openEditBox = this.openEditBox.bind(this);
    this.openEditReplyBox = this.openEditReplyBox.bind(this);
  }
  redirectTo = (eventId, name1) => {
    var name = name1.replace(/ /g, '-');
    var eventName = toLower(name);
    switch (process.env.REACT_APP_ENV) {
      case 'stag':
      case 'staging':
        const url1 = `https://stag.farechild.com/event/${eventId}/${encodeURIComponent(
          eventName
        )}`;
        window.open(url1, '_blank');
        break;
      case 'dev':
      case 'development':
        const url2 = `https://dev.farechild.com/event/${eventId}/${encodeURIComponent(
          eventName
        )}`;
        window.open(url2, '_blank');
        break;
      case 'prod':
      case 'production':
        const url3 = `https://events.farechild.com/event/${eventId}/${encodeURIComponent(
          eventName
        )}`;
        window.open(url3, '_blank');
        break;
      default:
        const url4 = `http://localhost:3000/event/${eventId}/${encodeURIComponent(
          eventName
        )}`;
        window.open(url4, '_blank');
    }
  };
  handleOpenDeletePopup = (url) =>
    this.setState({ openVideoPopup: !this.state.openVideoPopup, url });

  setModal2Visible = (item, i) => {
    this.setState({
      indexNewfeed: i,
      modal2Visible: !this.state.modal2Visible,
      imagesArr: item.newsfeedImage,
      videoArr: item.newsfeedVideo,
    });
  };
  setImageModalVisible = (i) => {
    this.setState({
      imageModalVisible: !this.state.imageModalVisible,
      index: i,
    });
  };

  closeModal = () => {
    this.setState({
      indexNewfeed: 0,
      modal2Visible: false,
      imageModalVisible: false,
      openVideoPopup: false,
    });
  };
  openEditBox(index, i, data) {
    this.setState({
      comment: data.comment,
      editCommentBox: index ? index : 0,
      newsfeedIndex: i ? i : 0,
    });
  }
  openEditReplyBox(indx, i, index, sub) {
    this.setState({
      reply: sub.subcomment,
      editReplyBox: indx ? indx : 0,
      newsfeedIndex: i ? i : 0,
      commentIndex: index ? index : 0,
    });
  }
  openReplyBox(index, i) {
    this.setState({
      selectedReplyBox: index ? index : 0,
      newsfeedIndex: i ? i : 0,
    });
  }

  closeReplyBox(i, index) {
    this.setState({
      selectedReplyBox: this.state.selectedReplyBox === '',
      newsfeedIndex: '',
    });
    this.state.newsFeedDetails[i].comments[index].reply = '';
    this.getNewsFeedList();
  }

  componentDidMount() {
    let obj = {
      eventId: this.props.eventId,
    };
    usersService
      .eventDetails(obj)
      .then((data) => {
        this.setState({
          eventPassword: data.data.responseData.result.eventPassword,
        });
        if (this.state.eventPassword) {
          this.setState({
            openEventPasswordPopup: !this.state.openEventPasswordPopup,
            checkpassword: this.state.eventPassword,
          });
        }
      })
      .catch((error) => {});

    this.getEventDetails();
    this.eventList();
    this.getNewsFeedList();
  }

  checkpasswordSubmit = () => {
    let { checkpassword, password } = this.state;
    if (password.trim() === '' || password.trim() === undefined) {
      this.setState({
        confirmPasswordErrorMsg: 'Please enter password.',
      });
    } else if (checkpassword !== password) {
      this.setState({
        confirmPasswordErrorMsg:
          'Entered password and event password does not match.',
      });
    } else {
      this.setState({
        openEventPasswordPopup: false,
        confirmPasswordErrorMsg: '',
      });
    }
  };

  deleteReplyHandler = (replyId) => {
    if (localStorage.getItem('userId')) {
      let obj = {
        replyId: replyId,
        status: 2,
      };
      const accessToken = localStorage.getItem('accessToken');
      usersService.deleteCommentReply(obj, accessToken).then((res) => {
        ResponseFilter(res);
        if (res.data.statusCode === 1) {
          this.getNewsFeedList();
          // message.success('Comment deleted successfully!');
        } else {
          this.getNewsFeedList();
        }
      });
    } else {
      message.warning('Please Login or Signup first', 10);
    }
  };

  deleteCommentHandler = (commentId) => {
    if (localStorage.getItem('userId')) {
      let obj = {
        commentId: commentId,
        status: 2,
      };
      const accessToken = localStorage.getItem('accessToken');
      usersService.deleteComment(obj, accessToken).then((res) => {
        ResponseFilter(res);
        if (res.data.statusCode === 1) {
          this.getNewsFeedList();
          //message.success('Comment deleted successfully!');
        } else {
          this.getNewsFeedList();
        }
      });
    } else {
      message.warning('Please Login or Signup first', 10);
    }
  };

  setCommentLike = (commentId) => {
    if (localStorage.getItem('userId')) {
      let params = `commentId=${commentId}&actionType=${1}`;
      {
        const accessToken = localStorage.getItem('accessToken');
        usersService.newsFeedCommentLike(params, accessToken).then((res) => {
          ResponseFilter(res);
          if (res.data.statusCode === 1) {
            this.getNewsFeedList();
            // message.success('Like Successfully!');
          } else {
            this.getNewsFeedList();
          }
        });
      }
    } else {
      message.warning('Please Login or Signup first', 10);
    }
  };

  setCommentUnLike = (commentId) => {
    if (localStorage.getItem('userId')) {
      let params = `commentId=${commentId}&actionType=${2}`;
      {
        const accessToken = localStorage.getItem('accessToken');
        usersService.newsFeedCommentLike(params, accessToken).then((res) => {
          ResponseFilter(res);
          if (res.data.statusCode === 1) {
            // message.success('Unlike Successfully!');
            this.getNewsFeedList();
          } else {
            this.getNewsFeedList();
          }
        });
      }
    } else {
      message.warning('Please Login or Signup first', 10);
    }
  };

  setLike = (newsfeedId, i) => {
    if (localStorage.getItem('userId')) {
      let params = `newsfeedId=${newsfeedId}&actionType=${1}`;
      {
        const accessToken = localStorage.getItem('accessToken');
        usersService.newsfeedLike(params, accessToken).then((res) => {
          ResponseFilter(res);
          if (res.data.statusCode === 1) {
            // message.success('Like Successfully!');
            this.getNewsFeedList();
          } else {
            this.getNewsFeedList();
          }
        });
      }
    } else {
      message.warning('Please Login or Signup first', 10);
    }
  };

  setUnLike = (newsfeedId, i) => {
    if (localStorage.getItem('userId')) {
      let params = `newsfeedId=${newsfeedId}&actionType=${2}`;
      {
        const accessToken = localStorage.getItem('accessToken');
        usersService.newsfeedLike(params, accessToken).then((res) => {
          ResponseFilter(res);
          if (res.data.statusCode === 1) {
            // message.success('Unlike Successfully!');
            this.getNewsFeedList();
          } else {
            this.getNewsFeedList();
          }
        });
      }
    } else {
      message.warning('Please Login or Signup first', 10);
    }
  };

  getNewsFeedList = () => {
    let params = `eventId=${this.props.eventId}`;
    usersService.newsFeedList(params).then((res) => {
      let { status, resData } = ResponseFilter(res);
      if (status) {
        this.setState({
          newsfeedIndex: '',
          allNewsFeedImages: resData.allNewsFeedImages
            ? resData.allNewsFeedImages
            : [],
          allNewsFeedVideosPlay: resData.allNewsFeedVideos
            ? resData.allNewsFeedVideos
            : [],
          newsFeedDetails: resData.result,
          totalCount: resData.count,
        });
      }
    });
  };

  getEventDetails = () => {
    let obj = {
      eventId: this.props.eventId,
    };
    usersService.eventDetails(obj).then((res) => {
      let { status, resData } = ResponseFilter(res);
      if (status) {
        this.props.stopLoader();
        let {
          successPageMessage,
          eventImage,
          eventName,
          tagline,
          aboutTheEvent,
          categoryId,
          eventPlatform,
          city,
          state,
          zip,
          address2,
          endTime,
          eventDate,
          timeZone,
          eventDays,
          endTimeStamp,
          startTimeStamp,
          eventPassword,
          eventVideo,
          privateType,
          startTime,
          status,
          latlng,
          ticketMin,
          ticketMax,
          createdBy,
          eventFollowers,
          promoterFollowers,
          eventPayout,
          aboutPromoter,
          promoterImage,
          promoterFacebookLink,
          promoterInstaUsername,
          promoterLinkedInLink,
          promoterTwitterUsername,
          eventAfterImage,
        } = resData.result;

        this.setState({
          eventAfterImage: eventAfterImage ? eventAfterImage : [],
          zip,
          address2: address2 == 'undefined' ? '' : address2,
          eventName,
          successPageMessage,
          eventPayout,
          ticketMin,
          ticketMax,
          eventImage,
          tagline,
          aboutTheEvent,
          city,
          state,
          categoryId,
          eventPlatform,
          loaction: latlng ? latlng.name : '',
          endTime,
          endTimeStamp,
          startTimeStamp,
          timeZone,
          eventDays: eventDays ? eventDays : [],
          eventDate: eventDate.sort((a, b) => Date.parse(a) - Date.parse(b)),
          eventPassword,
          eventVideo,
          privateType,
          startTime,
          hour: startTime ? startTime.split(' ').slice(0, 1).join('') : '',
          minute: startTime ? startTime.split(' ').slice(1, 2).join() : '',
          meridian: startTime ? startTime.split(' ').slice(2, 3).join('') : '',
          hourEnd: endTime ? endTime.split(' ').slice(0, 1).join('') : '',
          minuteEnd: endTime ? endTime.split(' ').slice(1, 2).join() : '',
          meridianEnd: endTime ? endTime.split(' ').slice(2, 3).join('') : '',
          status,
          createdBy,
          lat: latlng ? latlng.coordinates[0] : '',
          lng: latlng ? latlng.coordinates[1] : '',
          point: latlng ? latlng.type : '',
          truemap: true,
          eventFollowers,
          promoterFollowers,
          aboutPromoter,
          promoterImage,
          promoterFacebookLink,
          promoterInstaUsername,
          promoterLinkedInLink,
          promoterTwitterUsername,
        });
      } else {
        this.props.stopLoader();
      }
    });
  };

  eventList = () => {
    const currentTimeZoneName =
      Intl.DateTimeFormat().resolvedOptions().timeZone;

    let obj = {
      currentTimeZoneName: currentTimeZoneName,
      categoryId: this.state.categoryId,
      limit: 4,
      page: 0,
      status: 2,
      typeOfEvent: 1,
    };
    usersService.eventList(obj).then((res) => {
      ResponseFilter(res);
      if (res.data.statusCode === 1) {
        window.scrollTo(0, 0);
        let relatedEventList = [];
        let eventsData = 0;
        let eventsList = res.data.responseData.result;
        for (var l = 0; l < eventsList.length; l++) {
          eventsData = eventsList[l];
          if (eventsData._id !== this.props.eventId) {
            relatedEventList.push(eventsData);
          }
          this.setState({ relatedEventList: relatedEventList });
        }
        this.setState({
          eventData: res.data.responseData.result,
          totalCount: res.data.responseData.count,
        });
      }
    });
  };

  redirectTo = (eventId, name1) => {
    var name = name1.replace(/ /g, '-');
    var eventName = toLower(name);
    switch (process.env.REACT_APP_ENV) {
      case 'stag':
      case 'staging':
        const url1 = `https://stag.farechild.com/event/${eventId}/${encodeURIComponent(
          eventName
        )}`;
        window.open(url1, '_blank');
        break;
      case 'dev':
      case 'development':
        const url2 = `https://dev.farechild.com/event/${eventId}/${encodeURIComponent(
          eventName
        )}`;
        window.open(url2, '_blank');
        break;
      case 'prod':
      case 'production':
        const url3 = `https://events.farechild.com/event/${eventId}/${encodeURIComponent(
          eventName
        )}`;
        window.open(url3, '_blank');
        break;
      default:
        const url4 = `http://localhost:3000/event/${eventId}/${encodeURIComponent(
          eventName
        )}`;
        window.open(url4, '_blank');
    }
  };

  handleSubmitComment = (newsfeedId, i) => {
    if (localStorage.getItem('userId')) {
      this.setState({ disabledBtn: true });
      let obj = {
        eventId: this.props.eventId,
        newsfeedId: newsfeedId,
        comment: this.state.newsFeedDetails[i].comment,
      };
      let token = localStorage.getItem('accessToken');
      usersService.newsFeedComment(obj, token).then((data) => {
        ResponseFilter(data);
        if (data.data.statusCode === 1) {
          this.state.newsFeedDetails[i].comment = '';
          //message.success('Comment added successfully!');
          this.setState({ disabledBtn: false });
          this.getNewsFeedList();
        } else {
          this.setState({ disabledBtn: false });
          this.setState({
            errors: data.data.error.responseMessage,
          });
        }
      });
    } else {
      this.state.newsFeedDetails[i].comment = '';
      message.warning('Please Login or Signup first', 10);
    }
  };

  handleChangeReply(i, index, e) {
    const { name, value } = e.target;
    let newsFeedDetails = [...this.state.newsFeedDetails];
    newsFeedDetails[i].comments[index] = {
      ...newsFeedDetails[i].comments[index],
      [name]: value,
    };
    this.setState({ newsFeedDetails });
  }

  handleSubmitCommentReply = (commentId, i, index) => {
    if (localStorage.getItem('userId')) {
      this.setState({ disabledBtn: true });
      let obj = {
        commentId: commentId,

        reply: this.state.newsFeedDetails[i].comments[index].reply,
      };
      let token = localStorage.getItem('accessToken');

      usersService.newsFeedCommentReply(obj, token).then((data) => {
        ResponseFilter(data);
        if (data.data.statusCode === 1) {
          this.state.newsFeedDetails[i].comments[index].reply = '';
          //message.success('Comment added successfully!');
          this.setState({ disabledBtn: false });
          this.getNewsFeedList();
        } else {
          this.setState({ disabledBtn: false });
          this.setState({
            errors: data.data.error.responseMessage,
          });
        }
      });
    } else {
      message.warning('Please Login or Signup first', 10);
    }
  };

  handleEditReply = (replyId) => {
    if (localStorage.getItem('userId')) {
      let params = {
        subCommentId: replyId,
        reply: this.state.reply,
      };
      const accessToken = localStorage.getItem('accessToken');
      usersService.editNewsFeedCommentReply(params, accessToken).then((res) => {
        ResponseFilter(res);
        if (res.data.statusCode === 1) {
          this.setState({ reply: '', editReplyBox: '', newsfeedIndex: '' });
          //message.success('Comment updated successfully!');
          this.getNewsFeedList();
        } else {
          this.getNewsFeedList();
        }
      });
    } else {
      message.warning('Please Login or Signup first', 10);
    }
  };

  handleEditComment = (commentId) => {
    if (localStorage.getItem('userId')) {
      let params = {
        commentId: commentId,
        comment: this.state.comment,
      };
      const accessToken = localStorage.getItem('accessToken');
      usersService.editNewsFeedComment(params, accessToken).then((res) => {
        ResponseFilter(res);
        if (res.data.statusCode === 1) {
          //message.success('Comment updated successfully!');
          this.setState({ comment: '', editCommentBox: '', newsfeedIndex: '' });
          this.getNewsFeedList();
        } else {
          this.getNewsFeedList();
        }
      });
    } else {
      message.warning('Please Login or Signup first', 10);
    }
  };

  handleFollow = (eventId) => {
    if (localStorage.getItem('userId')) {
      let params = `eventId=${eventId}`;
      {
        const accessToken = localStorage.getItem('accessToken');
        usersService.follow(params, accessToken).then((res) => {
          ResponseFilter(res);
          if (res.data.statusCode === 1) {
            this.getEventDetails();
            // message.success('Follow Successfully!');
          } else {
            this.getEventDetails();
          }
        });
      }
    } else {
      message.warning('Please Login or Signup first', 10);
    }
  };

  handleFollowPromoter = (promoterId) => {
    if (localStorage.getItem('userId')) {
      let params = `promoterId=${promoterId}`;
      {
        const accessToken = localStorage.getItem('accessToken');
        usersService.follow(params, accessToken).then((res) => {
          ResponseFilter(res);
          if (res.data.statusCode === 1) {
            this.getEventDetails();
            // message.success('Follow Successfully!');
          } else {
            this.getEventDetails();
          }
        });
      }
    } else {
      message.warning('Please Login or Signup first', 10);
    }
  };

  handleUnFollow = (eventId) => {
    if (localStorage.getItem('userId')) {
      let params = `eventId=${eventId}`;
      {
        const accessToken = localStorage.getItem('accessToken');
        usersService.unfollow(params, accessToken).then((res) => {
          ResponseFilter(res);
          if (res.data.statusCode === 1) {
            this.getEventDetails();
            // message.success('Unfollow Successfully!');
          } else {
            this.getEventDetails();
          }
        });
      }
    } else {
      message.warning('Please Login or Signup first', 10);
    }
  };

  handleUnFollowPromoter = (promoterId) => {
    if (localStorage.getItem('userId')) {
      let params = `promoterId=${promoterId}`;
      {
        const accessToken = localStorage.getItem('accessToken');
        usersService.unfollow(params, accessToken).then((res) => {
          ResponseFilter(res);
          if (res.data.statusCode === 1) {
            this.getEventDetails();
            // message.success('Unfollow Successfully!');
          } else {
            this.getEventDetails();
          }
        });
      }
    } else {
      message.warning('Please Login or Signup first', 10);
    }
  };

  handleCancelComment = (i) => {
    this.state.newsFeedDetails[i].comment = '';
    this.getNewsFeedList();
  };

  handleChangeInput(i, e) {
    const { name, value } = e.target;
    let newsFeedDetails = [...this.state.newsFeedDetails];
    newsFeedDetails[i] = { ...newsFeedDetails[i], [name]: value };
    this.setState({ newsFeedDetails });
  }

  handleChange = (e) =>
    this.setState({
      [e.target.name]: e.target.value,
      [e.target.name + 'ErrorMsg']: '',
    });

  enterEditMode = () => {
    if (!this.state.editMode) {
      this.setState({
        editMode: true,
      });
    }
  };

  loadMore() {
    this.setState((prev) => {
      return { visible: prev.visible + 2 };
    });
  }

  loadLess() {
    this.setState((prev) => {
      return { visible: 2 };
    });
  }
  showCommentMore(i, lengthNo) {
    this.setState({ indxNo: i, lengthNo: lengthNo });
  }
  hideComment(i) {
    this.setState((prev) => {
      return { indxNo: '', lengthNo: '' };
    });
  }

  render() {
    var days = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ];
    let {
      promoterFacebookLink,
      promoterInstaUsername,
      promoterLinkedInLink,
      promoterTwitterUsername,
      openEventPasswordPopup,
      confirmPasswordErrorMsg,
      eventDate,
      timeZone,
      eventDays,
      eventAfterImage,
      eventName,
      endTimeStamp,
      startTimeStamp,
      startTime,
      endTime,
    } = this.state;
    var nameReplace = eventName ? eventName.replace(/ /g, '-') : '';
    var eventName1 = toLower(nameReplace);
    const urlTwitter = `http://twitter.com/${promoterTwitterUsername}`;
    const urlInstagram = ` https://www.instagram.com/${promoterInstaUsername}`;
    let userId = localStorage.getItem('userId');
    let profileImage = localStorage.getItem('profileImage');

    const options = {
      margin: 30,

      dots: true,
      mouseDrag: true,
      touchDrag: true,

      rewind: false,

      responsive: {
        0: {
          items: 1,
          mouseDrag: false,
          touchDrag: true,
        },
        480: {
          items: 1,
          mouseDrag: false,
          touchDrag: true,
        },
        600: {
          items: 1,
          mouseDrag: false,
          touchDrag: true,
        },
        1000: {
          items: 1,
          mouseDrag: false,
          touchDrag: true,
        },
        1300: {
          items: 2,
        },
        1500: {
          items: 3,
        },
      },
    };

    let images = eventAfterImage.map((or) => ({
      url: or,
      type: 'photo',
    }));

    // let activeImageUrl =
    //   images.length > 0 &&
    //   images &&
    //   images[(this.state.index && this.state.index) || 0].url;

    let imagearr = this.state.imagesArr.map((or) => ({
      url: or,
      type: 'photo',
      altTag: 'placeholder image',
    }));

    let videosarr = this.state.videoArr.map((or) => ({
      url: or,
      type: 'video',
      altTag: 'placeholder video',
    }));

    var images11 = [...imagearr, ...videosarr];

    var parse = require('html-react-parser');

    let url = this.state.eventVideo ? this.state.eventVideo : '';
    var srcLink,
      youTubePoster,
      r,
      rx =
        /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
    if (url !== '') {
      r = url.match(rx);
      youTubePoster = `https://i.ytimg.com/vi/${r[1]}/hqdefault.jpg`;
      srcLink = `https://www.youtube.com/embed/${r[1]}?autoplay=1&mute=1`;
    }

    return (
      <div className="event-bdy">
        {this.state.loader ? <Loader /> : null}

        <Helmet>
          <title>{`${
            this.state.eventName ? this.state.eventName : 'Event'
          } | Farechild`}</title>
          <script
            type="text/javascript"
            src="https://addevent.com/libs/atc/1.6.1/atc.min.js"
            async
            defer
          ></script>
        </Helmet>

        <div className="base-wrapper">
          <EventTopBar {...this.props} />
          <div className="banner-main">
            <div className="event-title">
              <h2 className="text-uppercase  font-weight-bold mb-0 mt-3">
                {this.state.eventName ? this.state.eventName : ''}
              </h2>
              <p className="m-0 tagline">
                {this.state.tagline ? this.state.tagline : ''}
              </p>
            </div>
            <div className="content-event event-auto-hg">
              <img
                src={this.state.eventImage ? this.state.eventImage : ''}
                alt="Event"
                className="event-banner-img"
              />
            </div>
          </div>
          <div className="content-wrapper-home">
            <div className="sidebar-overlay"></div>

            <div className="content content-event event event-auto-hg">
              <div className="row no-gutters">
                <div className="col-lg-7 border-right border-xs-right-none border-sm-right-none order-2 order-xl-1 order-lg-1 order-sm-2">
                  <div className="event-detail-wrapper pt-4 pr-lg-5 pr-xl-5 pr-md-5">
                    <h3 className="text-uppercase border-bottom font-weight-bold pb-2 mb-3">
                      Event Photos
                    </h3>

                    <div className="mb-3">
                      <div className="event-img-container">
                        <div className="event-img-gallery row">
                          {!_.isEmpty(eventAfterImage) ? (
                            <React.Fragment>
                              {eventAfterImage.map((img, i) => {
                                return (
                                  <div className="col-4">
                                    <div
                                      className="upload-img-list"
                                      onClick={() =>
                                        this.setImageModalVisible(i)
                                      }
                                    >
                                      <img src={img} alt="Showcase 1" />
                                    </div>
                                  </div>
                                );
                              })}
                            </React.Fragment>
                          ) : (
                            <div className="event-img-gallery-empty row m-auto">
                              <img
                                className="placeholder-gallery"
                                src={require('../../../assets/images/no_image.png')}
                                alt=""
                              />
                            </div>
                            //   <div className="text-center">
                            //   <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            // </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5 order-xl-2 order-lg-2 order-1 order-sm-1">
                  {this.state.eventVideo ? (
                    <div className="event-info pt-5">
                      <h5 className="font-weight-bold text-capitalize mb-3">
                        <i className="fas fa-video font-size-22 mt-1 mr-4"></i>
                        Recent Video
                      </h5>
                      <div className="popup-youtube">
                        <embed src={youTubePoster} className="w-100"></embed>
                        {/* <i
                          class="fas fa-play-circle"
                          onClick={() => this.handleOpenDeletePopup(url)}
                        ></i> */}

                        <div>
                          <div
                            class="videoPoster js-videoPoster"
                            onClick={() => this.handleOpenDeletePopup(url)}
                          ></div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}

                  <div className="event-info pt-5">
                    <h5 className="font-weight-bold text-capitalize mb-3">
                      <i className="far fa-images font-size-22 mt-1 mr-4"></i>
                      About Event
                    </h5>
                    <p>
                      {parse(
                        this.state.aboutTheEvent ? this.state.aboutTheEvent : ''
                      )}
                    </p>

                    <div className="d-flex mb-5 mt-3 social-anchor">
                      <FacebookShareButton
                        url={`https://events.farechild.com/event/${this.props.eventId}/${eventName1}`}
                      >
                        <span className="text-decoration-none text-brand  p-2">
                          <i className="fab fa-facebook-f font-size-18"></i>
                        </span>
                      </FacebookShareButton>
                      <TwitterShareButton
                        url={`https://events.farechild.com/event/${this.props.eventId}/${eventName1}`}
                        hashtag="event-details"
                      >
                        <span
                          href="#"
                          className="border-top border border-bottom border-right text-decoration-none text-brand p-2"
                        >
                          <i className="fab fa-twitter font-size-18"></i>
                        </span>
                      </TwitterShareButton>
                      <EmailShareButton
                        url={`https://events.farechild.com/event/${this.props.eventId}/${eventName1}`}
                        hashtag="event-details"
                      >
                        <span
                          href="#"
                          className="border-top border border-bottom border-right text-decoration-none text-brand p-2"
                        >
                          <i className="far fa-envelope font-size-18"></i>
                        </span>
                      </EmailShareButton>
                      <LinkedinShareButton
                        url={`https://events.farechild.com/event/${this.props.eventId}/${eventName1}`}
                        hashtag="event-details"
                      >
                        <span
                          href="#"
                          className="border-top border border-bottom border-right text-decoration-none text-brand p-2"
                        >
                          <i class="fab fa-linkedin-in font-size-18"></i>
                        </span>
                      </LinkedinShareButton>
                    </div>
                    {!_.isEmpty(this.state.newsFeedDetails) ? (
                      <h5 className="font-weight-bold text-capitalize mb-3">
                        <i className="far fa-file-alt font-size-22 mt-1 mr-4"></i>
                        Event Newsfeed
                      </h5>
                    ) : (
                      ''
                    )}

                    {!_.isEmpty(this.state.newsFeedDetails) ? (
                      <React.Fragment>
                        <React.Fragment>
                          {this.state.newsFeedDetails
                            .slice(0, this.state.visible)
                            .map((item, i) => {
                              return (
                                <div className="card-news mb-3">
                                  <div className="card-body p-0">
                                    <div className="feed p-4 border-bottom">
                                      <div className="feed-body pb-3">
                                        <div className="d-flex align-items-center mb-3">
                                          <img
                                            src={
                                              item.promoterProfileImage
                                                ? item.promoterProfileImage
                                                : require('../../../assets/images/placeholder.jpg')
                                            }
                                            alt="Event Promoter"
                                            title=""
                                            className="user-img mr-3"
                                          />
                                          <div className="d-flex flex-column">
                                            <h5 className="font-weight-bold mb-1 text-capitalize">
                                              {item.promoterName}
                                            </h5>
                                            <p className="m-0">
                                              {item.created
                                                ? Date(item.created)
                                                    .toString()
                                                    .split(' ')[0]
                                                : ''}
                                              ,{' '}
                                              {moment(item.created).format(
                                                'MMMM DD, yyyy'
                                              )}
                                            </p>
                                          </div>
                                        </div>
                                        <p className="page-msg">
                                          {parse(item.newsfeed)}
                                        </p>

                                        <div className="event-img-container">
                                          {getFeedImageVideoArray(
                                            item.newsfeedImage,
                                            item.newsfeedVideo
                                          ).length === 2 ? (
                                            <div className="event-img-gallery row">
                                              <div className="col-6">
                                                {getFeedImageVideoArray(
                                                  item.newsfeedImage,
                                                  item.newsfeedVideo
                                                )
                                                  .slice(0, 1)
                                                  .map((num) => (
                                                    <div
                                                      class="upload-img-list"
                                                      onClick={() =>
                                                        this.setModal2Visible(
                                                          item,
                                                          0
                                                        )
                                                      }
                                                    >
                                                      {num.type === 'photo' ? (
                                                        <img
                                                          src={num.url}
                                                          alt=""
                                                        />
                                                      ) : (
                                                        <iframe
                                                          class="ql-video  colsize-6"
                                                          frameborder="0"
                                                          allowfullscreen="true"
                                                          src={num.url}
                                                          title="video"
                                                        ></iframe>
                                                      )}
                                                      <div className="overlaylayer"></div>
                                                    </div>
                                                  ))}
                                              </div>
                                              <div className="col-6">
                                                {getFeedImageVideoArray(
                                                  item.newsfeedImage,
                                                  item.newsfeedVideo
                                                )
                                                  .slice(1, 2)
                                                  .map((num) => (
                                                    <div
                                                      class="upload-img-list"
                                                      onClick={() =>
                                                        this.setModal2Visible(
                                                          item,
                                                          1
                                                        )
                                                      }
                                                    >
                                                      {num.type === 'photo' ? (
                                                        <img
                                                          src={num.url}
                                                          alt=""
                                                        />
                                                      ) : (
                                                        <iframe
                                                          class="ql-video  colsize-6"
                                                          frameborder="0"
                                                          allowfullscreen="true"
                                                          src={num.url}
                                                          title="video"
                                                        ></iframe>
                                                      )}
                                                      <div className="overlaylayer"></div>
                                                    </div>
                                                  ))}
                                              </div>
                                            </div>
                                          ) : (
                                            <div className="event-img-gallery row">
                                              <div className="col-6">
                                                {getFeedImageVideoArray(
                                                  item.newsfeedImage,
                                                  item.newsfeedVideo
                                                )
                                                  .slice(0, 1)
                                                  .map((num, imagIndex) => (
                                                    <div
                                                      className="upload-img-list"
                                                      onClick={() =>
                                                        this.setModal2Visible(
                                                          item,
                                                          0
                                                        )
                                                      }
                                                    >
                                                      {num.type === 'photo' ? (
                                                        <img
                                                          src={num.url}
                                                          alt=""
                                                        />
                                                      ) : (
                                                        <iframe
                                                          class="ql-video colsize-6"
                                                          frameborder="0"
                                                          allowfullscreen="true"
                                                          src={num.url}
                                                          title="video"
                                                        ></iframe>
                                                      )}
                                                      <div className="overlaylayer"></div>
                                                    </div>
                                                  ))}
                                              </div>

                                              <div className="col-6">
                                                {getFeedImageVideoArray(
                                                  item.newsfeedImage,
                                                  item.newsfeedVideo
                                                )
                                                  .slice(1, 2)
                                                  .map((num, imagIndex) => (
                                                    <div
                                                      class="upload-img-list"
                                                      onClick={() =>
                                                        this.setModal2Visible(
                                                          item,
                                                          1
                                                        )
                                                      }
                                                    >
                                                      {num.type === 'photo' ? (
                                                        <img
                                                          src={num.url}
                                                          alt=""
                                                        />
                                                      ) : (
                                                        <iframe
                                                          class="ql-video colsize-6"
                                                          frameborder="0"
                                                          allowfullscreen="true"
                                                          src={num.url}
                                                          title="video"
                                                        ></iframe>
                                                      )}
                                                      <div className="overlaylayer"></div>
                                                    </div>
                                                  ))}
                                              </div>
                                              <div className="col-6">
                                                {getFeedImageVideoArray(
                                                  item.newsfeedImage,
                                                  item.newsfeedVideo
                                                )
                                                  .slice(2, 3)
                                                  .map((num, imagIndex) => (
                                                    <div
                                                      class="upload-img-list"
                                                      onClick={() =>
                                                        this.setModal2Visible(
                                                          item,
                                                          2
                                                        )
                                                      }
                                                    >
                                                      {num.type === 'photo' ? (
                                                        <img
                                                          src={num.url}
                                                          alt=""
                                                        />
                                                      ) : (
                                                        <iframe
                                                          class="ql-video colsize-6"
                                                          frameborder="0"
                                                          allowfullscreen="true"
                                                          src={num.url}
                                                          title="video"
                                                        ></iframe>
                                                      )}
                                                      <div className="overlaylayer"></div>
                                                    </div>
                                                  ))}
                                              </div>

                                              <div className="col-6">
                                                <div className="viewmore-list">
                                                  {getFeedImageVideoArray(
                                                    item.newsfeedImage,
                                                    item.newsfeedVideo
                                                  )
                                                    .slice(3, 4)
                                                    .map((num, imagIndex) => (
                                                      <div
                                                        class="upload-img-list"
                                                        onClick={() =>
                                                          this.setModal2Visible(
                                                            item,
                                                            3
                                                          )
                                                        }
                                                      >
                                                        {num.type ===
                                                        'photo' ? (
                                                          <img
                                                            src={num.url}
                                                            alt=""
                                                          />
                                                        ) : (
                                                          <iframe
                                                            class="ql-video colsize-6"
                                                            frameborder="0"
                                                            allowfullscreen="true"
                                                            src={num.url}
                                                            title="video"
                                                          ></iframe>
                                                        )}
                                                      </div>
                                                    ))}
                                                  {item.newsfeedImage.length +
                                                    item.newsfeedVideo.length >
                                                  4 ? (
                                                    <div
                                                      className="more-list "
                                                      onClick={() =>
                                                        this.setModal2Visible(
                                                          item,
                                                          3
                                                        )
                                                      }
                                                    >
                                                      +
                                                      {item.newsfeedImage
                                                        .length +
                                                        item.newsfeedVideo
                                                          .length -
                                                        4}
                                                    </div>
                                                  ) : (
                                                    ''
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                      <div className="feed-footer d-block d-sm-none">
                                        <div className="d-flex align-items-center pb-3 border-bottom">
                                          <div className="pr-3">
                                            <i className="text-danger fas fa-heart mr-1"></i>
                                            {item.totalLikes}
                                          </div>
                                          <span
                                            href="#"
                                            className="action-link active pl-3"
                                          >
                                            {item.totalComments} Comments
                                          </span>
                                        </div>

                                        <div className="d-flex align-items-center justify-content-between pt-3">
                                          {item.likes.includes(userId) ? (
                                            <span
                                              className="action-link"
                                              onClick={(newsfeedId) =>
                                                this.setUnLike(item._id, i)
                                              }
                                            >
                                              <i className="text-danger fas fa-heart mr-1"></i>
                                              Unlike
                                            </span>
                                          ) : (
                                            <span
                                              className="action-link"
                                              onClick={(newsfeedId) =>
                                                this.setLike(item._id, i)
                                              }
                                            >
                                              <i className="far fa-heart mr-1"></i>{' '}
                                              Like
                                            </span>
                                          )}
                                          <span className="action-link pl-3 pr-3">
                                            <i className="far fa-comment-dots mr-1"></i>{' '}
                                            Comment
                                          </span>
                                        </div>
                                      </div>
                                      <div className="feed-footer d-none d-sm-flex justify-content-between border-top pt-3">
                                        <div className="d-flex align-items-center">
                                          {item.likes.includes(userId) ? (
                                            <span
                                              className="action-link"
                                              onClick={(newsfeedId) =>
                                                this.setUnLike(item._id, i)
                                              }
                                            >
                                              <i className="text-danger fas fa-heart mr-1"></i>
                                              Unlike
                                            </span>
                                          ) : (
                                            <span
                                              className="action-link"
                                              onClick={(newsfeedId) =>
                                                this.setLike(item._id, i)
                                              }
                                            >
                                              <i className="far fa-heart mr-1"></i>{' '}
                                              Like
                                            </span>
                                          )}
                                          <span
                                            className="action-link pl-3 pr-3"
                                            onClick={() => {
                                              this[`roll-${i}`].focus();
                                            }}
                                          >
                                            <i className="far fa-comment-dots mr-1"></i>{' '}
                                            Comment
                                          </span>
                                        </div>
                                        <div className="d-flex align-items-center">
                                          <div className="pr-3">
                                            <i className="text-danger fas fa-heart mr-1"></i>
                                            {item.totalLikes}
                                          </div>
                                          <span
                                            href="#"
                                            className="action-link active pl-3"
                                          >
                                            {item.totalComments} Comments
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="feed-comment">
                                      {item.comments.map((data, index) => {
                                        return (
                                          <React.Fragment>
                                            {data.comment ? (
                                              <div className="d-flex p-3">
                                                <img
                                                  src={
                                                    data.commentedBy
                                                      .profileImage
                                                      ? data.commentedBy
                                                          .profileImage
                                                      : require('../../../assets/images/placeholder.jpg')
                                                  }
                                                  alt="Event Promoter"
                                                  title=""
                                                  className="user-img mr-3"
                                                />

                                                <div className="d-flex flex-column w-100">
                                                  <span className="text-brand font-weight-bold mb-1 text-capitalize">
                                                    {data.commentedBy
                                                      ? Object.values(
                                                          data.commentedBy.name
                                                        )
                                                      : ''}
                                                  </span>

                                                  {this.state.editCommentBox ===
                                                    index &&
                                                  this.state.newsfeedIndex ===
                                                    i ? (
                                                    <div className="w-100 home-form p-0">
                                                      <div className="form-group">
                                                        <textarea
                                                          placeholder="Edit comment"
                                                          className="form-control font-size-12 text-muted"
                                                          value={
                                                            this.state.comment
                                                          }
                                                          maxLength="500"
                                                          name="comment"
                                                          onChange={
                                                            this.handleChange
                                                          }
                                                        ></textarea>
                                                        {this.state.comment ? (
                                                          <button
                                                            type="button"
                                                            className="btn btn-link"
                                                            onClick={() =>
                                                              this.handleEditComment(
                                                                data._id
                                                              )
                                                            }
                                                            disabled={
                                                              this.state
                                                                .disabledBtn
                                                            }
                                                          >
                                                            Post
                                                          </button>
                                                        ) : (
                                                          ''
                                                        )}
                                                      </div>
                                                    </div>
                                                  ) : (
                                                    <p className="m-0">
                                                      {data.comment}
                                                    </p>
                                                  )}

                                                  <p>
                                                    <small>
                                                      {data.sublikes.includes(
                                                        userId
                                                      ) ? (
                                                        <span
                                                          className="text-brand mr-2 font-size-11"
                                                          onClick={() =>
                                                            this.setCommentUnLike(
                                                              data._id
                                                            )
                                                          }
                                                        >
                                                          Unlike
                                                        </span>
                                                      ) : (
                                                        <span
                                                          className="text-brand mr-2 font-size-11"
                                                          onClick={() =>
                                                            this.setCommentLike(
                                                              data._id
                                                            )
                                                          }
                                                        >
                                                          Like
                                                        </span>
                                                      )}

                                                      <span
                                                        className="text-brand mr-3 font-size-11"
                                                        onClick={() =>
                                                          this.openReplyBox(
                                                            index,
                                                            i
                                                          )
                                                        }
                                                      >
                                                        Reply
                                                      </span>
                                                      {userId ===
                                                      data.commentedBy._id ? (
                                                        <React.Fragment>
                                                          <span className="text-brand mr-3 font-size-11">
                                                            <i
                                                              className="far fa-edit edit-btn"
                                                              aria-hidden="true"
                                                              title="Edit"
                                                              onClick={() =>
                                                                this.openEditBox(
                                                                  index,
                                                                  i,
                                                                  data
                                                                )
                                                              }
                                                            ></i>
                                                          </span>
                                                          <span
                                                            className="text-brand mr-3 font-size-11"
                                                            onClick={() =>
                                                              this.deleteCommentHandler(
                                                                data._id
                                                              )
                                                            }
                                                          >
                                                            <i
                                                              className="far fa-trash-alt"
                                                              aria-hidden="true"
                                                              title="Delete"
                                                            ></i>
                                                          </span>
                                                        </React.Fragment>
                                                      ) : (
                                                        ''
                                                      )}
                                                      <span className="text-muted font-size-11 text-capitalize">
                                                        {moment(data.created)
                                                          .fromNow()
                                                          .charAt(0)
                                                          .toUpperCase() +
                                                          moment(data.created)
                                                            .fromNow()
                                                            .slice(1)}
                                                      </span>
                                                    </small>
                                                  </p>
                                                  <ul className="reply-list pl-0 pl-md-3">
                                                    {data.subcomments.map(
                                                      (sub, indx) => {
                                                        return (
                                                          <React.Fragment>
                                                            {sub.subcomment ? (
                                                              <div className="d-flex p-md-3 p-1">
                                                                {sub.subcommentUserDetails.map(
                                                                  (img) => {
                                                                    return (
                                                                      <img
                                                                        src={
                                                                          img.profileImage
                                                                            ? img.profileImage
                                                                            : require('../../../assets/images/placeholder.jpg')
                                                                        }
                                                                        alt="Event Promoter"
                                                                        title=""
                                                                        className="user-img mr-3"
                                                                      />
                                                                    );
                                                                  }
                                                                )}

                                                                <div className="d-flex flex-column w-100">
                                                                  {sub.subcommentUserDetails.map(
                                                                    (name) => {
                                                                      return (
                                                                        <span className="text-brand font-weight-bold mb-1 text-capitalize ">
                                                                          {
                                                                            name.name
                                                                          }
                                                                        </span>
                                                                      );
                                                                    }
                                                                  )}
                                                                  {this.state
                                                                    .editReplyBox ===
                                                                    indx &&
                                                                  this.state
                                                                    .newsfeedIndex ===
                                                                    i &&
                                                                  this.state
                                                                    .commentIndex ===
                                                                    index ? (
                                                                    <div className="w-100 home-form p-0">
                                                                      <div className="form-group">
                                                                        <textarea
                                                                          maxLength="500"
                                                                          placeholder="Edit reply"
                                                                          className="form-control font-size-12 text-muted"
                                                                          value={
                                                                            this
                                                                              .state
                                                                              .reply
                                                                          }
                                                                          name="reply"
                                                                          onChange={
                                                                            this
                                                                              .handleChange
                                                                          }
                                                                        ></textarea>
                                                                        {this
                                                                          .state
                                                                          .reply ? (
                                                                          <button
                                                                            type="button"
                                                                            className="btn  btn-link"
                                                                            onClick={() =>
                                                                              this.handleEditReply(
                                                                                sub._id
                                                                              )
                                                                            }
                                                                            disabled={
                                                                              this
                                                                                .state
                                                                                .disabledBtn
                                                                            }
                                                                          >
                                                                            Post
                                                                          </button>
                                                                        ) : (
                                                                          ''
                                                                        )}
                                                                      </div>
                                                                    </div>
                                                                  ) : (
                                                                    <p className="m-0">
                                                                      {
                                                                        sub.subcomment
                                                                      }
                                                                    </p>
                                                                  )}

                                                                  <p>
                                                                    <small>
                                                                      {sub.subcommentUserDetails.map(
                                                                        (
                                                                          id
                                                                        ) => {
                                                                          return userId ===
                                                                            id._id ? (
                                                                            <React.Fragment>
                                                                              <span className="text-brand mr-3 font-size-11">
                                                                                <i
                                                                                  className="far fa-edit edit-btn"
                                                                                  aria-hidden="true"
                                                                                  title="Edit"
                                                                                  onClick={() =>
                                                                                    this.openEditReplyBox(
                                                                                      indx,
                                                                                      i,
                                                                                      index,
                                                                                      sub
                                                                                    )
                                                                                  }
                                                                                ></i>
                                                                              </span>
                                                                              <span
                                                                                className="text-brand mr-3 font-size-11"
                                                                                onClick={() =>
                                                                                  this.deleteReplyHandler(
                                                                                    sub._id
                                                                                  )
                                                                                }
                                                                              >
                                                                                <i
                                                                                  className="far fa-trash-alt"
                                                                                  aria-hidden="true"
                                                                                  title="Delete"
                                                                                ></i>
                                                                              </span>
                                                                            </React.Fragment>
                                                                          ) : (
                                                                            ''
                                                                          );
                                                                        }
                                                                      )}
                                                                    </small>
                                                                  </p>
                                                                </div>
                                                              </div>
                                                            ) : (
                                                              ''
                                                            )}
                                                          </React.Fragment>
                                                        );
                                                      }
                                                    )}
                                                  </ul>
                                                </div>
                                              </div>
                                            ) : (
                                              ''
                                            )}

                                            {this.state.selectedReplyBox ===
                                              index &&
                                            this.state.newsfeedIndex === i ? (
                                              <ul className="reply-list">
                                                <li>
                                                  <div className="d-flex p-3">
                                                    <img
                                                      src={
                                                        profileImage
                                                          ? profileImage
                                                          : require('../../../assets/images/placeholder-1.jpg')
                                                      }
                                                      alt="Event Promoter"
                                                      className="user-img mr-3"
                                                    />
                                                    <div className="w-100 home-form ">
                                                      <div className="form-group">
                                                        <textarea
                                                          maxLength="500"
                                                          placeholder="Enter reply"
                                                          className="form-control font-size-12 text-muted"
                                                          value={data.reply}
                                                          name="reply"
                                                          onChange={this.handleChangeReply.bind(
                                                            this,
                                                            i,
                                                            index
                                                          )}
                                                        ></textarea>
                                                        {data.reply ? (
                                                          <div className="mt-3">
                                                            <span
                                                              className="cancel-btn mt-2"
                                                              onClick={() =>
                                                                this.closeReplyBox(
                                                                  i,
                                                                  index
                                                                )
                                                              }
                                                            >
                                                              Cancel
                                                            </span>
                                                            <button
                                                              className="btn  btn-link"
                                                              onClick={() =>
                                                                this.handleSubmitCommentReply(
                                                                  data._id,
                                                                  i,
                                                                  index
                                                                )
                                                              }
                                                              disabled={
                                                                this.state
                                                                  .disabledBtn
                                                              }
                                                            >
                                                              Post
                                                            </button>
                                                          </div>
                                                        ) : (
                                                          ''
                                                        )}
                                                      </div>
                                                    </div>
                                                  </div>
                                                </li>
                                              </ul>
                                            ) : (
                                              ''
                                            )}
                                          </React.Fragment>
                                        );
                                      })}

                                      <div className="comment-panel">
                                        <div className="d-flex p-3">
                                          <img
                                            src={
                                              profileImage
                                                ? profileImage
                                                : require('../../../assets/images/placeholder-1.jpg')
                                            }
                                            alt="Event Promoter"
                                            className="user-img mr-3"
                                          />
                                          <div className="w-100 home-form ">
                                            <div className="form-group">
                                              <textarea
                                                maxLength="500"
                                                ref={(input) =>
                                                  (this[`roll-${i}`] = input)
                                                }
                                                placeholder="Write comment"
                                                className="form-control font-size-12 text-muted"
                                                rows="2"
                                                value={item.comment}
                                                name="comment"
                                                onChange={this.handleChangeInput.bind(
                                                  this,
                                                  i
                                                )}
                                              ></textarea>
                                              {item.comment ? (
                                                <div className="mt-3">
                                                  <span
                                                    className="cancel-btn mt-2"
                                                    onClick={() =>
                                                      this.handleCancelComment(
                                                        i
                                                      )
                                                    }
                                                  >
                                                    Cancel
                                                  </span>
                                                  <button
                                                    className="btn  btn-link"
                                                    onClick={(newsfeedId) =>
                                                      this.handleSubmitComment(
                                                        item._id,
                                                        i
                                                      )
                                                    }
                                                    disabled={
                                                      this.state.disabledBtn
                                                    }
                                                  >
                                                    Post
                                                  </button>
                                                </div>
                                              ) : (
                                                ''
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                        </React.Fragment>
                        {this.state.visible <
                        this.state.newsFeedDetails.length ? (
                          <span
                            onClick={this.loadMore}
                            className="btn btn-back btn-outline-brand border-brand font-size-14 font-weight-bold pl-4 pr-4 pt-2 pb-2 text-brand mb-3"
                          >
                            READ MORE
                            <i class="fas fa-angle-double-down ml-3"></i>
                          </span>
                        ) : this.state.newsFeedDetails.length > 2 ? (
                          <span
                            onClick={this.loadLess}
                            className="btn btn-back btn-outline-brand border-brand font-size-14 font-weight-bold pl-4 pr-4 pt-2 pb-2 text-brand mb-3"
                          >
                            READ LESS
                            <i class="fas fa-angle-double-up ml-3"></i>
                          </span>
                        ) : (
                          ''
                        )}
                      </React.Fragment>
                    ) : (
                      ''
                    )}

                    <div className="text-xl-left text-md-left text-lg-left text-center">
                      <React.Fragment>
                        {this.state.eventFollowers.includes(userId) ? (
                          <span
                            onClick={() =>
                              this.handleUnFollow(this.props.eventId)
                            }
                            className="btn btn-outline-brand border-brand mb-3 font-size-14 font-weight-bold pl-4 pr-4 pt-2 pb-2 text-brand"
                          >
                            <i className="fas fa-plus mr-2"></i>
                            Unfollow Event
                          </span>
                        ) : (
                          <span
                            onClick={() =>
                              this.handleFollow(this.props.eventId)
                            }
                            className="btn btn-outline-brand border-brand mb-3 font-size-14 font-weight-bold pl-4 pr-4 pt-2 pb-2 text-brand"
                          >
                            <i className="fas fa-plus mr-2"></i>
                            Follow Event
                          </span>
                        )}
                      </React.Fragment>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {this.state.eventPlatform === 2 ? (
              <div className="mt-5"></div>
            ) : (
              <React.Fragment>
                <div className="event-location mt-5">
                  {this.state.truemap ? (
                    <MapComponent
                      isMarkerShown
                      lat={this.state.lng}
                      lng={this.state.lat}
                      mapStyle={mapStyle}
                      loaction={this.state.loaction}
                      city={this.state.city}
                      state={this.state.state}
                    />
                  ) : (
                    ''
                  )}
                </div>
              </React.Fragment>
            )}
            <div
              className={`event-address-container ${
                !_.isEmpty(this.state.relatedEventList) ? '' : 'manage-bg'
              }`}
            >
              <div className="event-address">
                <div className="row">
                  <div className="col-12">
                    <div className="bg-white">
                      <div className="border-bottom text-center ml-4 mr-4 pt-4 pb-4">
                        <h4 className="font-weight-bold text-capitalize">
                          {this.state.eventName ? this.state.eventName : ''}
                        </h4>
                        <p className="m-0">
                          {this.state.tagline ? this.state.tagline : ''}
                        </p>
                      </div>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="event-info pl-4 pt-3 d-flex">
                            <i className="fas fa-map-marker-alt font-size-22 mt-1 mr-4"></i>
                            <div className="mb-4">
                              <h5 className="font-weight-bold text-capitalize">
                                Location
                              </h5>
                              {this.state.eventPlatform === 2 ? (
                                <p className="m-0"> Virtual </p>
                              ) : (
                                <p className="m-0">
                                  {this.state.loaction},{' '}
                                  {this.state.address2
                                    ? `${this.state.address2}, `
                                    : ''}
                                  {this.state.city}, {this.state.state},{' '}
                                  {this.state.zip}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="event-info pl-4 pt-3 d-flex">
                            <i className="far fa-calendar font-size-22 mt-2 mr-4"></i>
                            <div className="mb-3">
                              <h5 className="font-weight-bold text-capitalize">
                                date and time
                              </h5>
                              <p className="m-0">
                                {this.state.eventPlatform == 1 ? (
                                  <>
                                    {eventDays && eventDays.length !== 0
                                      ? eventDays[0]
                                      : days[
                                          new Date(
                                            moment(eventDate[0]).format(
                                              'DD MMM YYYY'
                                            )
                                          ).getDay()
                                        ]}{' '}
                                    -{' '}
                                    {moment(eventDate[0]).format(
                                      'MMM DD, yyyy'
                                    )}
                                    ,{' '}
                                    {startTime.split(' ').slice(0, 1).join('')}:
                                    {startTime.split(' ').slice(1, 2).join()}{' '}
                                    {startTime.split(' ').slice(2, 3).join('')}
                                  </>
                                ) : (
                                  <>
                                    {' '}
                                    {startTimeStamp || startTimeStamp !== '' ? (
                                      <>
                                        {
                                          days[
                                            new Date(
                                              moment(startTimeStamp).format(
                                                'DD MMM YYYY'
                                              )
                                            ).getDay()
                                          ]
                                        }{' '}
                                        -{' '}
                                        {moment(startTimeStamp).format(
                                          'MMM DD, yyyy'
                                        )}
                                        ,{' '}
                                        {new Date(
                                          startTimeStamp
                                        ).toLocaleTimeString('en-us', {
                                          hour: '2-digit',
                                          minute: '2-digit',
                                        })}
                                      </>
                                    ) : (
                                      ''
                                    )}
                                  </>
                                )}
                                {this.state.eventPlatform == 1 ? (
                                  <>
                                    - <br />
                                    {eventDays && eventDays.length !== 0
                                      ? eventDays[1]
                                      : days[
                                          new Date(
                                            moment(eventDate[1]).format(
                                              'DD MMM YYYY'
                                            )
                                          ).getDay()
                                        ]}{' '}
                                    -{' '}
                                    {moment(eventDate[1]).format(
                                      'MMM DD, yyyy'
                                    )}
                                    , {endTime.split(' ').slice(0, 1).join('')}:
                                    {endTime.split(' ').slice(1, 2).join()}{' '}
                                    {endTime.split(' ').slice(2, 3).join('')}
                                  </>
                                ) : (
                                  <>
                                    {' '}
                                    {endTimeStamp || endTimeStamp !== '' ? (
                                      <React.Fragment>
                                        - <br />
                                        {
                                          days[
                                            new Date(
                                              moment(endTimeStamp).format(
                                                'DD MMM YYYY'
                                              )
                                            ).getDay()
                                          ]
                                        }{' '}
                                        -{' '}
                                        {moment(endTimeStamp).format(
                                          'MMM DD, yyyy'
                                        )}
                                        ,{' '}
                                        {new Date(
                                          endTimeStamp
                                        ).toLocaleTimeString('en-us', {
                                          hour: '2-digit',
                                          minute: '2-digit',
                                        })}
                                      </React.Fragment>
                                    ) : (
                                      ''
                                    )}
                                  </>
                                )}{' '}
                                {this.state.eventPlatform == 2 ? (
                                  <>{timeZone ? <>({timeZone})</> : ''}</>
                                ) : (
                                  ''
                                )}
                                <br />
                                <div
                                  title="Add to Calendar"
                                  className="addeventatc add-Calendar"
                                >
                                  <span className="text-brand text-decoration-none">
                                    Add to Calendar
                                  </span>

                                  <span className="start">
                                    {this.state.eventPlatform == 1 ? (
                                      <>
                                        {moment(eventDate[0]).format(
                                          'MM/DD/yyyy'
                                        )}
                                        ,{' '}
                                        {startTime
                                          .split(' ')
                                          .slice(0, 1)
                                          .join('')}
                                        :
                                        {startTime
                                          .split(' ')
                                          .slice(1, 2)
                                          .join()}{' '}
                                        {startTime
                                          .split(' ')
                                          .slice(2, 3)
                                          .join('')}
                                      </>
                                    ) : (
                                      <>
                                        {' '}
                                        {moment(startTimeStamp).format(
                                          'MM/DD/yyyy'
                                        )}
                                        ,{' '}
                                        {new Date(
                                          startTimeStamp
                                        ).toLocaleTimeString('en-us', {
                                          hour: '2-digit',
                                          minute: '2-digit',
                                        })}
                                      </>
                                    )}

                                    <br />
                                  </span>
                                  <span className="end">
                                    {this.state.eventPlatform == 1 ? (
                                      <>
                                        {moment(eventDate[1]).format(
                                          'MM/DD/yyyy'
                                        )}
                                        ,{' '}
                                        {endTime
                                          .split(' ')
                                          .slice(0, 1)
                                          .join('')}
                                        :{endTime.split(' ').slice(1, 2).join()}{' '}
                                        {endTime
                                          .split(' ')
                                          .slice(2, 3)
                                          .join('')}
                                      </>
                                    ) : (
                                      <>
                                        {moment(endTimeStamp).format(
                                          'MM/DD/yyyy'
                                        )}
                                        ,{' '}
                                        {new Date(
                                          endTimeStamp
                                        ).toLocaleTimeString('en-us', {
                                          hour: '2-digit',
                                          minute: '2-digit',
                                        })}
                                      </>
                                    )}

                                    <br />
                                  </span>
                                 
                                  <span className="client">
                                    {process.env.REACT_APP_CLIENT_ID}
                                  </span>
                                  <span className="title">
                                    {this.state.eventName}
                                  </span>
                                  <span className="description">
                                    {this.state.aboutTheEvent}
                                  </span>
                                  <span className="location">
                                    {this.state.loaction},{' '}
                                    {this.state.address2
                                      ? `${this.state.address2}, `
                                      : ''}
                                    {this.state.city}, {this.state.state},{' '}
                                    {this.state.zip}
                                  </span>
                                </div>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="pt-4 d-flex pb-5 border-bottom m-4 m-xl-0 m-lg-0 m-md-0">
                <img
                  className="mr-4 promoter-img"
                  src={
                    this.state.promoterImage
                      ? this.state.promoterImage
                      : require('../../../assets/images/placeholder-1.jpg')
                  }
                  alt="Promoter"
                />
                <div className="d-flex flex-column overflow-hidden">
                  <h4 className="text-capitalize font-weight-bold">
                    About the promoter
                  </h4>
                  <p className="text-wrap text-break page-msg">
                    {this.state.aboutPromoter
                      ? parse(this.state.aboutPromoter)
                      : 'No data present'}
                  </p>
                  <div className="overflow-hidden mt-2 d-md-flex">
                    <React.Fragment>
                      {this.state.promoterFollowers ? (
                        <React.Fragment>
                          {this.state.promoterFollowers.includes(userId) ? (
                            <button
                              onClick={(promoterId) =>
                                this.handleUnFollowPromoter(
                                  this.state.createdBy
                                )
                              }
                              className="btn btn-outline-brand bg-transparent border-brand border-3 pl-4 pr-4"
                            >
                              <i className="fas fa-plus"></i>
                              Unfollow
                            </button>
                          ) : (
                            <button
                              onClick={(promoterId) =>
                                this.handleFollowPromoter(this.state.createdBy)
                              }
                              className="btn btn-outline-brand bg-transparent border-brand border-3 pl-4 pr-4"
                            >
                              <i className="fas fa-plus"></i>
                              Follow
                            </button>
                          )}
                        </React.Fragment>
                      ) : (
                        <button
                          onClick={(promoterId) =>
                            this.handleFollowPromoter(this.state.createdBy)
                          }
                          className="btn btn-outline-brand bg-transparent border-brand border-3 pl-4 pr-4"
                        >
                          <i className="fas fa-plus"></i>
                          Follow
                        </button>
                      )}
                    </React.Fragment>

                    <div className="d-flex social-anchor ml-md-2 mt-1 mt-md-0">
                      {promoterFacebookLink === '' ? (
                        ''
                      ) : (
                        <button
                          aria-label="facebook"
                          className="social-icon-btn react-share__ShareButton"
                        >
                          {' '}
                          <a href={promoterFacebookLink} target="_blank">
                            <span className="text-decoration-none text-brand  p-2">
                              <i className="fab fa-facebook-f font-size-18"></i>
                            </span>
                          </a>
                        </button>
                      )}
                      {promoterInstaUsername === '' ? (
                        ''
                      ) : (
                        <button
                          aria-label="facebook"
                          className="social-icon-btn react-share__ShareButton"
                        >
                          <a href={urlInstagram} target="_blank">
                            <span className="text-decoration-none text-brand  p-2">
                              <i className="fab fa-instagram font-size-18"></i>
                            </span>
                          </a>
                        </button>
                      )}

                      {promoterTwitterUsername === '' ? (
                        ''
                      ) : (
                        <button
                          aria-label="facebook"
                          className="social-icon-btn react-share__ShareButton"
                        >
                          <a href={urlTwitter} target="_blank">
                            <span className="text-decoration-none text-brand  p-2">
                              <i className="fab fa-twitter font-size-18"></i>
                            </span>
                          </a>
                        </button>
                      )}
                      {promoterLinkedInLink === '' ? (
                        ''
                      ) : (
                        <button
                          aria-label="facebook"
                          className="social-icon-btn react-share__ShareButton"
                        >
                          <a href={promoterLinkedInLink} target="_blank">
                            <span className="text-decoration-none text-brand  p-2">
                              <i class="fab fa-linkedin-in"></i>
                            </span>
                          </a>
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {!_.isEmpty(this.state.relatedEventList) ? (
                <React.Fragment>
                  <h3 className="text-uppercase font-weight-bold pt-4 pb-2 mb-3 text-center">
                    Related Events
                  </h3>
                  <div className="related-events">
                    <OwlCarousel
                      options={options}
                      className="owl-carousel owl-theme event-owl"
                    >
                      {this.state.relatedEventList.map((item) => (
                        <div className="event-card">
                          <div className="event-card-img">
                            <Link
                              onClick={() =>
                                this.redirectTo(item._id, item.eventName)
                              }
                              className="link-text-white"
                            >
                              <img
                                src={item.eventImage}
                                alt=""
                                className="mb-3"
                              />
                            </Link>
                          </div>

                          <div className="py-3 pl-4">
                            <div className="pr-4">
                              <Link
                                onClick={() =>
                                  this.redirectTo(item._id, item.eventName)
                                }
                                className="link-text-white"
                              >
                                <h5 className="text-white font-weight-bold mb-3 text-capitalize text-overflow">
                                  {item.eventName}
                                </h5>
                              </Link>

                              <div className=" event-card-content">
                                <p className="text-white mb-3 card-p">
                                  {parse(
                                    item.aboutTheEventText
                                      ? item.aboutTheEventText
                                          .charAt(0)
                                          .toUpperCase() +
                                          item.aboutTheEventText.slice(1)
                                      : ''
                                  )}
                                </p>
                              </div>
                            </div>
                            <div className="d-flex justify-content-between mt-05">
                              <Link
                                onClick={() =>
                                  this.redirectTo(item._id, item.eventName)
                                }
                                className="btn btn-brand btn-event text-white"
                              >
                                Tickets
                              </Link>
                              <Link
                                onClick={() =>
                                  this.redirectTo(item._id, item.eventName)
                                }
                                className="link-text-white"
                              >
                                <span className="btn btn-gray btn-event text-white">
                                  {item.eventPlatform == 1
                                    ? moment(item.eventDate[0]).format('MMM D')
                                    : moment(item.startTimeStamp).format(
                                        'MMM D'
                                      )}
                                  {/* {item.eventDate[item.eventDate.length - 1]
                                    ? moment(
                                        item.eventDate[
                                          item.eventDate.length - 1
                                        ]
                                      ).format('MMM D')
                                    : moment(
                                        item.eventDate[
                                          item.eventDate.length - 1
                                        ]
                                      ).format('MMM D')} */}
                                </span>
                              </Link>
                            </div>
                          </div>
                        </div>
                      ))}
                    </OwlCarousel>
                  </div>
                </React.Fragment>
              ) : (
                ''
              )}
            </div>
          </div>
          <EventFooter />
        </div>
        <ModalReact
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.openVideoPopup}
          onHide={this.closeModal}
          className="video-popup"
        >
          <ModalReact.Header closeButton></ModalReact.Header>
          <iframe width="100%" height="500" src={srcLink} sandbox></iframe>
        </ModalReact>
        <ModalReact
          size="md"
          show={openEventPasswordPopup}
          onHide={this.closeModal}
          aria-labelledby="example-modal-sizes-title-sm"
        >
          <div className="cm_modal">
            <div className="modal-body">
              <h4 className="mb-4 mt-3">
                <b>Enter event password</b>
              </h4>

              <div className="form-group">
                <label for="exampleInputUsername12">
                  Password<span className="text-danger">*</span>
                </label>

                <Input.Password
                  type="text"
                  maxLength="50"
                  size="large"
                  id="event-name"
                  placeholder="Event Password"
                  name="password"
                  autoComplete="off"
                  value={this.state.password}
                  onChange={this.handleChange}
                />
                {confirmPasswordErrorMsg ? (
                  <div className="error m-0">{confirmPasswordErrorMsg}</div>
                ) : null}
              </div>
              <br />

              <div className="bg-white pull-right mb-4">
                <button
                  type="submit"
                  className="btn btn-primary ml-2"
                  onClick={this.checkpasswordSubmit}
                >
                  Submit
                </button>
              </div>
              <br />
              <br />
            </div>
          </div>
        </ModalReact>
        <ModalReact
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.modal2Visible}
          onHide={this.closeModal}
          className="video-popup"
        >
          <div className="cm_modal">
            <div className="modal-body-popup">
              <ReactImageVideoLightbox
                data={images11}
                startIndex={this.state.indexNewfeed}
                showResourceCount={true}
                onCloseCallback={() => this.closeModal()}
                onNavigationCallback={(e) => this.setState({ indexNewfeed: e })}
              />
            </div>
            <br />
            <div className="text-right ml-4">
              <ImageSharePopup
                url={
                  images11.length > 0
                    ? images11[this.state.indexNewfeed].url
                    : 0
                }
              />
            </div>
          </div>
        </ModalReact>

        <ModalReact
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.imageModalVisible}
          onHide={this.closeModal}
          className="video-popup"
        >
          <div className="cm_modal">
            <div className="modal-body-popup">
              <ReactImageVideoLightbox
                data={images}
                class="checkk"
                startIndex={this.state.index}
                showResourceCount={true}
                onCloseCallback={() => this.closeModal()}
                onNavigationCallback={(e) => this.setState({ index: e })}
              />
            </div>
            <br />
            <div className="text-right ml-4">
              <ImageSharePopup
                url={images.length > 0 ? images[this.state.index].url : 0}
              />
            </div>
          </div>
        </ModalReact>
      </div>
    );
  }
}

export default EventAfter;
