/**@About
 * This file is manage user
 */
import React, { useReducer, useCallback, useState, useEffect } from "react"
//footer
import Footer from "../template/Footer"
//side navbar
import SideNav from "../template/SideNav"
//header
import TopBar from "../template/TopBar"
//connect
import { connect } from "react-redux"
//pagination
import Pagination from "react-js-pagination"
//antd
import { notification, Empty, Select, Button } from "antd"
//user action
// import { usersActions } from "../../_actions/UserManage";
import { usersService } from "../../_services/usersService"
//modal
import { Modal } from "react-bootstrap"
//moment
import moment from "moment"
//loader
// import { Loader, MySpinner } from "../../components/common/Loader";
//svg icon
import { Active, Edit, Draft, Completed } from "../../config/Svgicon"
//react-router-dom
import { Link } from "react-router-dom"

import { Spinner } from "react-bootstrap"
import ResponseFilter from "../../config/response-handler"
import { useDebounce } from "use-debounce"

//Whole page loader
const Loader = (props) => {
  return (
    <div className="loader_wrapper">
      <div className="lds-roller">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  )
}

// button loader
const MySpinner = (props) => <Spinner size="sm" animation="border" variant="warning" />

const { Option } = Select

//user list screen
function AdminUsers(props) {
  const [userData, setUserData] = useState({
    userDetails: [],
    totalCount: "",
    openPopup: false,
    userId: "",
    role: "",
  })

  const [filterOptions, setFilterOptions] = useState({
    page: 1,
    limit: 20,
    status: "",
    sortOrder: "",
    sortBy: "created",
  })
  const [search, setSearch] = useState("")
  const [debouncedSearch] = useDebounce(search, 400)

  const [loader, setLoader] = useState({ isLoader: false, loading: false })

  //pagination on Change
  const handlePageChange = (page) => {
    setFilterOptions({ ...filterOptions, page: page, loader: true }, () => this.getUserList())
  }

  //searching on change
  const handleOnSearch = (e) => {
    // let val = e.target.value;
    // const db = debounce(setFilterOptions({ ...filterOptions, search: val }),300)
    setSearch(e.target.value)
  }

  //event type filter
  const handleFilter = (e) => {
    setFilterOptions({ ...filterOptions, status: e })
  }

  const handlehasFilledOutW9 = (event, item) => {
    // let { _id, hasRevenueBeenPaidOut } = event;
    const accessToken = localStorage.getItem("accessToken")
    let id = event.target.value
    console.log(event.target.value)
    usersService.updatePromoterProfile({ user: id, hasFilledOutW9: !item.hasFilledOutW9 }, accessToken).then((res) => {
      ResponseFilter(res)
      if (res.data.statusCode === 1) {
        console.log("success")
      } else {
        console.log("failure")
      }
    })
    setUserData((prevState) => {
      return {
        ...prevState,
        userDetails: prevState.userDetails.map((user) => {
          return user._id === id ? { ...user, hasFilledOutW9: !user.hasFilledOutW9 } : user
        }),
      }
    })
  }

  //sort by name
  const handleSort = (e) => {
    setFilterOptions({
      ...filterOptions,
      isLoader: false,
      page: 1,
      sortBy: e,
      sortOrder: filterOptions.sortOrder === "asc" ? "desc" : "asc",
    })
  }
  //get user list
  const getUserList = useCallback(() => {
    let { limit, sortOrder, sortBy, page, status } = filterOptions
    const accessToken = localStorage.getItem("accessToken")

    try {
      let params = `role=${status}&limit=${limit}&sortOrder=${sortOrder}&sortBy=${sortBy}&page=${
        debouncedSearch === "" ? (page ? page - 1 : 0) : ""
      }&search=${debouncedSearch}`
      setLoader({ isLoader: true })
      usersService.getUsers(params, accessToken).then((res) => {
        let { status, resData } = ResponseFilter(res)
        setUserData((state) => ({ userDetails: resData.result, totalCount: resData.count }))
        setLoader((state) => ({ isLoader: false }))
      })
    } catch (e) {
      console.log(e)
    }
  }, [filterOptions, debouncedSearch])

  useEffect(() => {
    getUserList()
  }, [filterOptions, getUserList])

  // useEffect(() => {
  //   getUserList()

  // }, [])

  //open popup
  const handleOpenPopup = (id) =>
    setUserData((prevState) => {
      return { ...prevState, openPopup: !openPopup, userId: id }
    })

  //close delete modal
  const closeModal = () =>
    setUserData((prevState) => {
      return { ...prevState, openPopup: false, userId: "" }
    })

  // delete user
  const deleteuserList = () => {
    const accessToken = localStorage.getItem("accessToken")

    let { userId } = userData
    let obj = {
      userId: userId,
    }
    setLoader((prevState) => {
      return { ...prevState, loading: true }
    })
    usersService.deleteUser(obj, accessToken).then((res) => {
      console.log(res)
      if (res?.data?.statusCode === 1) {
        notification.success({
          message: "Success",
          description: res.data.responseData.message,
        })
        getUserList()
        setLoader((prevState) => {
          return { ...prevState, loading: false }
        })
        closeModal()
      } else {
        // setUserData({
        //   loading: false,
        //   errostatus: false,
        //   message: data?.error?.responseMessage,
        //   showMessage: true,
        // })
        setLoader((prevState) => {
          return { ...prevState, loading: false }
        })
        closeModal()
        notification.error({
          message: "Error",
          description: res?.data?.error?.responseMessage,
        })
        console.log(res?.error?.responseMessage)
      }
    })
  }
  // const reDirectTo = () => {
  //   localStorage.setItem("edit", "1");
  //   props.history.push("/add-user");
  // };

  let { userDetails, openPopup, totalCount } = userData
  let { page, limit } = filterOptions
  let { isLoader, loading } = loader
  return (
    <div className="dark-sidebar">
      <div className="main-container">
        <div className="container-fluid page-body-wrapper">
          {isLoader && <Loader />}
          <nav className="navbar fixed-top">
            <div className="navbar-menu-container d-flex align-items-center justify-content-center">
              <div className="sub-heading">
                <h4 className="page-title ">User Management</h4>
              </div>
              <div className="text-center navbar-brand-container align-items-center justify-content-center">
                <a className="brand-logo" href="/">
                  {" "}
                  <img src={require("../../assets/images/mobile-logo.png")} alt="Event 3" className="mb-3" />
                </a>
              </div>
              <TopBar />
            </div>
          </nav>
          <SideNav />
          <div className="main-panel">
            <div className="content-wrapper manage-event">
              <div className="row card-margin">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="row w-100 align-items-center d-flex">
                        <div className="col-md-7">
                          <h5 className="card-title">
                            Admin
                            <small className="card-title-secondary">Manage your user below</small>
                          </h5>
                        </div>
                        <div className="col-md-5 p-0">
                          <div className="row w-100 align-items-center d-flex m-0">
                            <div className=" col-lg-8 col-md-5 ml-0">
                              <div className="search ml-0">
                                <input
                                  type="text"
                                  class="form-control"
                                  id="event-name"
                                  placeholder="Search"
                                  name="search"
                                  value={search}
                                  maxLength={50}
                                  onChange={handleOnSearch}
                                />
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-7">
                              <div className="card-header justify-content-end border-bottom-0 p-0">
                                {/* <div className="btn btn-sm btn-danger mr-2" onClick={() => reDirectTo()} >Add User</div> */}

                                <div className="card-toolbar ml-0">
                                  <Select
                                    className="status_button"
                                    // placeholder="Filter By User Type"
                                    value={filterOptions.status ? filterOptions.status : "Filter By User Type"}
                                    style={{ minWidth: 140 }}
                                    onChange={(e) => handleFilter(e)}
                                  >
                                    <Option value="">All</Option>
                                    <Option value="2">
                                      <Active className="m-3" />
                                      Attendee
                                    </Option>
                                    <Option value="1">
                                      <Draft />
                                      Promoter
                                    </Option>
                                    <Option value="3">
                                      <Completed />
                                      Both
                                    </Option>
                                  </Select>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="table-responsive">
                        <table className="table widget-8">
                          <thead>
                            <tr>
                              {/* <th className="border-0">No.</th> */}
                              <th className="border-0 cursor-pointer" onClick={() => handleSort("nameLower")}>
                                <i className="fa fa-sort"></i> User Name
                              </th>
                              <th className="border-0">Email</th>
                              <th className="border-0">Phone Number</th>
                              <th className="border-0">Role</th>
                              <th className="border-0">Created</th>
                              <th className="border-0">Action</th>
                              <th className="border-0">W9 Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {userDetails?.map((item, index) => {
                              return (
                                <tr>
                                  {/* <td>{(page - 1) * 20 + index + 1}</td> */}
                                  <td>{item?.name}</td>
                                  <td>{item?.email}</td>
                                  <td>{item?.phone}</td>
                                  <td>
                                    {item?.role === 2
                                      ? "Attendee"
                                      : item?.role == 3
                                      ? "Both"
                                      : item?.role == 1
                                      ? "Promoter"
                                      : ""}
                                  </td>
                                  <td>{moment(item.created).format("MMM DD, yyyy")}</td>
                                  <td>
                                    {/* {
                                                                                item.role === 2 ?
                                                                                    <ul className="list-inline mb-0">
                                                                                        <li className="list-inline-item cursorptr pr-3">

                                                                                            <Link to={`/edit-user/${item._id}`} > <i className="fa fa-edit faclr"></i>
                                                                                            </Link>
                                                                                        </li>

                                                                                        <li className="list-inline-item cursorptr pr-3" onClick={() => handleOpenPopup(item._id)}>
                                                                                            <i className="fa fa-trash"></i>
                                                                                        </li>
                                                                                    </ul>
                                                                                    : 'NA'
                                                                            } */}
                                    <li
                                      className="list-inline-item cursorptr pr-3"
                                      // onClick={() => handleOpenPopup(item._id)}
                                    >
                                      {/* <i className="fa fa-trash"></i> */}
                                      <React.Fragment>
                                        <Link
                                          className="btn btn-sm btn-flash-primary"
                                          to={`account-settings/${item._id}`}
                                          type="button"
                                          title="Edit event"
                                        >
                                          <Edit />
                                        </Link>
                                      </React.Fragment>
                                    </li>
                                  </td>
                                  <td>
                                    {item.role !== 2 && (
                                      <input
                                        type="checkbox"
                                        checked={item?.hasFilledOutW9}
                                        value={item?._id}
                                        onChange={(e) => handlehasFilledOutW9(e, item)}
                                      />
                                    )}
                                  </td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </table>
                      </div>
                      {
                        <div>
                          {!isLoader && userDetails?.length === 0 && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                        </div>
                      }
                    </div>
                    <div className="card-footer bg-white">
                      <div className="d-flex align-items-center justify-content-between flex-column flex-sm-row mt-4">
                        {/* <p className="mb-3 mb-sm-0">Showing {page == 1 ? page : (page*limit - 20) + 1} to {(page*limit - 20 + 20)} of {totalCount} users</p> */}
                        <p className="mb-3 mb-sm-0">
                          Showing {page == 1 ? page : page * limit - 20 + 1} to{" "}
                          {userDetails
                            ? userDetails.length === 20
                              ? page * limit - 20 + 20
                              : page * limit - 20 + userDetails.length
                            : ""}{" "}
                          of {totalCount} users
                        </p>

                        <nav>
                          <ul>
                            <Pagination
                              activePage={page}
                              itemsCountPerPage={limit}
                              totalItemsCount={Number(totalCount)}
                              pageRangeDisplayed={5}
                              onChange={handlePageChange}
                            />
                          </ul>
                        </nav>
                      </div>
                    </div>
                  </div>
                  <Modal size="md" show={openPopup} onHide={closeModal} aria-labelledby="example-modal-sizes-title-sm">
                    <Modal.Header closeButton></Modal.Header>
                    <div className="cm_modal">
                      <div className="modal-body">
                        <h4 className="mb-4">
                          <b>Are you sure, you want to delete this?</b>
                        </h4>
                        <br />
                        <div className="bg-white pull-right mb-4">
                          <button className="btn btn-light" onClick={closeModal}>
                            No
                          </button>
                          <button
                            type="submit"
                            className="btn btn-primary ml-2"
                            disabled={loading}
                            onClick={() => deleteuserList()}
                          >
                            Yes &nbsp;
                            {loading && <MySpinner />}
                          </button>
                        </div>
                        <br />
                        <br />
                      </div>
                    </div>
                  </Modal>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  )
}
function mapStateToProps(state) {
  const { props, getUser, user } = state
  return {
    props,
    getUser,
    user,
  }
}
export default connect(mapStateToProps)(AdminUsers)
