/* eslint-disable no-undef */
import React from 'react';
import { Loader } from '../../../config/Svgicon';
import { usersService } from '../../../_services/usersService';
import ResponseFilter from '../../../config/response-handler';
import EventAfter from '../event-pages/EventAfter';
import EventDetail from '../event-pages/EventDetail';

class EventViewPage extends React.Component {
  constructor() {
    super();
    this.state = {
      eventStatus: '',
    };
  }

  componentDidMount() {
    //  ReactGA.initialize('UA-173130906-1');  // Initialize google analytics page view tracking
    // ReactGA.set({ page: this.props.location.pathname }); // Update the user's current page
    // ReactGA.pageview(this.props.location.pathname + this.props.location.search); // Record a pageview for the given page
    document.title = 'My Events | Farechild';
    this.getEventDetails();
    this.handleEventVisitor();
  }

  handleEventVisitor = () => {
    let obj = {
      eventId: this.props.match.params.id,
     
    };
    usersService.eventVisitor(obj).then((data) => {
      
    });
  };

  getEventDetails = () => {
    this.setState({ loader: true });
    let obj = {
      eventId: this.props.match.params.id,
    };
    window.scrollTo(0, 0);
    usersService.eventDetails(obj).then((res) => {
      let { status, resData } = ResponseFilter(res);

      if (status) {
        this.setState({
          eventStatus: resData.result.status,
        });
      }
    });
  };
  stopLoader = () => this.setState({ loader: false });

  render() {
    let { loader } = this.state;

    return (
      <React.Fragment>
        {loader ? <Loader /> : null}
        {this.state.eventStatus === 2 ? (
          <EventDetail
            {...this.props}
            eventId={this.props.match.params.id}
            stopLoader={this.stopLoader}
          />
        ) : (
          ''
        )}
        {this.state.eventStatus === 3 ? (
          <EventAfter
            {...this.props}
            eventId={this.props.match.params.id}
            stopLoader={this.stopLoader}
          />
        ) : (
          ''
        )}
      </React.Fragment>
    );
  }
}

export default EventViewPage;
