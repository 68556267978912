/* eslint-disable no-undef */
import React from 'react';
import TopBar from '../template/TopBar';
import Footer from '../template/Footer';
import SideNav from '../template/SideNav';
import { usersService } from '../../_services/usersService';
import _ from 'lodash';
import { isValidEmail } from '../../utils';
import Pagination from 'react-js-pagination';
import { message, Empty } from 'antd';
import Modal from 'react-bootstrap/Modal';
import {
  Delete,
  Star,
  University2,
  Paypal,
  University,
  Loader,
} from '../../config/Svgicon';
import DropdownButton from 'react-bootstrap/DropdownButton';
import ResponseFilter from '../../config/response-handler';
import { Menu, Dropdown } from 'antd';

class PayoutSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openAddPaypalPopup: false,
      openAddVenmoPopup: false,
      openAddBankAccountPopup: false,
      openDeletePopup: false,
      openSetPrimaryPopup: false,
      disabledBtn: false,
      loader: false,
      payoutList: [],
      limit: 5,
      page: 1,
      nickname: '',
      username: '',
      email: '',
      bankname: '',
      routingNo: '',
      accountNo: '',
      confirmAccountNo: '',
      accountType: '',
      payoutId: '',
      priorityStatus: '',
      nicknameErrorMsg: '',
      usernameErrorMsg: '',
      emailErrorMsg: '',
      banknameErrorMsg: '',
      routingNoErrorMsg: '',
      accountNoErrorMsg: '',
      confirmAccountNoErrorMsg: '',
    };
  }

  componentDidMount() {
    if (
      localStorage.getItem('isAccountDetailsExists') == 'false' &&
      localStorage.getItem('role') != 2
    ) {
      this.props.history.push('/account-settings');
    }
    if (localStorage.getItem('role') == 2) {
      this.props.history.push('/events-feed');
    }
    if (localStorage.getItem('role') == 4) {
      this.props.history.push('/admin');
    }
    document.title = 'Payout Methods | Farechild';
    this.getPayoutDetails();
    this.setState({ loader: true });
  }
  closeModal = () => {
    this.setState({
      openAddPaypalPopup: false,
      openAddVenmoPopup: false,
      openAddBankAccountPopup: false,
      openDeletePopup: false,
      openSetPrimaryPopup: false,
      disabledBtn: false,
      loader: false,
      limit: 5,
      page: 1,
      nickname: '',
      username: '',
      email: '',
      bankname: '',
      routingNo: '',
      accountNo: '',
      confirmAccountNo: '',
      accountType: '',
      payoutId: '',
      priorityStatus: '',
      nicknameErrorMsg: '',
      usernameErrorMsg: '',
      emailErrorMsg: '',
      banknameErrorMsg: '',
      routingNoErrorMsg: '',
      accountNoErrorMsg: '',
      confirmAccountNoErrorMsg: '',
    });
  };

  handleOpenDeletePopup = (payoutId) =>
    this.setState({ openDeletePopup: !this.state.openDeletePopup, payoutId });
  handleOpenSetPrimaryPopup = (payoutId, priorityStatus) =>
    this.setState({
      openSetPrimaryPopup: !this.state.openSetPrimaryPopup,
      payoutId,
      priorityStatus,
    });

  handleOpenAddVenmoPopup = (accountType) =>
    this.setState({
      openAddVenmoPopup: !this.state.openAddVenmoPopup,
      accountType,
    });

  handleOpenAddPaypalPopup = (accountType) =>
    this.setState({
      openAddPaypalPopup: !this.state.openAddPaypalPopup,
      accountType,
    });
  handleOpenAddBankAccountPopup = (accountType) => {
    this.setState({
      accountType: accountType,
      openAddBankAccountPopup: !this.state.openAddBankAccountPopup,
    });
  };

  handleChange = (e) => {
    const { name } = e.target;
    var checkNumber = /^\d*\.?\d*$/i.test(e.target.value);
    if (e.target.type === 'number') {
      if (checkNumber === false || checkNumber === 'false') {
        e.preventDefault();
      }
      var value = e.target.value.replace(/\+|-/gi, '');
    } else {
      var { value } = e.target;
    }
    this.setState({
      [name]: value,
      [e.target.name + 'ErrorMsg']: '',
    });
  };

  handlePageChange = (page) => {
    this.setState({ page: page, loader: true }, () => this.getPayoutDetails());
  };

  numberInputValidation = (event) => {
    const regex = new RegExp('^[a-zA-Z -]*$');
    const key = String.fromCharCode(
      !event.charCode ? event.which : event.charCode
    );
    if (!regex.test(key)) {
      event.preventDefault();
      return false;
    }
  };

  handleValidation = () => {
    let validate = true;
    let {
      bankname,
      email,
      nickname,
      routingNo,
      accountNo,
      confirmAccountNo,
      username,
    } = this.state;

    if (this.state.accountType === 1) {
      if (bankname === '' || bankname === undefined) {
        validate = false;
        this.setState({
          banknameErrorMsg: 'Please enter bank name.',
        });
      } else {
        this.setState({
          banknameErrorMsg: '',
        });
      }
      if (nickname === '' || nickname === undefined) {
        validate = false;
        this.setState({
          nicknameErrorMsg: 'Please enter nick name.',
        });
      }
      if (routingNo === '' || routingNo === undefined) {
        validate = false;
        this.setState({
          routingNoErrorMsg: 'Please enter routing number.',
        });
      } else {
        this.setState({
          routingNoErrorMsg: '',
        });
      }
      if (accountNo === '' || accountNo === undefined) {
        validate = false;
        this.setState({
          accountNoErrorMsg: 'Please enter account number.',
        });
      } else {
        this.setState({
          accountNoErrorMsg: '',
        });
      }
      if (confirmAccountNo === '' || confirmAccountNo === undefined) {
        validate = false;
        this.setState({
          confirmAccountNoErrorMsg: 'Please enter confirm account number.',
        });
      } else if (confirmAccountNo !== accountNo) {
        validate = false;
        this.setState({
          confirmAccountNoErrorMsg:
            'Account number and confirm account number does not match.',
        });
      } else {
        this.setState({
          confirmAccountNoErrorMsg: '',
        });
      }
    } else if (this.state.accountType === 2) {
      if (email === '' || email === undefined) {
        validate = false;
        this.setState({
          emailErrorMsg: 'Please enter your email address.',
        });
      } else if (!isValidEmail(String(email.toLowerCase()))) {
        validate = false;
        this.setState({ emailErrorMsg: 'Please enter a valid email address.' });
      } else {
        this.setState({
          emailErrorMsg: '',
        });
      }
      if (nickname === '' || nickname === undefined) {
        validate = false;
        this.setState({
          nicknameErrorMsg: 'Please enter nick name.',
        });
      }
    } else {
      if (username === '' || username === undefined) {
        validate = false;
        this.setState({
          usernameErrorMsg: 'Please enter your user name.',
        });
      } else {
        this.setState({
          usernameErrorMsg: '',
        });
      }
      if (nickname === '' || nickname === undefined) {
        validate = false;
        this.setState({
          nicknameErrorMsg: 'Please enter nick name.',
        });
      }
    }

    return validate;
  };
  handleAddSubmit = (event) => {
    event.preventDefault();
    let self = this;
    if (this.handleValidation()) {
      if (this.state.accountType === 1) {
        var obj = {
          accountType: JSON.stringify(this.state.accountType),
          bankName: this.state.bankname,
          nickName: this.state.nickname,
          routingNumber: this.state.routingNo,
          accountNumber: this.state.accountNo,
        };
      } else if (this.state.accountType === 2) {
        var obj = {
          accountType: JSON.stringify(this.state.accountType),
          paypalEmail: this.state.email.toLowerCase(),
          paypalNickName: this.state.nickname,
        };
      } else {
        var obj = {
          accountType: JSON.stringify(this.state.accountType),
          venmoUsername: this.state.username,
          venmoNickname: this.state.nickname,
        };
      }

      const accessToken = localStorage.getItem('accessToken');
      this.setState({ disabledBtn: true });
      usersService.createPayout(obj, accessToken).then((res) => {
        ResponseFilter(res);
        if (res.data.statusCode === 1) {
          if (localStorage.getItem('payoutRedirect') === 'true') {
            this.getPayoutDetails();
            this.props.history.push(
              `/create-event/${localStorage.getItem('draftEventId')}`
            );
          } else if (localStorage.getItem('ticketTabRedirect') === 'true') {
            this.getPayoutDetails();
            localStorage.setItem('activeTab', 2);
            this.props.history.push(
              `/manage-event/edit-event/${localStorage.getItem(
                'ticketEventId'
              )}`
            );
          } else {
            message.success('Payout has been created successfully!');

            self.closeModal();
            self.setState(
              {
                disabledBtn: false,
              },
              () => this.getPayoutDetails()
            );
          }
        } else {
          self.closeModal();
          self.setState(
            {
              disabledBtn: false,
            },
            () => this.getPayoutDetails()
          );
        }
      });
    }
  };
  handlePayoutSetAsPrimary = () => {
    let self = this;
    if (this.state.priorityStatus === 0) {
      var obj = {
        payoutId: this.state.payoutId,
        priorityStatus: '1',
      };
    } else {
      var obj = {
        payoutId: this.state.payoutId,
        priorityStatus: '0',
      };
    }

    const accessToken = localStorage.getItem('accessToken');
    this.setState({ disabledBtn: true });
    usersService.payoutSetAsPrimary(obj, accessToken).then((res) => {
      ResponseFilter(res);
      if (res.data.statusCode === 1) {
        message.success('This payout method successfully set as primary!');
        self.closeModal();
        self.setState(
          {
            disabledBtn: false,
          },
          () => this.getPayoutDetails()
        );
      } else {
        self.closeModal();
        self.setState(
          {
            disabledBtn: false,
          },
          () => this.getPayoutDetails()
        );
      }
    });
  };
  handleDelete = () => {
    let obj = {
      payoutId: this.state.payoutId,
    };
    const accessToken = localStorage.getItem('accessToken');
    this.setState({ disabledBtn: true });
    usersService.deletePayout(obj, accessToken).then((res) => {
      ResponseFilter(res);
      if (res.data.statusCode === 1) {
        message.success('Payout has been deleted successfully!');
        this.setState(
          {
            disabledBtn: false,
            openDeletePopup: false,
          },
          () => this.getPayoutDetails()
        );
      } else {
        this.setState({
          disabledBtn: false,
          getPayoutDetails: false,
        });
      }
    });
  };
  getPayoutDetails = () => {
    let { limit, page } = this.state;

    let params = `createdBy=${localStorage.getItem(
      'userId'
    )}&limit=${limit}&page=${page - 1}`;
    usersService.getPayout(params).then((res) => {
      let { status, resData } = ResponseFilter(res);
      this.setState({ loader: false });
      if (status) {
        this.setState({
          payoutList: resData.result,
          totalCount: resData.count,
        });

        if (resData.result.length == 0) {
          localStorage.setItem('isPayoutExists', false);
        } else {
          localStorage.setItem('isPayoutExists', true);
        }
      }
    });
  };
  render() {
    let {
      payoutList,
      loader,
      nicknameErrorMsg,
      usernameErrorMsg,
      emailErrorMsg,
      banknameErrorMsg,
      routingNoErrorMsg,
      accountNoErrorMsg,
      confirmAccountNoErrorMsg,
      disabledBtn,
    } = this.state;
    return (
      <div className="dark-sidebar">
        {loader ? <Loader /> : null}
        <div className="main-container">
          <div className="container-fluid page-body-wrapper">
            <nav className="navbar fixed-top">
              <div className="navbar-menu-container d-flex align-items-center justify-content-center">
                <div className="sub-heading">
                  <h4 className="page-title ">PAYOUT METHODS</h4>
                </div>
                <div className="text-center navbar-brand-container align-items-center justify-content-center">
                  <a className="brand-logo" href="/">
                    <img
                      src={require('../../assets/images/mobile-logo.png')}
                      alt=""
                      className="mb-3"
                    />
                  </a>
                </div>

                <TopBar {...this.props} />
              </div>
            </nav>

            <SideNav />
            <div className="main-panel">
              <div className="content-wrapper manage-event">
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-header card-new-header">
                        <div className="row d-flex">
                          <div className="col-sm-6">
                            <h5 className="card-title">
                              Payout Methods
                              <small className="card-title-secondary">
                                Manage your payout accounts below
                              </small>
                            </h5>
                          </div>
                          <div className="col-sm-6 text-sm-right mt-3 mt-sm-0">
                            <div className="dropdown widget-15-post-action card-pos">
                              <DropdownButton
                                className="dropdown-add btn-sm"
                                title=" Add a New Payout Account"
                              >
                                <li className="dropdown-item">
                                  <a
                                    className="dropdown-link"
                                    onClick={() =>
                                      this.handleOpenAddBankAccountPopup(1)
                                    }
                                  >
                                    <University2 />
                                    Bank Account
                                  </a>
                                </li>
                                <li className="dropdown-item">
                                  <a
                                    className="dropdown-link"
                                    onClick={() =>
                                      this.handleOpenAddPaypalPopup(2)
                                    }
                                  >
                                    <Paypal />
                                    PayPal
                                  </a>
                                </li>
                                <li className="dropdown-item">
                                  <a
                                    className="dropdown-link"
                                    onClick={() =>
                                      this.handleOpenAddVenmoPopup(3)
                                    }
                                  >
                                    <img
                                      src={require('../../assets/images/venmo-icon-gray.png')}
                                      style={{ width: 13, marginRight: 10 }}
                                      alt=""
                                    />
                                    Venmo
                                  </a>
                                </li>
                              </DropdownButton>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="card-body">
                        <div className="table-responsive">
                          <table className="table widget-8">
                            <thead>
                              <tr>
                                <th className="border-0">Account Type</th>
                                <th className="border-0">Status</th>
                                <th className="border-0">Events</th>
                                <th className="border-0">Edit</th>
                              </tr>
                            </thead>
                            <tbody>
                              {!_.isEmpty(payoutList) ? (
                                <React.Fragment>
                                  {payoutList
                                    .sort((a, b) =>
                                      a.priorityStatus > b.priorityStatus
                                        ? -1
                                        : 1
                                    )
                                    .map((item, i) => {
                                      return (
                                        <tr>
                                          <td>
                                            <div className="widget-8-title-wrapper">
                                              {item.accountType === 1 ? (
                                                <University />
                                              ) : item.accountType === 2 ? (
                                                <i className="fab fa-paypal fa-lg"></i>
                                              ) : (
                                                <img
                                                  src={require('../../assets/images/venmo-icon.png')}
                                                  style={{ width: 17 }}
                                                  alt=""
                                                />
                                              )}

                                              <div className="widget-8-product-info">
                                                {item.accountType === 1 ? (
                                                  <div className="attribute">
                                                    {item.bankName}
                                                  </div>
                                                ) : item.accountType === 2 ? (
                                                  <div className="attribute">
                                                    PayPal
                                                  </div>
                                                ) : (
                                                  <div className="attribute">
                                                    Venmo
                                                  </div>
                                                )}

                                                {item.accountType === 1 ? (
                                                  <div className="title">
                                                    {item.accountNumber}
                                                  </div>
                                                ) : item.accountType === 2 ? (
                                                  <div className="title">
                                                    {item.paypalEmail}
                                                  </div>
                                                ) : (
                                                  <div className="title">
                                                    {item.venmoUsername}
                                                  </div>
                                                )}
                                                <div className="attribute">
                                                  U.S. Dollars $, United States
                                                </div>
                                              </div>
                                            </div>
                                          </td>
                                          <td>
                                            {item.priorityStatus === 1 ? (
                                              <div className="widget-8-status-wrapper">
                                                <div className="badge badge-pill badge-outline-success">
                                                  Primary
                                                </div>
                                              </div>
                                            ) : (
                                              <p className="ml-4">_</p>
                                            )}
                                          </td>
                                          <td>
                                            <div className="widget-8-desc">
                                              <div className="widget-8-order-wrapper">
                                                <div className="figure">
                                                  {item.eventPayout}
                                                </div>
                                                <div className="desc">
                                                  Event Payouts
                                                </div>
                                              </div>
                                            </div>
                                          </td>
                                          <td>
                                            <Dropdown
                                              overlay={
                                                <Menu>
                                                  <Menu.Item
                                                    key="0"
                                                    onClick={() =>
                                                      this.handleOpenDeletePopup(
                                                        item._id
                                                      )
                                                    }
                                                  >
                                                    <span className="dropdown-link">
                                                      <Delete />
                                                      <span className="ml-2">
                                                        {} Delete
                                                      </span>
                                                    </span>
                                                  </Menu.Item>
                                                  <Menu.Item
                                                    key="1"
                                                    onClick={() =>
                                                      this.handleOpenSetPrimaryPopup(
                                                        item._id,
                                                        item.priorityStatus
                                                      )
                                                    }
                                                  >
                                                    <span className="dropdown-link">
                                                      {item.priorityStatus ===
                                                      1 ? (
                                                        <span>
                                                          <Star />
                                                          <span className="ml-2">
                                                            Remove
                                                          </span>
                                                        </span>
                                                      ) : (
                                                        <span>
                                                          <Star />
                                                          <span className="ml-2">
                                                            Set as Primary
                                                          </span>
                                                        </span>
                                                      )}
                                                    </span>
                                                  </Menu.Item>
                                                </Menu>
                                              }
                                              trigger={['click']}
                                            >
                                              <a
                                                className="ant-dropdown-link"
                                                onClick={(e) =>
                                                  e.preventDefault()
                                                }
                                              >
                                                <button
                                                  className="btn btn-sm btn-shadow-primary"
                                                  type="button"
                                                  id="product-action-pane2"
                                                  data-toggle="dropdown"
                                                  aria-haspopup="true"
                                                  aria-expanded="false"
                                                >
                                                  Options
                                                  <i
                                                    className="fa fa-angle-double-down"
                                                    aria-hidden="true"
                                                  ></i>
                                                </button>
                                              </a>
                                            </Dropdown>
                                          </td>
                                        </tr>
                                      );
                                    })}
                                </React.Fragment>
                              ) : (
                                ''
                              )}
                            </tbody>
                          </table>
                        </div>

                        {payoutList.length > 0 ? (
                          ''
                        ) : (
                          <div className="text-center">
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                          </div>
                        )}
                      </div>

                      <div className="card-footer bg-white">
                        <div className="d-flex align-items-center justify-content-between flex-column flex-sm-row mt-4">
                          {payoutList.length > 0 ? (
                            <p className="mb-3 mb-sm-0">
                              Showing{' '}
                              {this.state.page == 1 ? (
                                <React.Fragment>1</React.Fragment>
                              ) : (
                                this.state.page * this.state.limit -
                                this.state.limit +
                                1
                              )}{' '}
                              to{' '}
                              {this.state.payoutList
                                ? this.state.payoutList.length ===
                                  this.state.limit
                                  ? this.state.page * this.state.limit -
                                    this.state.limit +
                                    this.state.limit
                                  : this.state.page * this.state.limit -
                                    this.state.limit +
                                    this.state.payoutList.length
                                : ''}{' '}
                              of {this.state.totalCount} entries
                            </p>
                          ) : (
                            <p className="mb-3 mb-sm-0">
                              Showing 0 to 0 of 0 entries
                            </p>
                          )}
                          <nav>
                            <ul>
                              <Pagination
                                activePage={this.state.page}
                                itemsCountPerPage={this.state.limit}
                                totalItemsCount={this.state.totalCount}
                                pageRangeDisplayed={3}
                                onChange={this.handlePageChange}
                              />
                            </ul>
                          </nav>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>

        <Modal
          size="lg"
          show={this.state.openAddBankAccountPopup}
          onHide={this.closeModal}
          aria-labelledby="example-modal-sizes-title-sm"
        >
          <Modal.Header closeButton>
            <h5 className="card-title mt-4">ADD NEW BANK ACCOUNT</h5>
          </Modal.Header>

          <div id="custom-content" className="white-popup-block">
            <div className="card card-margin">
              <div className="card-body">
                <form className="forms-sample">
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label for="exampleInputUsername12">
                        Bank Name<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        onKeyPress={(e) => this.numberInputValidation(e)}
                        className="form-control"
                        id="event-name"
                        placeholder="Wells Fargo"
                        name="bankname"
                        maxLength="100"
                        value={this.state.bankname}
                        onChange={this.handleChange}
                      />
                      {banknameErrorMsg ? (
                        <div className="error">{banknameErrorMsg}</div>
                      ) : null}
                    </div>
                    <div className="form-group  col-md-6">
                      <label for="exampleInputUsername12">
                        Nick Name<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        onKeyPress={(e) => this.numberInputValidation(e)}
                        className="form-control"
                        id="event-sub"
                        placeholder="TMG Checking"
                        name="nickname"
                        maxLength="50"
                        value={this.state.nickname}
                        onChange={this.handleChange}
                      />
                      {nicknameErrorMsg ? (
                        <div className="error">{nicknameErrorMsg}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-12">
                      <label for="exampleInputUsername12">
                        Routing Number<span className="text-danger">*</span>
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="event-name"
                        placeholder="Routing #"
                        name="routingNo"
                        onKeyPress={(e) => {
                          if (
                            this.state.routingNo.length > 9 &&
                            e.key !== 'Backspace'
                          )
                            e.preventDefault();
                        }}
                        value={this.state.routingNo}
                        onChange={this.handleChange}
                      />
                      {routingNoErrorMsg ? (
                        <div className="error">{routingNoErrorMsg}</div>
                      ) : null}
                    </div>
                    <div className="form-group  col-md-12">
                      <label for="exampleInputUsername12">
                        Account Number<span className="text-danger">*</span>
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="event-sub"
                        placeholder="Account #"
                        name="accountNo"
                        onKeyPress={(e) => {
                          if (
                            this.state.accountNo.length > 16 &&
                            e.key !== 'Backspace'
                          )
                            e.preventDefault();
                        }}
                        value={this.state.accountNo}
                        onChange={this.handleChange}
                      />
                      {accountNoErrorMsg ? (
                        <div className="error">{accountNoErrorMsg}</div>
                      ) : null}
                    </div>
                    <div className="form-group  col-md-12">
                      <label for="exampleInputUsername12">
                        Confirm Account Number
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="event-sub"
                        placeholder="Confirm Account #"
                        name="confirmAccountNo"
                        value={this.state.confirmAccountNo}
                        onChange={this.handleChange}
                        onKeyPress={(e) => {
                          if (
                            this.state.confirmAccountNo.length > 16 &&
                            e.key !== 'Backspace'
                          )
                            e.preventDefault();
                        }}
                      />
                      {confirmAccountNoErrorMsg ? (
                        <div className="error">{confirmAccountNoErrorMsg}</div>
                      ) : null}
                    </div>
                  </div>
                </form>
              </div>
              <div className="card-footer bg-white pull-right">
                <button className="btn btn-light" onClick={this.closeModal}>
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-primary ml-2"
                  disabled={disabledBtn}
                  onClick={this.handleAddSubmit}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </Modal>

        <Modal
          size="lg"
          show={this.state.openAddPaypalPopup}
          onHide={this.closeModal}
          aria-labelledby="example-modal-sizes-title-sm"
        >
          <Modal.Header closeButton>
            <h5 className="card-title mt-4">ADD NEW PAYPAL ACCOUNT</h5>
          </Modal.Header>

          <div id="custom-content" className="white-popup-block">
            <div className="card card-margin">
              <div className="card-body">
                <form className="forms-sample">
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label for="exampleInputUsername12">
                        PayPal Account Email
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="event-name"
                        placeholder="PayPal Email"
                        name="email"
                        maxLength="100"
                        value={this.state.email}
                        onChange={this.handleChange}
                      />
                      {emailErrorMsg ? (
                        <div className="error">{emailErrorMsg}</div>
                      ) : null}
                    </div>
                    <div className="form-group  col-md-6">
                      <label for="exampleInputUsername12">
                        Nick Name<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        onKeyPress={(e) => this.numberInputValidation(e)}
                        className="form-control"
                        id="event-sub"
                        placeholder="Nickname"
                        name="nickname"
                        maxLength="50"
                        value={this.state.nickname}
                        onChange={this.handleChange}
                      />
                      {nicknameErrorMsg ? (
                        <div className="error">{nicknameErrorMsg}</div>
                      ) : null}
                    </div>
                  </div>
                </form>
              </div>
              <div className="card-footer bg-white pull-right">
                <button className="btn btn-light" onClick={this.closeModal}>
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-primary ml-2"
                  disabled={disabledBtn}
                  onClick={this.handleAddSubmit}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          size="lg"
          show={this.state.openAddVenmoPopup}
          onHide={this.closeModal}
          aria-labelledby="example-modal-sizes-title-sm"
        >
          <Modal.Header closeButton>
            <h5 className="card-title mt-4">Add New Venmo Account</h5>
          </Modal.Header>

          <div id="custom-content" className="white-popup-block">
            <div className="card card-margin">
              <div className="card-body">
                <form className="forms-sample">
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label for="exampleInputUsername12">
                        Venmo Username<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="event-name"
                        placeholder="Venmo Username"
                        name="username"
                        maxLength="50"
                        value={this.state.username}
                        onChange={this.handleChange}
                      />
                      {usernameErrorMsg ? (
                        <div className="error">{usernameErrorMsg}</div>
                      ) : null}
                    </div>
                    <div className="form-group  col-md-6">
                      <label for="exampleInputUsername12">
                        Nick Name<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        onKeyPress={(e) => this.numberInputValidation(e)}
                        className="form-control"
                        id="event-sub"
                        placeholder="Nickname"
                        name="nickname"
                        maxLength="50"
                        value={this.state.nickname}
                        onChange={this.handleChange}
                      />
                      {nicknameErrorMsg ? (
                        <div className="error">{nicknameErrorMsg}</div>
                      ) : null}
                    </div>
                  </div>
                </form>
              </div>
              <div className="card-footer bg-white pull-right">
                <button className="btn btn-light" onClick={this.closeModal}>
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-primary ml-2"
                  disabled={disabledBtn}
                  onClick={this.handleAddSubmit}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          size="md"
          show={this.state.openDeletePopup}
          onHide={this.closeModal}
          aria-labelledby="example-modal-sizes-title-sm"
        >
          <Modal.Header closeButton></Modal.Header>
          <div className="cm_modal">
            <div className="modal-body">
              <h4 className="mb-4">
                Are you sure you want to delete this payout?
              </h4>
              <br />

              <div className="bg-white pull-right mb-4">
                <button
                  className="btn btn-light"
                  type="button"
                  onClick={this.closeModal}
                >
                  No
                </button>
                <button
                  type="submit"
                  className="btn btn-primary ml-2"
                  disabled={disabledBtn}
                  onClick={() => this.handleDelete(this.state.payoutId)}
                >
                  Yes
                </button>
              </div>
              <br />
              <br />
            </div>
          </div>
        </Modal>
        <Modal
          size="md"
          show={this.state.openSetPrimaryPopup}
          onHide={this.closeModal}
          aria-labelledby="example-modal-sizes-title-sm"
        >
          <Modal.Header closeButton></Modal.Header>
          <div className="cm_modal">
            <div className="modal-body">
              {this.state.priorityStatus === 0 ? (
                <h4 className="mb-4">
                  Are you sure you want to set as primary this payout?
                </h4>
              ) : (
                <h4 className="mb-4">
                  Are you sure you want to remove from primary?
                </h4>
              )}

              <br />

              <div className="bg-white pull-right mb-4">
                <button
                  className="btn btn-light"
                  type="button"
                  onClick={this.closeModal}
                >
                  No
                </button>
                <button
                  type="submit"
                  className="btn btn-primary ml-2"
                  disabled={disabledBtn}
                  onClick={() =>
                    this.handlePayoutSetAsPrimary(
                      this.state.payoutId,
                      this.state.priorityStatus
                    )
                  }
                >
                  Yes
                </button>
              </div>
              <br />
              <br />
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default PayoutSettings;
