/* eslint-disable no-undef */
import React from 'react';
import { Link } from 'react-router-dom';
import EventTopBar from '../event/event-template/EventTopBar';
import EventFooter from '../event/event-template/EventFooter';
import { usersService } from '../../_services/usersService';
import ResponseFilter from '../../config/response-handler';

class ViewSuccessPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      eventImage: '',
    };
  }
  componentDidMount() {
    this.getEventDetails();
    if (
      localStorage.getItem('isAccountDetailsExists') == 'false' &&
      localStorage.getItem('role') != 2
    ) {
      this.props.history.push('/account-settings');
    }
    if (localStorage.getItem('role') == 2) {
      this.props.history.push('/events-feed');
    }
    if (localStorage.getItem('role') == 4) {
      this.props.history.push('/admin');
    }
    document.title = 'View Success Message | Farechild';
  }

  getEventDetails = () => {
    let obj = {
      eventId: this.props.match.params.id,
    };

    usersService.eventDetails(obj).then((res) => {
      let { status, resData } = ResponseFilter(res);
      if (status) {
        let { eventImage } = resData.result;

        this.setState({
          eventImage,
        });
      }
    });
  };

  render() {
    var parse = require('html-react-parser');

    return (
      <div className="base-wrapper event-detail">
        <EventTopBar {...this.props} />
        <div>
          <div className="sidebar-overlay"></div>
          <div className="success-content">
            <h3 className="content-title pb-2">Thank you for your order!</h3>
            <p className="text-center mb-5">
              {parse(localStorage.getItem('successPageMessage'))}
            </p>
            <div className="card mb-5">
              <div className="card-body p-0">
                <img
                  src={
                    this.state.eventImage
                      ? this.state.eventImage
                      : ""
                  }
                  
                  className="w-100"
                />

                <div className="p-4 border-bottom">
                  <h5 className="font-weight-bold pb-3">Order Summary</h5>

                  <div className="row no-gutters border-bottom">
                    <div className="col-lg-8">
                      <p>1 x General Admission</p>
                    </div>
                    <div className="col-lg-4">
                      <p className="float-right">$40.00</p>
                    </div>
                  </div>
                  <div className="row no-gutters border-bottom pt-4">
                    <div className="col-lg-8">
                      <p>Subtotal</p>
                    </div>
                    <div className="col-lg-4">
                      <p className="float-right">$40.00</p>
                    </div>
                    <div className="col-lg-8">
                      <p>Fees</p>
                    </div>
                    <div className="col-lg-4">
                      <p className="float-right">$2.50</p>
                    </div>
                  </div>
                  <div className="row no-gutters pt-4 mb-4">
                    <div className="col-lg-8">
                      <p className="font-weight-bold">Total</p>
                    </div>
                    <div className="col-lg-4">
                      <p className="float-right font-weight-bold">$42.50</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <h5 className="font-weight-bold pb-2">Billing Address</h5>
                      <p className="mb-4">
                        O5745 Peladeau Street
                        <br />
                        Emeryville, CA 94608
                      </p>
                    </div>
                    <div className="col-md-6">
                      <h5 className="font-weight-bold pb-2">Payment Method</h5>
                      <p>
                        Credit Card
                        <br />
                        **** **** **** 1234
                      </p>
                    </div>
                  </div>
                 
                </div>
              </div>

              <div className="border-top w-100 p-4 text-right d-none d-xl-flex d-lg-flex align-items-center justify-content-between">
                <div className="text-xl-left text-md-left text-lg-left text-center">
                  <Link className="btn btn-back btn-outline-brand border-brand font-size-14 font-weight-bold pl-4 pr-4 pt-2 pb-2 text-brand">
                    <i className="fas fa-arrow-left mr-2"></i>
                    Back to Homepage
                  </Link>
                </div>

                <button className="btn btn-brand font-size-14 pl-4 pr-4 pt-2 pb-2">
                  Print Receipt
                </button>
              </div>
            </div>
          </div>
        </div>
        <EventFooter />
      </div>
    );
  }
}

export default ViewSuccessPage;
