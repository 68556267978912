/* eslint-disable no-undef */
import React from 'react'
import { Loader } from '../../config/Svgicon'
import _ from 'lodash'
import { Select, DatePicker, message, Empty } from 'antd'
import Modal from 'react-bootstrap/Modal'
import { usersService } from '../../_services/usersService'
import ResponseFilter from '../../config/response-handler'
import moment from 'moment'
import phone from 'phone'
import Pagination from 'react-js-pagination'
import { isValidEmail } from '../../utils'
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from 'react-places-autocomplete'
import ReactPhoneInput from 'react-phone-input-2'
import { CSVLink } from 'react-csv'

const { Option } = Select
const dateFormat = 'MM-DD-YYYY'

const genderValue = type => {
  switch (type) {
    case 1:
      return 'Male'
    case 2:
      return 'Female'
    case 3:
      return 'Other'
    default:
      return 'Select'
  }
}

class Attendee extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      limit: 5,
      orderId: '',
      address2: '',
      page: 1,
      dobErrorMsg: '',
      sortOrder: 'desc',
      sortBy: 'created',
      loader: false,
      openEditPopup: false,
      openAddPopup: false,
      openCheckinPopup: false,
      eventId: this.props.eventId,
      name: '',
      email: '',
      address: '',
      city: '',
      zip: '',
      state: '',
      gender: '',
      dob: '',
      ticketId: '',
      Price: '',
      quantity: '',
      selectQuan: '',
      lastName: '',
      firstName: '',
      userId: '',
      disabledBtn: false,
      emailErrorMsg: '',
      firstNameErrorMsg: '',
      lastNameErrorMsg: '',
      phoneErrorMsg: '',
      ticketIdErrorMsg: '',
      quantityErrorMsg: '',
      priceErrorMsg: '',
      phoneInput: '',
      allowLandline: false,
      showDangerHelp: false,
      showSuccessHelp: false,
      result: [],
      ticketName: '',
      ticketPrice: '',
      description: '',
      ticketEditName: '',
      searchDebounce:null,
      ticketPriceShow: '',
      attendeeTicketDetails: [],
      attendeesListCSV: [],
      CSV_headers: [
        { label: 'Order', key: 'Order' },
        { label: 'Name', key: 'Name' },
        { label: 'Email', key: 'Email' },
        { label: 'Ticket', key: 'Ticket' },
        { label: 'Ticket Price', key: 'Price' }
        // { label: 'QTY.', key: 'QTY' },
        // { label: 'Total', key: 'Total' },
        // { label: 'QR Codes', key: 'QR' }
      ]
    }
  }

  componentDidMount () {
    if (localStorage.getItem('role') == 2) {
      if(!localStorage.getItem('promoterId')||localStorage.getItem('promoterId')==''){
        this.props.history.push('/events-feed');
      } else {
        this.setState({isAssistant:true});
      }
    }
    this.getAttendeesList()
    this.getAttendeesCSV()
  }

  handleChangephone = e => {
    this.setState({
      phoneInput: e,
      showDangerHelp: false
    })
  }

  handleSelectAddress = address => {
    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(latLng => {
        let newaddress = address.split(',')
        this.setState({
          lng: latLng.lng,
          lat: latLng.lat,
          address: newaddress[0]
        })

        let Url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latLng.lat},${latLng.lng}&key=${process.env.REACT_APP_MAP_KEY}`

        fetch(Url)
          .then(response => response.json())
          .then(result => {
            let zipcode
            let state
            let city
            for (var i = 0; i < result.results.length; i++) {
              for (
                var j = 0;
                j < result.results[i].address_components.length;
                j++
              ) {
                for (
                  var k = 0;
                  k < result.results[i].address_components[j].types.length;
                  k++
                ) {
                  if (
                    result.results[i].address_components[j].types[k] ==
                    'postal_code'
                  ) {
                    zipcode = result.results[i].address_components[j].short_name

                    if (zipcode) {
                      this.setState({ zip: zipcode })
                    } else {
                      this.setState({ zip: '' })
                    }
                  }
                  if (
                    result.results[i].address_components[j].types[k] ==
                    'locality'
                  ) {
                    city = result.results[i].address_components[j].long_name
                    if (city) {
                      this.setState({ city: city })
                    } else {
                      this.setState({ city: '' })
                    }
                  }
                  if (
                    result.results[i].address_components[j].types[k] ==
                    'administrative_area_level_1'
                  ) {
                    state = result.results[i].address_components[j].long_name
                    if (zipcode) {
                      this.setState({ state: state })
                    } else {
                      this.setState({ state: '' })
                    }
                  }
                }
              }
            }
          })
      })
      .catch(error => console.error('Error', error))
  }

  handleChangeAddress = address => {
    if (address.length == 0) {
      this.setState({
        city: '',
        state: '',
        zip: ''
      })
    }
    this.setState({ address })
  }

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
      [e.target.name + 'ErrorMsg']: ''
    })
  }
  
  handleSearch = e => {
    let search = e.target.value;
    clearTimeout(this.state.searchDebounce);
    this.setState({search:search,loader:true,page:1,searchDebounce:setTimeout(this.getAttendeesList,1000)});
  }

  changeLimit = e => {
    this.setState({ limit: e, page: 1 }, () => this.getAttendeesList())
  }

  handlePageChange = page => {
    this.setState({ page: page, loader: true }, () => this.getAttendeesList())
  }

  handleSort = e => {
    this.setState(
      {
        loader: false,
        page: 1,
        sortBy: e,
        sortOrder: this.state.sortOrder === 'asc' ? 'desc' : 'asc'
      },
      () => this.getAttendeesList()
    )
  }

  handleGenderChange = e => {
    this.setState({ gender: e ? e : '' })
  }

  handleEditGenderChange = e => {
    this.setState({ gender: e ? parseInt(e) : '' })
  }

  disabledDate = current => {
    return current && current > moment().subtract(1, 'day')
  }

  handleSelectDobChange = date => {
    this.setState({
      dob: date ? moment(date) : '' // Formatted String, ex: "11-16-2020"
    })
  }

  closeModal = () => {
    this.setState({
      openAddPopup: false,
      openEditPopup: false,
      openCheckinPopup: false,
      showDangerHelp: false,
      showSuccessHelp: false,
      name: '',
      disabledBtn: false,
      email: '',
      phoneInput: '',
      address: '',
      city: '',
      zip: '',
      state: '',
      gender: '',
      dob: '',
      ticketId: '',
      price: '',
      quantity: '',
      quantitySelect: '',
      lastName: '',
      firstName: '',
      userId: '',
      emailErrorMsg: '',
      firstNameErrorMsg: '',
      lastNameErrorMsg: '',
      phoneErrorMsg: '',
      ticketIdErrorMsg: '',
      quantityErrorMsg: '',
      priceErrorMsg: '',
      ticketName: '',
      ticketPrice: '',
      description: '',
      address2: ''
    })
  }
  handleOpenAddPopup = () =>
    this.setState({ openAddPopup: !this.state.openAddPopup })
  handleOpenCheckinPopup = item => {
    console.log('ITEM', item)
    let ticketInfo = []
    item.qrTokenVerification.map(ticket => {
      let currentTicket = ticket
      currentTicket.ticketStatus = ''
      ticketInfo.push(currentTicket)
    })
    this.setState({
      openCheckinPopup: !this.state.openCheckinPopup,
      attendeeTicketDetails: ticketInfo
    })
  }
  handleOpenEditPopup = item => {
    this.getOrderDetails(item.orderId, item.ticketId)
    this.setState({
      attendeeId: item._id,
      userId: item.userId,
      openEditPopup: !this.state.openEditPopup
    })
  }
  checkInTicket = (ticket, i) => {
    let token = localStorage.getItem('accessToken')
    console.log('TOKEN', token)
    console.log('TICKET', ticket)

    usersService
      .qrVerification('qrToken=' + ticket.qrToken, token)
      .then(res => {
        console.log(res)
        let tickets = this.state.attendeeTicketDetails
        if (res.data.error) {
          tickets[i].ticketStatus = res.data.error.responseMessage
        } else if (
          res.data.responseData &&
          res.data.responseData.message == 'Ticket verified'
        ) {
          tickets[i].ticketStatus = 'Success!'
          tickets[i].isVerified = 1
        } else {
          tickets[i].ticketStatus = 'There was an error.'
        }
        this.setState({
          attendeeTicketDetails: tickets
        })
      })
  }
  getOrderDetails = (orderId, ticketId) => {
    let params = `orderId=${orderId}&ticketId=${ticketId}`
    usersService.orderDetails(params).then(res => {
      let { status, resData } = ResponseFilter(res)
      if (status) {
        this.setState({
          firstName: resData.result[0].contactInformation.firstName,
          lastName: resData.result[0].contactInformation.lastName,
          email: resData.result[0].contactInformation.email,
          phoneInput: resData.result[0].contactInformation.phone,
          gender: resData.result[0].contactInformation.gender,
          dob: resData.result[0].contactInformation.dob,

          address: resData.result[0].billingInformation.address1,
          address2: resData.result[0].billingInformation.address2,
          city: resData.result[0].billingInformation.city,
          state: resData.result[0].billingInformation.state,
          zip: resData.result[0].billingInformation.zipCode,

          quantity: resData.result[0].ticketDetails.ticketQuantity,
          ticketEditName: resData.result[0].ticketDetails.ticketName,
          ticketId: resData.result[0].ticketDetails.ticketId,
          ticketName: resData.result[0].ticketDetails.ticketName,
          ticketPriceShow: resData.result[0].ticketDetails.ticketPrice,
          price: resData.result[0].ticketDetails.ticketPrice,

          orderId: resData.result[0].orderId,
          eventId: resData.result[0].eventId
        })
      }
    })
  }

  getAttendeesList = () => {
    let { search, limit, page, sortOrder } = this.state
    let obj = {
      eventId: this.props.eventId,
      search: search,
      limit: limit,
      sortOrder: sortOrder,
      page: page - 1 ? page - 1 : 0,
      status: 1
    }
    usersService.getAttendees(obj).then(res => {
      let { status, resData } = ResponseFilter(res)
      console.log('ATENDEES', res)
      if (status) {
        this.setState({ loader: false })
        this.setState({
          attendeesList: resData.result,
          totalCount: resData.count
        })
      } else {
        this.setState({ loader: false })
      }
    })
  }

  getAttendeesCSV = () => {
    let { sortOrder } = this.state
    this.setState({ loader: true })
    let obj = {
      eventId: this.props.eventId,
      // search: search,
      limit: 10000000000,
      sortOrder: sortOrder,
      page: 0,
      status: 1
    }
    usersService.getAttendees(obj).then(res => {
      let { status, resData } = ResponseFilter(res)
      console.log('ATENDEES for csv export: ', res)
      if (status) {
        let attendeesListCSV = []

        resData.result.forEach(item => {
          // let QRCodes = ""
          // if (item.qrTokenVerification.length > 0) {
          //   item.qrTokenVerification.forEach(item => {
          //     QRCodes += `QR Code: ${item.qrToken}, Refunded/Used: ${item.isVerified ? "Yes": "No"}\n`
          //   })
          // }
          for (let i = 0; i < item.quantity; i++) {
            if (item.qrTokenVerification[i].isVerified === 0) {
              attendeesListCSV.push({
                Order: item.orderId ? item.orderId : '',
                Name: item.name ? item.name : '',
                Email: item.attendeeEmail ? item.attendeeEmail : '',
                Ticket: item.ticketName ? item.ticketName : '',
                Price: item.ticketPrice ? item.ticketPrice : '',
                QTY: item.quantity ? item.quantity : ''
                // Total: item.grandTotal ? item.grandTotal : '',
                // QR: QRCodes
              })
            }
          }
        })
        this.setState({ loader: false })
        this.setState({
          attendeesListCSV: attendeesListCSV
          // totalCount: resData.count,
        })
      } else {
        this.setState({ loader: false })
        message.error('Something went wrong!')
      }
    })
  }

  handleValidation = () => {
    let validate = true
    const { phoneInput, allowLandline } = this.state
    const result = phone(`+ ${phoneInput}`, '', allowLandline)
    let { firstName, lastName, email, ticketId, quantity, price, selectQuan } =
      this.state

    var nameRegex = /^[a-zA-Z ]{2,30}$/
    var firstname1 = firstName.trim()
    var lastname1 = lastName.trim()

    if (phoneInput !== '') {
      if (result.length) {
        this.setState({
          validate: false,
          result,
          showDangerHelp: false,
          showSuccessHelp: true
        })
      } else {
        validate = false
        this.setState({
          validate: false,
          result,
          showDangerHelp: true,
          showSuccessHelp: false
        })
      }
    }

    if (email === '' || email === undefined) {
      validate = false
      this.setState({
        emailErrorMsg: 'Please enter email address.'
      })
    } else if (!isValidEmail(String(email.toLowerCase()))) {
      validate = false
      this.setState({ emailErrorMsg: 'Please enter a valid email address.' })
    } else {
      this.setState({
        emailErrorMsg: ''
      })
    }

    if (!firstName.replace(/\s/g, '').length) {
      validate = false
      this.setState({
        firstNameErrorMsg: 'Please enter your first name.'
      })
    }
    if (firstname1 === '' || firstname1 === undefined || firstname1 === null) {
      validate = false
      this.setState({
        firstNameErrorMsg: 'Please enter your first name.'
      })
    } else if (!nameRegex.test(firstname1)) {
      validate = false
      this.setState({
        firstNameErrorMsg: 'Please enter valid first name.'
      })
    } else if (firstname1.length < 2) {
      validate = false
      this.setState({
        firstNameErrorMsg: 'Please enter valid first name.'
      })
    }

    if (!lastName.replace(/\s/g, '').length) {
      validate = false
      this.setState({
        lastNameErrorMsg: 'Please enter your last name.'
      })
    }
    if (lastname1 === '' || lastname1 === undefined || lastname1 === null) {
      validate = false
      this.setState({
        lastNameErrorMsg: 'Please enter your last name.'
      })
    } else if (!nameRegex.test(lastname1)) {
      validate = false
      this.setState({
        lastNameErrorMsg: 'Please enter valid last name.'
      })
    } else if (lastname1.length < 2) {
      validate = false
      this.setState({
        lastNameErrorMsg: 'Please enter valid last name.'
      })
    }

    if (ticketId === '' || ticketId === undefined) {
      validate = false
      this.setState({
        ticketIdErrorMsg: 'Please select ticket.'
      })
    } else {
      this.setState({
        ticketIdErrorMsg: ''
      })
    }

    if (ticketId === '' || ticketId === undefined) {
      validate = false
      this.setState({
        priceErrorMsg: 'Please enter price.'
      })
    } else {
      this.setState({
        priceErrorMsg: ''
      })
    }

    if (selectQuan === '' || selectQuan === undefined) {
      validate = false
      this.setState({
        quantityErrorMsg: 'Please enter quantity.'
      })
    }
    if (quantity === '' || quantity === undefined) {
      validate = false
      this.setState({
        quantityErrorMsg: 'Please enter quantity.'
      })
    } else if (quantity < 1) {
      validate = false
      this.setState({
        quantityErrorMsg: 'Please enter valid quantity.'
      })
    }
    return validate
  }

  handleAddSubmit = event => {
    event.preventDefault()
    let self = this
    if (this.handleValidation()) {
      let params = {
        isPromoter: 1,
        eventId: this.props.eventId,
        name:
          this.state.firstName.replace(/\s/g, '') +
          ' ' +
          this.state.lastName.replace(/\s/g, ''),
        email: this.state.email.toLowerCase(),
        phone: this.state.phoneInput,
        address2: this.state.address2,
        gender: this.state.gender,
        dob: this.state.dob ? moment(this.state.dob).format('YYYY-MM-DD') : '',
        ticketDetails: JSON.stringify([
          {
            ticketId: this.state.ticketId,
            ticketName: this.state.ticketName,
            ticketQuantity: this.state.quantity,
            ticketPrice: this.state.ticketPrice,
            description: this.state.description
          }
        ]),
        subTotal: Number(this.state.ticketPrice) * Number(this.state.quantity),
        grandTotal:
          Number(this.state.ticketPrice) * Number(this.state.quantity),
        fees: 0,
        quantity: this.state.quantity,
        address: this.state.address,
        city: this.state.city,
        zip: this.state.zip,
        state: this.state.state
      }
      const accessToken = localStorage.getItem('accessToken')
      this.setState({ disabledBtn: true, loader: true })
      usersService.createAttendee(params).then(res => {
        ResponseFilter(res)
        if (res.data.statusCode === 1) {
          message.success('Attendee created successfully!')
          self.closeModal()
          self.setState(
            {
              disabledBtn: false,
              loader: false
            },
            () => this.getAttendeesList()
          )
        } else {
          self.closeModal()
          self.setState(
            {
              disabledBtn: false,
              loader: false
            },
            () => this.getAttendeesList()
          )
        }
      })
    }
  }

  handleEditValidation = () => {
    let validate = true
    let { firstName, lastName, ticketId, quantity } = this.state
    var nameRegex = /^[a-zA-Z ]{2,30}$/
    var firstname1 = firstName.trim()
    var lastname1 = lastName.trim()
    const { phoneInput, allowLandline } = this.state
    const result = phone(`+ ${phoneInput}`, '', allowLandline)
    if (phoneInput !== '') {
      if (result.length) {
        this.setState({
          validate: false,
          result,
          showDangerHelp: false,
          showSuccessHelp: true
        })
      } else {
        validate = false
        this.setState({
          validate: false,
          result,
          showDangerHelp: true,
          showSuccessHelp: false
        })
      }
    }

    if (!firstName.replace(/\s/g, '').length) {
      validate = false
      this.setState({
        firstNameErrorMsg: 'Please enter your first name.'
      })
    }
    if (firstname1 === '' || firstname1 === undefined || firstname1 === null) {
      validate = false
      this.setState({
        firstNameErrorMsg: 'Please enter your first name.'
      })
    } else if (!nameRegex.test(firstname1)) {
      validate = false
      this.setState({
        firstNameErrorMsg: 'Please enter valid first name.'
      })
    } else if (firstname1.length < 2) {
      validate = false
      this.setState({
        firstNameErrorMsg: 'Please enter valid first name.'
      })
    }

    if (!lastName.replace(/\s/g, '').length) {
      validate = false
      this.setState({
        lastNameErrorMsg: 'Please enter your last name.'
      })
    }
    if (lastname1 === '' || lastname1 === undefined || lastname1 === null) {
      validate = false
      this.setState({
        lastNameErrorMsg: 'Please enter your last name.'
      })
    } else if (!nameRegex.test(lastname1)) {
      validate = false
      this.setState({
        lastNameErrorMsg: 'Please enter valid last name.'
      })
    } else if (lastname1.length < 2) {
      validate = false
      this.setState({
        lastNameErrorMsg: 'Please enter valid last name.'
      })
    }

    if (ticketId === '' || ticketId === undefined) {
      validate = false
      this.setState({
        ticketIdErrorMsg: 'Please select ticket.'
      })
    } else {
      this.setState({
        ticketIdErrorMsg: ''
      })
    }
    if (quantity === '' || quantity === undefined) {
      validate = false
      this.setState({
        quantityErrorMsg: 'Please enter quantity.'
      })
    } else {
      this.setState({
        quantityErrorMsg: ''
      })
    }
    return validate
  }

  handleQuantity = i => {
    let quant = i ? i : ''
    this.setState({ quantity: quant, quantityErrorMsg: '', selectQuan: quant })
  }

  handleEditSubmit = event => {
    event.preventDefault()
    let self = this
    if (this.handleEditValidation()) {
      let params = {
        attendeeId: this.state.attendeeId,
        userId: this.state.userId,
        eventId: this.props.eventId,
        ticketId: this.state.ticketId,
        name:
          this.state.firstName.replace(/\s/g, '') +
          ' ' +
          this.state.lastName.replace(/\s/g, ''),
        phone: this.state.phoneInput,
        gender: this.state.gender,
        dob: this.state.dob ? moment(this.state.dob).format('YYYY-MM-DD') : '',
        quantity: this.state.quantity,
        address: this.state.address,
        address2: this.state.address2,
        city: this.state.city,
        zip: this.state.zip,
        state: this.state.state,
        orderId: this.state.orderId
      }
      const accessToken = localStorage.getItem('accessToken')
      this.setState({ disabledBtn: true })
      usersService.updateAttendee(params, accessToken).then(res => {
        ResponseFilter(res)
        if (res.data.statusCode === 1) {
          message.success('Attendee details has been updated successfully!')
          self.closeModal()
          self.setState(
            {
              disabledBtn: false
            },
            () => this.getAttendeesList()
          )
        } else {
          self.closeModal()
          self.setState(
            {
              disabledBtn: false
            },
            () => this.getAttendeesList()
          )
        }
      })
    }
  }

  handleTicketSelect (ticketId, ticketName, ticketPrice, description, quantity) {
    this.setState({
      selectQuan: '',
      quantitySelect: quantity,
      ticketId: ticketId,
      ticketName: ticketName,
      ticketPrice: ticketPrice === null ? 0 : ticketPrice,
      description: description,
      ticketIdErrorMsg: '',
      priceErrorMsg: ''
    })
  }

  handleTicketChange = e => {
    this.setState({
      ticketId: e ? e : '',
      ticketIdErrorMsg: '',
      priceErrorMsg: ''
    })
  }

  numberInputValidation = e => {
    if (
      !(
        (e.keyCode > 95 && e.keyCode < 106) ||
        (e.keyCode > 47 && e.keyCode < 58) ||
        e.keyCode === 8
      )
    ) {
      e.preventDefault()
    }
  }

  render () {
    const getOptionsArray = count => {
      const array = []
      for (let i = 0; i < count; i++) {
        array.push(i + 1)
      }
      return array
    }
    let today = moment(new Date()).format('MM-DD-YYYY')

    const { phoneInput, quantitySelect, dobErrorMsg, showDangerHelp } =
      this.state

    let {
      loader,
      attendeesList,
      disabledBtn,
      emailErrorMsg,
      firstNameErrorMsg,
      lastNameErrorMsg,
      ticketIdErrorMsg,
      quantityErrorMsg,
      priceErrorMsg
    } = this.state

    return (
      <React.Fragment>
        {loader ? <Loader /> : null}

        <div className='text-right'>
          <button
            className='btn btn-sm btn-primary  mb-2'
            onClick={this.handleOpenAddPopup}
          >
            <i className='fa fa-plus'></i> Add Attendee
          </button>

          <CSVLink
            filename={`Attendees-List-Results_${today ? today : ''}.csv`}
            data={this.state.attendeesListCSV}
            headers={this.state.CSV_headers}
            className='btn btn-sm btn-dark mb-2 ml-2'
          >
            Export Results
          </CSVLink>
        </div>
        <div
          className='tab-pane fade show active'
          id='details'
          role='tabpanel'
          aria-labelledby='details'
        >
          <div className='row'>
            <div className='col-md-6 col-sm-6 mb-2 mb-lg-0 mb-md-0'>
              <div className='d-flex flex-row select-rang'>
                Show
                <Select
                  className='select-limit mr-1 ml-1'
                  value={this.state.limit}
                  defaultValue={this.state.limit}
                  style={{ width: 120 }}
                  onChange={this.changeLimit}
                >
                  <Option value={5}>5</Option>
                  <Option value={10}>10</Option>
                  <Option value={15}>15</Option>
                </Select>
                <span>entries</span>
              </div>
            </div>
            <div className='col-md-6 col-sm-6 mb-lg-0 mb-md-0 text-md-right'>
              <input
                onKeyPress={e => {
                  if (e.key === 'Enter') e.preventDefault()
                }}
                className='form-control search-box'
                name='search'
                placeholder='Search'
                type='search'
                value={this.state.search1}
                onChange={this.handleSearch}
                maxLength='100'
              />
            </div>
          </div>
          {/* <div className="row">
            <div className="col-6">
              Show
              <Select
                className="select-limit mr-1 ml-1"
                value={this.state.limit}
                defaultValue={this.state.limit}
                style={{ width: 120 }}
                onChange={this.changeLimit}
              >
                <Option value={5}>5</Option>
                <Option value={10}>10</Option>
                <Option value={15}>15</Option>
              </Select>
              entries
            </div>
            <div className="col-6 mb-3">
              <div className="d-flex flex-row-reverse bd-highlight">
                <div>
                  <span>
                    <input
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') e.preventDefault();
                      }}
                      className="form-control search-box"
                      name="search"
                      placeholder="Search"
                      type="search"
                      value={this.state.search}
                      onChange={this.handleSearch}
                    />
                  </span>
                </div>
              </div>
            </div>
          </div> */}
          <div className='table-responsive'>
            <table id='example' className='table table-striped table-bordered'>
              <thead>
                <tr>
                  <th>Check In</th>
                  <th>Order</th>
                  <th>
                    First
                    <i
                      className='fa fa-sort'
                      aria-hidden='true'
                      onClick={() => this.handleSort('name')}
                    ></i>
                  </th>
                  <th>Last</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>
                    QTY
                    <i
                      className='fa fa-sort'
                      aria-hidden='true'
                      onClick={() => this.handleSort('quantity')}
                    ></i>
                  </th>
                  <th>Type</th>
                  {this.state.isAssistant?"":<th>Paid</th>}
                  {/* <th>Actions</th> */}
                </tr>
              </thead>
              <tbody>
                {!_.isEmpty(attendeesList) ? (
                  <React.Fragment>
                    {attendeesList.map((item, i) => {
                      item.name = item.name.trim().replace(/\s\s+/g, ' ')
                      return (
                        <tr>
                          <td>
                            <button
                              type='button'
                              class='btn btn-sm btn-dark simple-ajax-popup-align-top'
                              onClick={() => this.handleOpenCheckinPopup(item)}
                            >
                              Check In
                            </button>
                          </td>
                          <td style={{ width: 150 }}>
                            {item.orderId ? item.orderId : ''}
                          </td>
                          <td>{item.name ? item.name.split(' ')[0] : ''}</td>
                          <td>{item.name ? item.name.split(' ')[1] : ''}</td>
                          <td>
                            {item.attendeeEmail ? item.attendeeEmail : ''}
                          </td>
                          <td>
                            {item.attendeePhone
                              ? `+${item.attendeePhone}`
                              : '_'}
                          </td>
                          <td>
                            {item.quantity ? item.quantity : ''}
                            <span className='font-size-10'>
                              {item.qrTokenVerification.filter(
                                item => item.isVerified === 1
                              ).length > 0 &&
                                ` (${item.qrTokenVerification.reduce(
                                  (acc, item) => {
                                    return acc + item.isVerified
                                  },
                                  0
                                )} Refunded/Used)`}
                            </span>
                          </td>
                          <td>{item.ticketName ? item.ticketName : ''}</td>
                          {this.state.isAssistant?"":
                          <td>
                            {item.ticketPrice
                              ? `$${(
                                  Number(item.quantity) *
                                  Number(item.ticketPrice)
                                ).toFixed(2)}`
                              : 'Free'}
                          </td>
                          }
                          {/* <td>
                            <button
                              type="button"
                              className="btn btn-sm btn-dark simple-ajax-popup-align-top"
                              onClick={() => this.handleOpenEditPopup(item)}
                            >
                              Details
                            </button>
                          </td> */}
                        </tr>
                      )
                    })}
                  </React.Fragment>
                ) : (
                  ''
                )}
              </tbody>
            </table>
          </div>
          {!_.isEmpty(attendeesList) ? (
            ''
          ) : (
            <div className='text-center'>
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </div>
          )}
        </div>
        <div className='card-footer bg-white'>
          <div className='d-flex align-items-end justify-content-between flex-column flex-sm-row mt-4'>
            {!_.isEmpty(attendeesList) ? (
              <p className='mb-3 mb-sm-0'>
                Showing{' '}
                {this.state.page == 1 ? (
                  <React.Fragment>1</React.Fragment>
                ) : (
                  this.state.page * this.state.limit - this.state.limit + 1
                )}{' '}
                to{' '}
                {attendeesList
                  ? attendeesList.length === this.state.limit
                    ? this.state.page * this.state.limit -
                      this.state.limit +
                      this.state.limit
                    : this.state.page * this.state.limit -
                      this.state.limit +
                      attendeesList.length
                  : ''}{' '}
                of {this.state.totalCount} attendees
              </p>
            ) : (
              <p className='mb-3 mb-sm-0'>Showing 0 to 0 of 0 attendees</p>
            )}

            <nav className='pagination-nav'>
              <ul>
                <Pagination
                  hideFirstLastPages={true}
                  itemClassPrev='page-change'
                  prevPageText='Previous'
                  itemClassNext='page-change'
                  nextPageText='Next'
                  activePage={this.state.page}
                  itemsCountPerPage={this.state.limit}
                  totalItemsCount={this.state.totalCount}
                  pageRangeDisplayed={3}
                  onChange={this.handlePageChange}
                />
              </ul>
            </nav>
          </div>
        </div>
        <Modal
          size='lg'
          show={this.state.openAddPopup}
          onHide={this.closeModal}
          aria-labelledby='example-modal-sizes-title-sm'
        >
          <Modal.Header closeButton>
            <h5 className='card-title mt-4'>Add Attendee</h5>
          </Modal.Header>

          <div id='custom-content' className='white-popup-block'>
            <div className='card card-margin'>
              <div className='card-body'>
                <form className='forms-sample' onSubmit={this.handleAddSubmit}>
                  <div className='form-row'>
                    <div className='form-group col-md-6'>
                      <label for='exampleInputUsername12'>
                        First Name<span className='text-danger'>*</span>
                      </label>
                      <input
                        type='text'
                        className='form-control'
                        maxLength='50'
                        name='firstName'
                        placeholder='First Name'
                        value={this.state.firstName}
                        onChange={this.handleChange}
                      />
                      {firstNameErrorMsg ? (
                        <div className='error ml-0'>{firstNameErrorMsg}</div>
                      ) : null}
                    </div>
                    <div className='form-group  col-md-6'>
                      <label for='exampleInputUsername12'>
                        Last Name<span className='text-danger'>*</span>
                      </label>
                      <input
                        type='text'
                        className='form-control'
                        maxLength='50'
                        id='event-sub'
                        placeholder='Last Name'
                        name='lastName'
                        value={this.state.lastName}
                        onChange={this.handleChange}
                      />
                      {lastNameErrorMsg ? (
                        <div className='error ml-0'>{lastNameErrorMsg}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className='form-row'>
                    <div className='form-group col-md-6'>
                      <label for='exampleInputUsername12'>
                        Email<span className='text-danger'>*</span>
                      </label>
                      <input
                        type='text'
                        className='form-control'
                        id='event-name'
                        placeholder='Email'
                        name='email'
                        value={this.state.email}
                        onChange={this.handleChange}
                        maxLength='100'
                      />
                      {emailErrorMsg ? (
                        <div className='error ml-0'>{emailErrorMsg}</div>
                      ) : null}
                    </div>
                    <div className='form-group  col-md-6'>
                      <label for='exampleInputUsername12'>Phone</label>
                      <ReactPhoneInput
                        className='form-control'
                        country={'us'}
                        value={phoneInput}
                        onChange={this.handleChangephone}
                      />

                      {showDangerHelp && (
                        <div className='error ml-0'>
                          This phone number is invalid
                        </div>
                      )}
                    </div>
                  </div>
                  <hr />
                  <div className='form-row'>
                    <div className='form-group col-md-4'>
                      <label for='exampleInputUsername12'>
                        Ticket Type
                        <span className='text-danger'>*</span>
                      </label>
                      <Select
                        className='cm-select-box'
                        defaultValue='Female'
                        // onChange={this.handleTicketChange}
                        name='gender'
                        value={
                          this.state.ticketName
                            ? this.state.ticketName
                            : 'Select Ticket'
                        }
                      >
                        {!_.isEmpty(this.props.ticketsList)
                          ? this.props.ticketsList
                              .filter(e => e.quantity !== '0')
                              .map(item =>
                                item.quantity <= '0' ? (
                                  ''
                                ) : (
                                  <Option>
                                    <div
                                      onClick={this.handleTicketSelect.bind(
                                        this,
                                        item._id,
                                        item.ticketName,
                                        item.price,
                                        item.description,
                                        item.quantity
                                      )}
                                    >
                                      {item.ticketName}
                                    </div>
                                  </Option>
                                )
                              )
                          : ''}
                      </Select>
                      {ticketIdErrorMsg ? (
                        <div className='error ml-0'>{ticketIdErrorMsg}</div>
                      ) : null}
                    </div>
                    <div className='form-group col-md-4'>
                      <label for='exampleInputUsername12'>
                        Price<span className='text-danger'>*</span>
                      </label>

                      <span className='prefix'>$</span>
                      <input
                        type='text'
                        maxLength='100'
                        className='form-control price-input bg-white'
                        placeholder='Price'
                        readOnly
                        value={
                          this.state.quantity
                            ? this.state.ticketPrice === 0
                              ? 'Free'
                              : Number(this.state.ticketPrice).toFixed(2)
                            : 'Price'
                        }
                      />

                      {priceErrorMsg ? (
                        <div className='error ml-0'>{priceErrorMsg}</div>
                      ) : null}
                    </div>
                    <div className='form-group col-md-4'>
                      <label for='exampleInputUsername12'>
                        Quantity<span className='text-danger'>*</span>
                      </label>

                      <Select
                        className='cm-select-box'
                        placeholder='Select'
                        onChange={this.handleQuantity}
                        value={this.state.selectQuan}
                        disabled={quantitySelect ? false : true}
                      >
                        {getOptionsArray(quantitySelect).map(num => (
                          <Option value={num}> {num}</Option>
                        ))}
                      </Select>
                      {/* <select
                        onClick={(i) => this.handleQuantity(i)}
                        className="form-control  font-size-14 shadow-none"
                        disabled={quantitySelect ? false : true}
                      >
                        <option key="" value="">
                          Select
                        </option>
                        {getOptionsArray(quantitySelect).map((num) => (
                          <option key={num} value={num}>
                            {num}
                          </option>
                        ))}
                      </select> */}
                      {quantityErrorMsg ? (
                        <div className='error ml-0'>{quantityErrorMsg}</div>
                      ) : null}
                    </div>
                  </div>

                  <hr />
                  <div className='form-group'>
                    <label className='font-weight-semibold' for='inputAddress'>
                      Address:
                    </label>
                    <PlacesAutocomplete
                      value={this.state.address}
                      onChange={this.handleChangeAddress}
                      onSelect={this.handleSelectAddress}
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading
                      }) => (
                        <div>
                          <input
                            {...getInputProps({
                              placeholder: 'Search Places',
                              className: 'location-search-input form-control'
                            })}
                            maxLength='100'
                          />
                          <div className='autocomplete-dropdown-container'>
                            {loading && <div>Loading...</div>}
                            {suggestions.map(suggestion => {
                              const className = suggestion.active
                                ? 'suggestion-item--active'
                                : 'suggestion-item'
                              // inline style for demonstration purpose
                              const style = suggestion.active
                                ? {
                                    backgroundColor: '#fafafa',
                                    cursor: 'pointer'
                                  }
                                : {
                                    backgroundColor: '#ffffff',
                                    cursor: 'pointer'
                                  }
                              return (
                                <div
                                  {...getSuggestionItemProps(suggestion, {
                                    className,
                                    style
                                  })}
                                >
                                  <span>{suggestion.description}</span>
                                </div>
                              )
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
                  </div>
                  <div className='form-group'>
                    <label className='font-weight-semibold' for='inputAddress'>
                      Address 2:
                    </label>
                    <input
                      maxLength='200'
                      type='text'
                      className='form-control'
                      id='inputCity'
                      placeholder='Address 2'
                      name='address2'
                      value={this.state.address2}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className='form-row'>
                    <div className='form-group col-md-4'>
                      <label className='font-weight-semibold' for='inputCity'>
                        City:
                      </label>
                      <input
                        maxLength='50'
                        type='text'
                        disabled
                        className='form-control'
                        id='inputCity'
                        placeholder='City'
                        name='city'
                        value={this.state.city}
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className='form-group col-md-4'>
                      <label className='font-weight-semibold' for='state'>
                        State:
                      </label>
                      <input
                        type='text'
                        className='form-control'
                        id='inputCity'
                        placeholder='State'
                        name='state'
                        disabled
                        maxLength='50'
                        value={this.state.state}
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className='form-group col-md-4'>
                      <label className='font-weight-semibold' for='inputZip'>
                        Zip:
                      </label>
                      <input
                        type='text'
                        className='form-control'
                        id='inputCity'
                        placeholder='Zip'
                        disabled
                        name='zip'
                        maxLength='10'
                        value={this.state.zip}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                  <hr />

                  <div className='form-row'>
                    <div className='form-group col-md-6'>
                      <label for='exampleInputUsername12'>Gender</label>
                      <br />
                      <Select
                        className='cm-select-box'
                        defaultValue='Female'
                        onChange={this.handleGenderChange}
                        name='gender'
                        value={
                          this.state.gender
                            ? this.state.gender
                            : 'Select Gender'
                        }
                      >
                        <Option value='1'>Male</Option>
                        <Option value='2'>Female</Option>
                        <Option value='3'>Other</Option>
                      </Select>
                    </div>
                    <div className='form-group col-md-6'>
                      <label for='exampleInputUsername12'>Birthdate</label>
                      <DatePicker
                        type='date'
                        className='form-control'
                        disabledDate={this.disabledDate}
                        format={dateFormat}
                        name='dob'
                        value={this.state.dob ? moment(this.state.dob) : ''}
                        onChange={this.handleSelectDobChange}
                      />

                      {dobErrorMsg ? (
                        <div className='error ml-0'>{dobErrorMsg}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className='card-footer bg-white'>
                    <button
                      type='button'
                      className='btn btn-light'
                      onClick={this.closeModal}
                    >
                      Cancel
                    </button>
                    <button
                      type='submit'
                      className='btn btn-primary ml-2'
                      disabled={disabledBtn}
                    >
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Modal>

        <Modal
          size='lg'
          show={this.state.openEditPopup}
          onHide={this.closeModal}
          aria-labelledby='example-modal-sizes-title-sm'
        >
          <Modal.Header closeButton>
            <h5 className='card-title mt-4'>Edit Attendee</h5>
          </Modal.Header>

          <div id='custom-content' className='white-popup-block'>
            <div className='card card-margin'>
              <div className='card-body'>
                <form className='forms-sample' onSubmit={this.handleEditSubmit}>
                  <div className='form-row'>
                    <div className='form-group col-md-6'>
                      <label for='exampleInputUsername12'>
                        First Name<span className='text-danger'>*</span>
                      </label>
                      <input
                        type='text'
                        className='form-control'
                        maxLength='50'
                        name='firstName'
                        placeholder='First Name'
                        value={this.state.firstName}
                        onChange={this.handleChange}
                      />
                      {firstNameErrorMsg ? (
                        <div className='error ml-0'>{firstNameErrorMsg}</div>
                      ) : null}
                    </div>
                    <div className='form-group  col-md-6'>
                      <label for='exampleInputUsername12'>
                        Last Name<span className='text-danger'>*</span>
                      </label>
                      <input
                        type='text'
                        className='form-control'
                        maxLength='50'
                        id='event-sub'
                        placeholder='Last Name'
                        name='lastName'
                        value={this.state.lastName}
                        onChange={this.handleChange}
                      />
                      {lastNameErrorMsg ? (
                        <div className='error ml-0'>{lastNameErrorMsg}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className='form-row'>
                    <div className='form-group col-md-6'>
                      <label for='exampleInputUsername12'>
                        Email<span className='text-danger'>*</span>
                      </label>
                      <input
                        type='text'
                        className='form-control'
                        disabled
                        id='event-name'
                        placeholder='Email'
                        name='email'
                        value={this.state.email}
                        maxLength='100'
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className='form-group  col-md-6'>
                      <label for='exampleInputUsername12'>Phone</label>
                      <ReactPhoneInput
                        className='form-control'
                        country={'us'}
                        value={phoneInput}
                        onChange={this.handleChangephone}
                      />
                      {showDangerHelp && (
                        <div className='error ml-0'>
                          This phone number is invalid
                        </div>
                      )}
                    </div>
                  </div>
                  <hr />
                  <div className='form-row'>
                    <div className='form-group col-md-4'>
                      <label for='exampleInputUsername12'>
                        Ticket Type<span className='text-danger'>*</span>
                      </label>
                      {this.props.ticketsList.length !== 0 &&
                      this.props.ticketsList ? (
                        <Select
                          disabled
                          allowClear={false}
                          className='cm-select-box'
                          onChange={this.handleTicketChange}
                          value={
                            this.state.ticketId
                              ? this.state.ticketId
                              : 'Select Ticket'
                          }
                        >
                          {!_.isEmpty(this.props.ticketsList)
                            ? this.props.ticketsList
                                .filter(e => e.quantity !== '0')
                                .map(item => (
                                  <Option value={item._id}>
                                    {item.ticketName}
                                  </Option>
                                ))
                            : ''}
                        </Select>
                      ) : (
                        <input
                          maxLength='100'
                          type='text'
                          className='form-control'
                          placeholder='Ticket'
                          name='quantity'
                          value={this.state.ticketEditName}
                          onChange={this.handleChange}
                          disabled
                        />
                      )}

                      {ticketIdErrorMsg ? (
                        <div className='error ml-0'>{ticketIdErrorMsg}</div>
                      ) : null}
                    </div>
                    <div className='form-group col-md-4'>
                      <label for='exampleInputUsername12'>
                        Price<span className='text-danger'>*</span>
                      </label>
                      {this.props.ticketsList.length !== 0 &&
                      this.props.ticketsList ? (
                        <Select
                          disabled
                          className='cm-select-box'
                          onChange={this.handleTicketChange}
                          name='gender'
                          value={
                            this.state.ticketId ? this.state.ticketId : 'Price'
                          }
                        >
                          {!_.isEmpty(this.props.ticketsList)
                            ? this.props.ticketsList.map(item => (
                                <Option value={item._id}>
                                  {item.price
                                    ? `$${Number(
                                        item.price * this.state.quantity
                                      ).toFixed(2)}`
                                    : 'Free'}
                                </Option>
                              ))
                            : ''}
                        </Select>
                      ) : (
                        <input
                          type='text'
                          className='form-control'
                          placeholder='Ticket'
                          name='quantity'
                          maxLength='100'
                          value={this.state.ticketPriceShow}
                          onChange={this.handleChange}
                          disabled
                        />
                      )}
                    </div>
                    <div className='form-group col-md-4'>
                      <label for='exampleInputUsername12'>
                        Quantity<span className='text-danger'>*</span>
                      </label>
                      <input
                        type='number'
                        className='form-control'
                        placeholder='Quantity'
                        id='time'
                        maxLength='100'
                        name='quantity'
                        value={this.state.quantity}
                        onChange={this.handleChange}
                        disabled
                      />
                      {quantityErrorMsg ? (
                        <div className='error ml-0'>{quantityErrorMsg}</div>
                      ) : null}
                    </div>
                  </div>

                  <hr />
                  <div className='form-group'>
                    <label className='font-weight-semibold' for='inputAddress'>
                      Address:
                    </label>
                    <PlacesAutocomplete
                      value={this.state.address}
                      onChange={this.handleChangeAddress}
                      onSelect={this.handleSelectAddress}
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading
                      }) => (
                        <div>
                          <input
                            {...getInputProps({
                              placeholder: 'Search Places',
                              className: 'location-search-input form-control'
                            })}
                            maxLength='100'
                          />
                          <div className='autocomplete-dropdown-container'>
                            {loading && <div>Loading...</div>}
                            {suggestions.map(suggestion => {
                              const className = suggestion.active
                                ? 'suggestion-item--active'
                                : 'suggestion-item'
                              // inline style for demonstration purpose
                              const style = suggestion.active
                                ? {
                                    backgroundColor: '#fafafa',
                                    cursor: 'pointer'
                                  }
                                : {
                                    backgroundColor: '#ffffff',
                                    cursor: 'pointer'
                                  }
                              return (
                                <div
                                  {...getSuggestionItemProps(suggestion, {
                                    className,
                                    style
                                  })}
                                >
                                  <span>{suggestion.description}</span>
                                </div>
                              )
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
                  </div>
                  <div className='form-group'>
                    <label className='font-weight-semibold' for='inputAddress'>
                      Address 2:
                    </label>
                    <input
                      maxLength='200'
                      type='text'
                      className='form-control'
                      id='inputCity'
                      placeholder='Address 2'
                      name='address2'
                      value={this.state.address2}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className='form-row'>
                    <div className='form-group col-md-4'>
                      <label className='font-weight-semibold' for='inputCity'>
                        City:
                      </label>
                      <input
                        maxLength='50'
                        type='text'
                        className='form-control'
                        id='inputCity'
                        placeholder='City'
                        disabled
                        name='city'
                        value={this.state.city}
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className='form-group col-md-4'>
                      <label className='font-weight-semibold' for='state'>
                        State:
                      </label>
                      <input
                        maxLength='50'
                        type='text'
                        className='form-control'
                        id='exampleOption14'
                        placeholder='State'
                        name='state'
                        disabled
                        value={this.state.state}
                        onChange={this.handleChange}
                      />
                    </div>

                    <div className='form-group col-md-4'>
                      <label className='font-weight-semibold' for='inputZip'>
                        Zip:
                      </label>
                      <input
                        maxLength='10'
                        type='text'
                        className='form-control'
                        id='inputZip'
                        placeholder='Zip'
                        name='zip'
                        disabled
                        value={this.state.zip}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                  <hr />

                  <div className='form-row'>
                    <div className='form-group col-md-6'>
                      <label for='exampleInputUsername12'>Gender</label>
                      <br />
                      <Select
                        className='cm-select-box'
                        onChange={e => this.handleEditGenderChange(e)}
                        name='gender'
                        value={
                          this.state.gender
                            ? genderValue(this.state.gender)
                            : 'Select Gender'
                        }
                      >
                        <Option value='1'>Male</Option>
                        <Option value='2'>Female</Option>
                        <Option value='3'>Other</Option>
                      </Select>
                    </div>
                    <div className='form-group col-md-6'>
                      <label for='exampleInputUsername12'>Birthdate</label>
                      <DatePicker
                        className='form-control'
                        disabledDate={this.disabledDate}
                        format={dateFormat}
                        allowClear={false}
                        value={this.state.dob ? moment(this.state.dob) : ''}
                        onChange={this.handleSelectDobChange}
                      />

                      {dobErrorMsg ? (
                        <div className='error ml-0'>{dobErrorMsg}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className='card-footer bg-white'>
                    <button
                      type='button'
                      className='btn btn-light'
                      onClick={this.closeModal}
                    >
                      Cancel
                    </button>
                    <button
                      type='submit'
                      className='btn btn-primary ml-2'
                      disabled={disabledBtn}
                    >
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          size='md'
          show={this.state.openCheckinPopup}
          onHide={this.closeModal}
          aria-labelledby='example-modal-sizes-title-sm'
        >
          <Modal.Header closeButton>
            <h5 className='card-title mt-4'>TICKETS</h5>
          </Modal.Header>
          <div id='custom-content' className='white-popup-block'>
            <div className='card card-margin'>
              <div className='card-body'>
                {this.state.attendeeTicketDetails.map((ticket, i) => {
                  return (
                    <div
                      style={{
                        display: 'flex',
                        padding: '10px 0px',
                        borderBottom: '1px solid #333',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                      }}
                    >
                      <div style={{ width: '200px' }}>
                        Token: {ticket.qrToken}
                      </div>
                      <div>{ticket.ticketStatus}</div>
                      <div>
                        {ticket.isVerified == 0 ? (
                          <div>
                            <button
                              type='button'
                              class='btn btn-sm btn-dark simple-ajax-popup-align-top'
                              onClick={() => {
                                this.checkInTicket(ticket, i)
                              }}
                            >
                              Check In
                            </button>
                          </div>
                        ) : (
                          <div>Ticket in use</div>
                        )}
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </Modal>
      </React.Fragment>
    )
  }
}

export default Attendee
