/* eslint-disable no-undef */
import React from 'react';
import { usersService } from '../../_services/usersService';
import ResponseFilter from '../../config/response-handler';
import { message } from 'antd';
import phone from 'phone';
import { isValidWebsiteUrl1, isValidWebsiteUrl2 } from '../../utils';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import ReactPhoneInput from 'react-phone-input-2';

class AccountSettingPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: '',
      workZip: '',
      workState: '',
      workCity: '',
      workAddress2: '',
      workAddress: '',
      shippingZip: '',
      shippingState: '',
      shippingCity: '',
      shippingAddress2: '',
      shippingAddress: '',
      website: '',
      company: '',
      jobtitle: '',
      dob: '',
      gender: '',
      zip: '',
      state: '',
      city: '',
      address2: '',
      address: '',
      lastName: '',
      firstName: '',
      phone: '',
      showDangerHelp: false,
      websiteErrorMsg: '',
      phoneInput: '',
      addressErrorMsg: '',
      zipErrorMsg: '',
      stateErrorMsg: '',
      cityErrorMsg: '',
      ownerErrorMsg: '',
      onboardingErrorMsg: '',
      payoutErrorMsg: '',
      onboardingEmail:'',
      promoterType:'',
      payoutName:'',
    };
  }

  componentDidMount() {
    if (localStorage.getItem('role') === 4) {
      this.props.history.push('/admin');
    }

    this.getUserDetails();
  }

  closeModal = () => {
    this.props.history.push('/events-feed');
    this.props.accountCloseModal();
    this.getUserDetails();
    this.setState({
      addressErrorMsg: '',
      zipErrorMsg: '',
      stateErrorMsg: '',
      cityErrorMsg: '',
    });
  };

  getUserDetails = () => {
    let obj = {
      userId: localStorage.getItem('userId'),
    };
    this.setState({ loader: true });

    usersService.userDetails(obj).then((res) => {
      this.setState({ loader: false });
      let { status, resData } = ResponseFilter(res);
      if (status) {
        let {
          name,
          phone,
          email,
          jobtitle,
          company,
          website,
          address,
          address2,
          city,
          state,
          zip,
          shippingAddress,
          shippingAddress2,
          shippingCity,
          shippingState,
          shippingZip,
          workAddress,
          workAddress2,
          workCity,
          workState,
        } = resData.result;

        this.setState({
          firstName: name ? name.split(' ')[0] : '',
          lastName: name ? name.split(' ').slice(1).join(' ') : '',
          phoneInput: phone,
          phone1: phone,
          firstName1: name ? name.split(' ')[0] : '',
          lastName1: name ? name.split(' ').slice(1).join(' ') : '',
          copyJobtitle: jobtitle,
          copyCompany: company,
          copyWebsite: website,
          email,
          showDangerHelp: '',
          firstNameErrorMsg: '',
          websiteErrorMsg: '',
          lastNameErrorMsg: '',
          company,
          website,
          jobtitle,
          address,
          address2,
          city,
          state,
          zip,
          shippingAddress,
          shippingAddress2,
          shippingCity,
          shippingState,
          shippingZip,
          workAddress,
          workAddress2,
          workCity,
          workState,

          copyAddress: address,
          copyAddress2: address2?address2:'',
          copyCity: city,
          copyState: state,
          copyZip: zip,
        });
      }
    });
  };
  handleChange = (e) =>
    this.setState({
      [e.target.name]: e.target.value,
      [e.target.name + 'ErrorMsg']: '',
      resMsg: '',
      resErrorMsg: '',
    });

  handleEditValidation = () => {
    let validate = true;
    let {
      firstName,
      lastName,
      website,
      address,
      city,
      state,
      zip,
      onboardingEmail,
      promoterType,
      payoutName,
    } = this.state;
    var nameRegex = /^[a-zA-Z ]{2,30}$/;
    var firstname1 = firstName.trim();
    var lastname1 = lastName.trim();
    const { phoneInput, allowLandline } = this.state;
    const result = phone(`+ ${phoneInput}`, '', allowLandline);

    if (result.length) {
      this.setState({
        validate: false,
        result,
        showDangerHelp: false,
        showSuccessHelp: true,
      });
    } else {
      validate = false;
      this.setState({
        validate: false,
        result,
        showDangerHelp: true,
        showSuccessHelp: false,
      });
    }
    if (website !== '') {
      if (!isValidWebsiteUrl1(website)) {
        validate = false;
        this.setState({
          websiteErrorMsg: 'Please enter  valid website url.',
        });
      }
      if (!isValidWebsiteUrl2(website)) {
        validate = false;
        this.setState({
          websiteErrorMsg: 'Please enter  valid website url.',
        });
      } else {
        this.setState({
          websiteErrorMsg: '',
        });
      }
    }
    if (!firstName.replace(/\s/g, '').length) {
      validate = false;
      this.setState({
        firstNameErrorMsg: 'Please enter your first name.',
      });
    }
    if (firstname1 === '' || firstname1 === undefined || firstname1 === null) {
      validate = false;
      this.setState({
        firstNameErrorMsg: 'Please enter your first name.',
      });
    } else if (!nameRegex.test(firstname1)) {
      validate = false;
      this.setState({
        firstNameErrorMsg: 'Please enter valid first name.',
      });
    } else if (firstname1.length < 2) {
      validate = false;
      this.setState({
        firstNameErrorMsg: 'Please enter valid first name.',
      });
    }

    if (!lastName.replace(/\s/g, '').length) {
      validate = false;
      this.setState({
        lastNameErrorMsg: 'Please enter your last name.',
      });
    }
    if (lastname1 === '' || lastname1 === undefined || lastname1 === null) {
      validate = false;
      this.setState({
        lastNameErrorMsg: 'Please enter your last name.',
      });
    } else if (!nameRegex.test(lastname1)) {
      validate = false;
      this.setState({
        lastNameErrorMsg: 'Please enter valid last name.',
      });
    } else if (lastname1.length < 2) {
      validate = false;
      this.setState({
        lastNameErrorMsg: 'Please enter valid last name.',
      });
    }

    if (address === '' || address === undefined) {
      validate = false;
      this.setState({
        addressErrorMsg: 'Please enter address.',
      });
    } else {
      this.setState({
        addressErrorMsg: '',
      });
    }
    if (city === '' || city === undefined) {
      validate = false;
      this.setState({
        cityErrorMsg: 'Please enter city.',
      });
    } else {
      this.setState({
        cityErrorMsg: '',
      });
    }
    if (state === '' || state === undefined) {
      validate = false;
      this.setState({
        stateErrorMsg: 'Please enter state.',
      });
    } else {
      this.setState({
        stateErrorMsg: '',
      });
    }
    if (zip === '' || zip === undefined) {
      validate = false;
      this.setState({
        zipErrorMsg: 'Please enter zip code.',
      });
    } else {
      this.setState({
        zipErrorMsg: '',
      });
    }
    if (onboardingEmail === '' || onboardingEmail === undefined) {
      validate = false;
      this.setState({
        onboardingErrorMsg: 'Please enter a valid email address.',
      });
    } else {
      this.setState({
        onboardingErrorMsg: '',
      });
    }
    if (promoterType === '' || promoterType === undefined) {
      validate = false;
      this.setState({
        ownerErrorMsg: 'Please fill out this field.',
      });
    } else {
      this.setState({
        ownerErrorMsg: '',
      });
    }
    if (payoutName === '' || payoutName === undefined) {
      validate = false;
      this.setState({
        payoutErrorMsg: 'Please fill out this field.',
      });
    } else {
      this.setState({
        payoutErrorMsg: '',
      });
    }
    
    return validate;
  };

  handleSubmitEdit = (event) => {
    event.preventDefault();
    let {
      website,
      company,
      jobtitle,
      phoneInput,
      address,
      address2,
      city,
      state,
      zip,
    } = this.state;

    if (this.handleEditValidation()) {
      var obj = {
        userId: localStorage.getItem('userId'),
        name: this.state.firstName.replace(/\s/g, '') + ' ' + this.state.lastName.replace(/\s/g, ''),
        phone: phoneInput,
        jobtitle: jobtitle,
        company: company,
        website: website,
        address: address,
        address2: address2,
        city: city,
        state: state,
        zip: zip,
      };

      const accessToken = localStorage.getItem('accessToken');
      this.setState({ disabledBtn: true });
      usersService.accountSettings(obj, accessToken).then((res) => {
        let { status, resData } = ResponseFilter(res);
        if (status) {
          this.props.history.push('/create-event');
          localStorage.setItem('isAccountDetailsExists', true);
          this.props.accountSubmitCloseModal();

          message.success('Your account details added successfully.');
        } else {
          this.props.accountSubmitCloseModal();
          this.setState({
            resErrorMsg: resData,
          });
        }
      });
    }
  };

  handleChangephone = (e) => {
    this.setState({
      phoneInput: e,
      showDangerHelp: false,
    });
  };
  handleSelectBillingAddress = (address) => {
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        let newaddress = address.split(',');
        this.setState({
          lng: latLng.lng,
          lat: latLng.lat,
          address: newaddress[0],
        });

        let Url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latLng.lat},${latLng.lng}&key=${process.env.REACT_APP_MAP_KEY}`;

        fetch(Url)
          .then((response) => response.json())
          .then((result) => {
            let zipcode;
            let state;
            let city;

            for (var i = 0; i < result.results.length; i++) {
              for (
                var j = 0;
                j < result.results[i].address_components.length;
                j++
              ) {
                for (
                  var k = 0;
                  k < result.results[i].address_components[j].types.length;
                  k++
                ) {
                  if (
                    result.results[i].address_components[j].types[k] ===
                    'postal_code'
                  ) {
                    zipcode =
                      result.results[i].address_components[j].short_name;

                    if (zipcode) {
                      this.setState({ zip: zipcode });
                    } else {
                      this.setState({ zip: '' });
                    }
                  }
                  if (
                    result.results[i].address_components[j].types[k] ===
                    'locality'
                  ) {
                    city = result.results[i].address_components[j].long_name;
                    if (city) {
                      this.setState({ city: city });
                    } else {
                      this.setState({ city: '' });
                    }
                  }
                  if (
                    result.results[i].address_components[j].types[k] ===
                    'administrative_area_level_1'
                  ) {
                    state = result.results[i].address_components[j].long_name;
                    if (zipcode) {
                      this.setState({ state: state });
                    } else {
                      this.setState({ state: '' });
                    }
                  }
                }
              }
            }
          });
      })
      .catch((error) => console.error('Error', error));
  };

  handleChangeBillingAddress = (address) => {
    if (address.length == 0) {
      this.setState({
        city: '',
        state: '',
        zip: '',
      });
    }
    this.setState({ address });
  };

  render() {
    let {
      website,
      company,
      jobtitle,
      zip,
      state,
      city,
      address2,
      phoneInput,
      firstNameErrorMsg,
      websiteErrorMsg,
      lastNameErrorMsg,
      emailErrorMsg,
      showDangerHelp,
      addressErrorMsg,
      zipErrorMsg,
      stateErrorMsg,
      cityErrorMsg,
      ownerErrorMsg,
      onboardingErrorMsg,
      payoutErrorMsg,
    } = this.state;

    return (
      <div className="card card-margin">
        <div className="card-header">
          <h5 className="card-title">Edit Account</h5>
        </div>
        <div className="card-body">
          <form className="forms-sample">
            <div className="form-row">
              <div className="form-group col-md-4">
                <label className="font-weight-semibold" for="name">
                  First Name<span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  placeholder="First Name"
                  name="firstName"
                  maxLength="50"
                  value={this.state.firstName}
                  onChange={this.handleChange}
                />
                {firstNameErrorMsg ? (
                  <div className="error ml-0">{firstNameErrorMsg}</div>
                ) : null}
              </div>
              <div className="form-group col-md-4">
                <label className="font-weight-semibold" for="name">
                  Last Name<span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  placeholder="Last Name"
                  name="lastName"
                  maxLength="50"
                  value={this.state.lastName}
                  onChange={this.handleChange}
                />
                {lastNameErrorMsg ? (
                  <div className="error ml-0">{lastNameErrorMsg}</div>
                ) : null}
              </div>
              <div className="form-group col-md-4">
                <label className="font-weight-semibold" for="email">
                  Email<span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  placeholder="Email"
                  name="email"
                  maxLength="100"
                  value={this.state.email}
                  onChange={this.handleChange}
                  disabled
                />
                {emailErrorMsg ? (
                  <div className="error ml-0">{emailErrorMsg}</div>
                ) : null}
              </div>
              <div className="form-group col-md-4">
                <label className="font-weight-semibold" for="phone">
                  Phone
                  <span className="text-danger">*</span>
                </label>
                <ReactPhoneInput
                  className="form-control"
                  country={'us'}
                  value={phoneInput}
                  onChange={this.handleChangephone}
                />
                {showDangerHelp && (
                  <div className="error ml-0">This phone number is invalid</div>
                )}
              </div>
              <div className="form-group col-md-4">
                <label className="font-weight-semibold" for="phone">
                  Job Title
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="phone"
                  placeholder="Job Title"
                  name="jobtitle"
                  value={jobtitle}
                  maxLength="70"
                  onChange={this.handleChange}
                />
              </div>
              <div className="form-group col-md-4">
                <label className="font-weight-semibold" for="phone">
                  Company
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="phone"
                  placeholder="Company"
                  name="company"
                  value={company}
                  maxLength="100"
                  onChange={this.handleChange}
                />
              </div>
              <div className="form-group col-md-4">
                <label className="font-weight-semibold" for="phone">
                  Website
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="phone"
                  placeholder="https://xyz.com" 
                  name="website"
                  value={website}
                  maxLength="200"
                  onChange={this.handleChange}
                />
                {websiteErrorMsg ? (
                  <div className="error ml-0">{websiteErrorMsg}</div>
                ) : null}
              </div>
            </div>
          </form>

          <div className="card-header">
            <h5 className="card-title">Address</h5>
          </div>
          <form id="example-form" action="#">
            <div>
              <section>
                <div className="form-group m-t-40">
                  <label className="font-weight-semibold" for="inputAddress">
                    Address
                    <span className="text-danger">*</span>
                  </label>
                  <PlacesAutocomplete
                    value={this.state.address}
                    onChange={this.handleChangeBillingAddress}
                    onSelect={this.handleSelectBillingAddress}
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => (
                      <div>
                        <input
                          {...getInputProps({
                            placeholder: 'Search Places',
                            className: 'location-search-input form-control',
                          })}
                          maxLength="100"
                        />
                        {addressErrorMsg ? (
                          <div className="error ml-0">{addressErrorMsg}</div>
                        ) : null}
                        <div className="autocomplete-dropdown-container">
                          {loading && <div>Loading...</div>}
                          {suggestions.map((suggestion) => {
                            const className = suggestion.active
                              ? 'suggestion-item--active'
                              : 'suggestion-item';

                            const style = suggestion.active
                              ? {
                                  backgroundColor: '#fafafa',
                                  cursor: 'pointer',
                                }
                              : {
                                  backgroundColor: '#ffffff',
                                  cursor: 'pointer',
                                };
                            return (
                              <div
                                {...getSuggestionItemProps(suggestion, {
                                  className,
                                  style,
                                })}
                              >
                                <span>{suggestion.description}</span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </PlacesAutocomplete>
                </div>
                <div className="form-group">
                  <label className="font-weight-semibold" for="inputAddress2">
                    Address 2
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="inputAddress2"
                    placeholder="Enter Address 2"
                    name="address2"
                    value={address2}
                    maxLength="100"
                    onChange={this.handleChange}
                  />
                </div>
                <div className="form-row">
                  <div className="form-group col-md-4">
                    <label className="font-weight-semibold" for="inputCity">
                      City
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="inputCity"
                      placeholder="City"
                      name="city"
                      disabled
                      value={city}
                      maxLength="50"
                      onChange={this.handleChange}
                    />
                    {cityErrorMsg ? (
                      <div className="error ml-0">{cityErrorMsg}</div>
                    ) : null}
                  </div>
                  <div className="form-group col-md-4">
                    <label className="font-weight-semibold" for="state">
                      State
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="inputCity"
                      placeholder="State"
                      name="state"
                      disabled
                      value={state}
                      maxLength="50"
                      onChange={this.handleChange}
                    />
                    {stateErrorMsg ? (
                      <div className="error ml-0">{stateErrorMsg}</div>
                    ) : null}
                  </div>
                  <div className="form-group col-md-4">
                    <label className="font-weight-semibold" for="inputZip">
                      Zip
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="inputCity"
                      placeholder="Zip"
                      name="zip"
                      value={zip}
                      disabled
                      maxLength="10"
                      onChange={this.handleChange}
                    />
                    {zipErrorMsg ? (
                      <div className="error ml-0">{zipErrorMsg}</div>
                    ) : null}
                  </div>
                </div>
              </section>
            </div>
          </form>
          <div className="card-header">
            <h5 className="card-title">PAYOUT INFORMATION</h5>
          </div>
          <form id="example-form" action="#">
            <div>
              <section>
                <div className="form-group m-t-40">
                  <label className="font-weight-semibold" for="promoterType">
                  Will ticket sales payouts be to a business or individual? 
                    <span className="text-danger">*</span>
                  </label>
                  <div style={{display:'flex',alignItems:"center"}} ><input style={{width:"14px",marginRight:"10px"}}
                    type="radio"
                    className="form-control"
                    id="promoterType"
                    name="promoterType"
                    value="Business"
                    onChange={this.handleChange}
                  />
                  Business</div>
                  <div style={{display:'flex',alignItems:"center"}} ><input style={{width:"14px",marginRight:"10px"}}
                    type="radio"
                    className="form-control"
                    id="promoterType"
                    name="promoterType"
                    value="Individual"
                    onChange={this.handleChange}

                  />
                  Individual</div>
                  {ownerErrorMsg? (
                      <div className="error ml-0">{ownerErrorMsg}</div>
                    ) : null}
                </div>
                <div className="form-group">
                  <label className="font-weight-semibold" for="payoutName">
                  What is the legal name of the business or individual?
                  <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="payoutName"
                    placeholder=""
                    name="payoutName"
                    maxLength="100"
                    onChange={this.handleChange}
                  />
                  {payoutErrorMsg? (
                    <div className="error ml-0">{payoutErrorMsg}</div>
                  ) : null}
                </div>
                <div className="form-group">
                  <label className="font-weight-semibold" for="onboardingEmail">
                  What email address would you like your onboarding email to be sent to?
                  <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="onboardingEmail"
                    placeholder=""
                    name="onboardingEmail"
                    maxLength="100"
                    onChange={this.handleChange}
                  />
                  {onboardingErrorMsg? (
                    <div className="error ml-0">{onboardingErrorMsg}</div>
                  ) : null}
                </div>
              </section>
            </div>
          </form>
        </div>

        <div className="card-footer bg-white">
          <div className="pull-right">
            <button className="btn btn-light" onClick={this.closeModal}>
              Cancel
            </button>
            <button
              type="submit"
              className="btn btn-primary mr-2"
              onClick={this.handleSubmitEdit}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default AccountSettingPopup;
