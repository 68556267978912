export default {
  api: {
    url: 'https://prodapi.farechild.com/api/v1/',
    mode: 'cors',
  },
  headers: {
    Authorization: 'Basic ZmFyZWNoaWxkX3Byb2Q6ZmFyZWNoaWxkQHByb2Q=',
    'Content-Type': 'application/json',
  },
};
