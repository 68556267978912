import React from 'react';
import TopBar from '../template/TopBar';
import Footer from '../template/Footer';
import SideNav from '../template/SideNav';

class Support extends React.Component {
  componentDidMount() {
    if (
      localStorage.getItem('isAccountDetailsExists') == 'false' &&
      localStorage.getItem('role') != 2
    ) {
      this.props.history.push('/account-settings');
    }
    
    if (localStorage.getItem('role') == 4) {
      this.props.history.push('/admin');
    }
    document.title = 'Support | Farechild';
  }

  render() {
    return (
      <div className="dark-sidebar">
        <div className="main-container">
          <div className="container-fluid page-body-wrapper">
            <nav className="navbar fixed-top">
              <div className="navbar-menu-container d-flex align-items-center justify-content-center">
                <div className="sub-heading">
                  <h4 className="page-title ">SUPPORT</h4>
                </div>
                <div className="text-center navbar-brand-container align-items-center justify-content-center">
                  <a className="brand-logo" href="/">
                    <img
                      src={require('../../assets/images/mobile-logo.png')}
                      alt=""
                      className="mb-3"
                    />
                  </a>
                </div>

                <TopBar {...this.props} />
              </div>
            </nav>
            <SideNav />
            <div className="main-panel">
              <div className="content-wrapper manage-event">
                <div className="card">
                  <div className="card-body p-0">
                    <div className="invoice">
                      <div className="invoice-header">
                        <div className="invoice-address">
                          <div className="invoice-from-address">
                            <h3 className="title">SUPPORT</h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Support;
