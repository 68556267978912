/* eslint-disable no-undef */
import React from 'react';
import _ from 'lodash';
import TopBar from '../template/TopBar';
import Footer from '../template/Footer';
import SideNav from '../template/SideNav';
import { usersService } from '../../_services/usersService';
import ResponseFilter from '../../config/response-handler';
import { Select } from 'antd';
import { Loader } from '../../config/Svgicon';
const { Option } = Select;

class Admin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      promotersList: [],
      loader: false,
      isAccountDetailsExists:''
    };
  }

  componentDidMount() {
    if(localStorage.getItem('isAdmin') !== 'true' &&  localStorage.getItem('role') == 3){
      if( localStorage.getItem('isAccountDetailsExists') === 'false' ){
        this.props.history.push('/account-settings'); 
      }
      else{
        this.props.history.push('/dashboard');
      }
     
    }
   else if(localStorage.getItem('isAdmin') !== 'true' &&  localStorage.getItem('role') == 1){
      if( localStorage.getItem('isAccountDetailsExists') === 'false' ){
        this.props.history.push('/account-settings'); 
      }
      else{
        this.props.history.push('/dashboard');
      }
     
    }
   else if(localStorage.getItem('isAdmin') !== 'true' &&  localStorage.getItem('role') == 2){
      this.props.history.push('/events-feed');
    }
   
    this.getPromotersList();
    document.title = 'Admin | Farechild';
  }

  promoterSelect = (e) => {
    this.getRefreshToken(e);
  };

  getRefreshToken = (e) => {
    let obj = `userId=${e}`;
    usersService.refreshToken(obj).then((res) => {
      this.setState({ loader: false });
      let { status, resData } = ResponseFilter(res);
      if (status) {
        localStorage.setItem('accessToken', resData.accessToken);
        this.getUserDetails(e);
      }
    });
  };
  getUserDetails = (e) => {
    let obj = {
      userId: e,
    };
    this.setState({ loader: true });

    usersService.userDetails(obj).then((res) => {
      this.setState({ loader: false });
      let { status, resData } = ResponseFilter(res);
      if (status) {
        let {
          name,
          profileImage,
          email,
          role,
          isPayoutExists,
          isAccountDetailsExists,
        } = resData.result;

        this.setState({
          name,
          profileImage,
          email,
          role,
          isPayoutExists,
          isAccountDetailsExists,
        });
        localStorage.setItem('isPayoutExists', this.state.isPayoutExists);
        localStorage.setItem(
          'isAccountDetailsExists',
          this.state.isAccountDetailsExists
        );
        localStorage.setItem('userId', e);
        localStorage.setItem('role', this.state.role);
        localStorage.setItem('profileImage', this.state.profileImage);
        localStorage.setItem('isAdmin', 'true');
        this.props.history.push({
          pathname: '/dashboard',
        });
      }
    });
  };

  getPromotersList = () => {
    let params = `sortBy=${'nameLower'}&sortOrder=${'desc'}`;
    const accessToken = localStorage.getItem('accessToken');
    this.setState({ loader: true });
    usersService.getPromoters(params, accessToken).then((res) => {
      let { status, resData } = ResponseFilter(res);
      if (status) {
        this.setState({
          loader: false,
          promotersList: resData.result,
          totalCount: resData.count,
        });
      } else {
        this.setState({ loader: false });
      }
    });
  };

  render() {
    return (
      <div className="dark-sidebar">
        {this.state.loader ? <Loader /> : null}
        <div className="main-container">
          <div className="container-fluid page-body-wrapper">
            <nav className="navbar fixed-top">
              <div className="navbar-menu-container d-flex align-items-center justify-content-center">
                <div className="sub-heading">
                  <h4 className="page-title ">Select Promoter</h4>
                </div>
                <div className="text-center navbar-brand-container align-items-center justify-content-center">
                  <a className="brand-logo" href="/">
                    <img
                      src={require('../../assets/images/mobile-logo.png')}
                      alt=""
                      className="mb-3"
                    />
                  </a>
                </div>

                <TopBar {...this.props} />
              </div>
            </nav>

            <SideNav />
            <div className="main-panel">
              <div className="content-wrapper manage-event">
                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6">
                  <div className="card card-margin">
                    <div className="card-body">
                      <div className="widget-13">
                        <Select
                          showSearch
                          placeholder="Select a promoter"
                          optionFilterProp="children"
                          className="form-control-select mb-5 mt-5"
                          onFocus={this.onFocus}
                          onBlur={this.onBlur}
                          onSearch={this.onSearch}
                          onChange={this.promoterSelect}
                        >
                          {!_.isEmpty(this.state.promotersList)
                            ? this.state.promotersList.map((item) => (
                                <Option value={item._id}>{item.name}</Option>
                              ))
                            : ''}
                        </Select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    );
  }
}

export default Admin;
