/* eslint-disable no-undef */
import React from 'react';
import { Link } from 'react-router-dom';
import _, { toLower } from 'lodash';
import { usersService } from '../../_services';
import EventTopBar from '../event/event-template/EventTopBar';
import EventFooter from '../event/event-template/EventFooter';
import { isValidEmail } from "../../utils";
import { Loader } from '../../config/Svgicon';

class ContactPromoter extends React.Component {
  constructor(props) {
    super(props);
    let searchParams = new URLSearchParams(window.location.search);
    this.state = {
      promoterInfo: {},
      eventId:searchParams.get('event_id'),
      errors:"",
      loading:false,
      success:false
    };
  }
  handleSubmit = (e) => {
    e.preventDefault();
    console.log(e.target);
    console.log(e.target.elements);
    let obj = {
      name : e.target.elements[0].value,
      email : e.target.elements[1].value,
      subject : e.target.elements[2].value,
      message : e.target.elements[3].value,
      eventId : e.target.elements[4].value,
    }
    console.log(obj);
    if (!obj.name||!obj.email||!obj.message||!obj.subject) {
      this.setState({ errors: "Please fill out all fields." });
      return;
    }

    if (!isValidEmail(String(obj.email.toLowerCase()))) {
      this.setState({ errors: "Please enter a valid email address." });
      return;
    }
    
    this.setState({ loading: true });
    console.log('pinging route');
    usersService.contactPromoter(obj).then((data) => {
      console.log(data);
      console.log(data.responseData);
      if(data.data&&data.data.responseData&&data.data.responseData.message){
        this.setState({ errors:"",loading: false,success:true },()=>{
          if(this.state.success){
            this.props.history.push({
              pathname: '/contact-success-message',
            });
          }
          console.log(this.state);
        });
      } else {
        this.setState({ errors:"There was an error. Please try again later.",loading: false },()=>{
          console.log(this.state);
        });
      }
    });
  };
  componentDidMount() {
    if(!this.state.eventId){
      this.props.history.push({
        pathname: '/',
      });
    }
  }
  render() {
    return (
    <React.Fragment>
      <EventTopBar {...this.props} />
      <div className="event-question-form">
      {this.state.loading ? <Loader /> : null}
        <form onSubmit={this.handleSubmit}> 
          <h4>Contact your Promoter</h4>
          <input name="name" placeholder="Name" type="text"/>
          <input name="emailAdress" placeholder="Email Address" type="text"/>
          <select name="subject" placeholder="Subject">
            <option disabled="true">Subject</option>
            <option value="Event Date, Time & Location" >Event Date, Time &amp; Location</option>
            <option value="Event Specifics">Event Specifics (Entry Age, Parking, Food/Drink, Etc.)</option>
          </select>
          <textarea placeholder="Type your question here" ></textarea>
          <input name="eventId" type="hidden" value={this.state.eventId}/>
            {this.state.errors ? (
              <div style={{marginBottom:'6px'}} className="error">
                {" "}
                {this.state.errors}{" "}
              </div>
            ) : null}
          <div class="text-center mb-3">
            <button disabled={this.state.loading} type="submit" class="btn btn-primary btn-block">SEND</button>
          </div>
        </form>
      </div>
      <EventFooter />
    </React.Fragment>
    )
  }
}
export default ContactPromoter;