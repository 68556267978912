import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { usersService } from "../../_services";

const VerifyEmail = (props) => {
  let token = props.match.params.token;
  const [verify, setVerify] = useState(false);
  useEffect(() => {
    let formData = {
      token,
    };
    usersService
      .resetEmail(formData)
      .then(() => {
        setVerify(true);
      })
      .catch(() => {
        setVerify(false);
      });
  }, []);

  return (
    <div>
      <div className="base-color-option-2">
        <div className="main-container">
          <div className="container-fluid page-body-wrapper full-page-wrapper">
            <div className="user-login login-v2">
              <div className="row no-gutters">
                <div className="col-lg-12">
                  <div className="logo-container text-center">
                    <Link className="brand-logo login-page" to="/">
                      {" "}
                      <img
                        src={require("../../assets/images/mobile-logo.png")}
                        alt=""
                        title=""
                      />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="justify-content-center align-self-center">
                <div className="center">
                  <div className="card-body cm_card card">
                    <div className="">
                      <div className="user-login-content">
                        <h3>
                          {verify
                            ? `Your email has been verified`
                            : ` Something went wrong`}
                        </h3>
                        <div className="text-center">
                          <div className="text-muted  text-uppercase">
                            <Link to="/login" className="link">
                              Back to home
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyEmail;
