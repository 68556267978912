import React from "react";
import { Link, BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import ScrollToTop from "./config/ScrollToTop";
import Login from "./component/auth/Login";
import Join from "./component/auth/Join";
import ForgotPassword from "./component/auth/ForgotPassword";
import ResetPassword from "./component/auth/ResetPassword";
import SuccessMessage from "./component/auth/SuccessMessage";
import ContactSuccessMessage from "./component/auth/ContactSuccessMessage";
import ResetSuccessMessage from "./component/auth/ResetSuccessMessage";
import VerifyEmail from "./component/auth/VerifyEmail";
import Home from "./component/pages/Home";
import Dashboard from "./component/pages/Dashboard";
import CreateEvent from "./component/pages/CreateEvent";
import EventCart from "./component/event/event-pages/EventCart";
import EventCheckout from "./component/event/event-pages/EventCheckout";
import ThankYou from "./component/event/event-pages/ThankYou";
import ManageMyEvent from "./component/pages/ManageMyEvent";
import EventsFeed from "./component/pages/EventsFeed";
import EditEvent from "./component/pages/EditEvent";
import Orders from "./component/pages/Orders";
import Reports from "./component/pages/Reports";
import PromoterProfile from "./component/pages/PromoterProfile";
import AccountSettings from "./component/setting-page/AccountSettings";
import TaxSettings from "./component/setting-page/TaxSettings";
import PayoutSettings from "./component/setting-page/PayoutSettings";
import ChangePassword from "./component/setting-page/ChangePassword";
import MyTickets from "./component/pages/MyTickets";
import Tickets from "./component/pages/Tickets";
import QrVerification from "./component/event/event-pages/QrVerification";
import ViewSuccessPage from "./component/eventEditPage/ViewSuccessPage";
import TermsServices from "./component/event/event-pages/TermsServices";
import Privacy from "./component/event/event-pages/Privacy";
import Support from "./component/pages/Support";
import Admin from "./component/pages/Admin";
import AdminReports from "./component/pages/AdminReports";
import AdminEvents from "./component/pages/AdminEvents";
import AdminUsers from "./component/pages/AdminUsers"
import ContactPromoter from "./component/pages/ContactPromoter";
import AfterEventUpdate from "./component/pages/AfterEventUpdate";
import EventViewPage from "./component/event/event-pages/EventViewPage";

function Page() {
  return (
    <div className="login_wrapper d-flex align-items-center">
      <div className="container cm_container bg_white d-flex align-items-center">
        <div className="page_content text-center">
          <h4 className=" cm_heading mb-4">404</h4>
          <p className="mb-5">Page not found</p>
          <div>
            <Link
              to="/"
              className="btn btn-back btn-outline-brand border-brand font-size-14 font-weight-bold pl-4 pr-4 pt-2 pb-2 text-brand"
            >
              <i class="fas fa-arrow-left mr-2"></i>
              Back to Homepage
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      localStorage.getItem("accessToken") ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
      )
    }
  />
);

class Routers extends React.Component {
  render() {
    return (
      <BrowserRouter>
        <ScrollToTop />
        <Switch>
          <PrivateRoute path="/dashboard" component={Dashboard} />
          <PrivateRoute exact path="/create-event" component={CreateEvent} />
          <PrivateRoute exact path="/manage-event" component={ManageMyEvent} />
          <PrivateRoute
            exact
            path="/manage-event/edit-event/:id"
            component={EditEvent}
          />
          <PrivateRoute exact path="/orders" component={Orders} />
          <PrivateRoute exact path="/reports" component={Reports} />
          <PrivateRoute
            exact
            path="/promoter-profile"
            component={PromoterProfile}
          />
             <PrivateRoute
            exact
            path="/promoter-profile/:id"
            component={PromoterProfile}
          />
          <PrivateRoute
            exact
            path="/account-settings"
            component={AccountSettings}
          />
          <PrivateRoute
            exact
            path="/account-settings/*"
            component={AccountSettings}
          />
          <PrivateRoute
            exact
            path="/payout-settings"
            component={PayoutSettings}
          />
          <PrivateRoute exact path="/tax-settings" component={TaxSettings} />
          <PrivateRoute
            exact
            path="/change-password"
            component={ChangePassword}
          />
          <PrivateRoute
            exact
            path="/create-event/:id"
            component={CreateEvent}
          />
          <PrivateRoute exact path="/support" component={Support} />
          <PrivateRoute
            exact
            path="/success-page/:id"
            component={ViewSuccessPage}
          />
          <PrivateRoute exact path="/events-feed" component={EventsFeed} />
          <PrivateRoute exact path="/my-tickets" component={MyTickets} />
          <PrivateRoute
            exact
            path="/my-tickets/tickets/:id/:ticketId"
            component={Tickets}
          />
          <PrivateRoute exact path="/admin" component={Admin} />
          <PrivateRoute exact path="/admin-reports" component={AdminReports} />
          <PrivateRoute exact path="/admin-events" component={AdminEvents} />
          <PrivateRoute exact path="/admin-users" component={AdminUsers} />

          <PrivateRoute
            exact
            path="/manage-event/after-event-update/:id"
            component={AfterEventUpdate}
          />

          <Route exact path="/event/:id/:name" component={EventViewPage} />

          {/* <Route exact path="/image-share" component={ImageShare} /> */}
          <Route path="/verify/:token" component={VerifyEmail} />
          <Route exact path="/cart/:id" component={EventCart} />
          <Route path="/checkout/:id" component={EventCheckout} />
          <Route path="/thank-you/:id" component={ThankYou} />
          <Route path="/qrverificaion/:qrToken" component={QrVerification} />
          <Route path="/login" component={Login} />
          <Route path="/forgot-password" component={ForgotPassword} />
          <Route path="/reset-password/:token" component={ResetPassword} />
          <Route path="/success-message" component={SuccessMessage} />
          <Route path="/contact-success-message" component={ContactSuccessMessage} />
          <Route path="/reset-successfully" component={ResetSuccessMessage} />
          <Route path="/join" component={Join} />
          <Route exact path="/terms" component={TermsServices} />
          <Route exact path="/privacy" component={Privacy} />
          <Route exact path="/event-contact" component={ContactPromoter} />
          <Route path="/" component={Home} />
          <Route path="/404">
            <Page />
          </Route>
          <Redirect from="*" to="/404" />
        </Switch>
      </BrowserRouter>
    );
  }
}

export default Routers;
