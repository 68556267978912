/* eslint-disable no-undef */
import React from 'react';
import { Link } from 'react-router-dom';
import { usersActions } from '../../_actions';
import axios from 'axios';
import { isValidEmail } from '../../utils';
import { EyeIcon, Loader } from '../../config/Svgicon';
import { connect } from 'react-redux';
import FacebookLogin from 'react-facebook-login';

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errorCode: '',
      loader: false,
      email: '',
      password: '',
      emailErrorMsg: '',
      passwordErrorMsg: '',
      errors: '',
      showPassword: false,
      redirect: false,
    };
  }

  componentDidMount() {
    if (localStorage.getItem('accessToken')) {
      this.props.history.push('/');
    }
  }

  handleShowPassword = () =>
    this.setState({ showPassword: !this.state.showPassword });

  responseFacebook = (response) => {
    this.setState({ loader: true });

    let obj = {
      name: response.name,
      email: response.email,
      socialID: response.userID,
      socialType: 1,
      profileImage: response.picture ? response.picture.data.url : '',
    };

    let { dispatch } = this.props;
    dispatch(usersActions.signupWithFacebook(obj)).then((data) => {
      if (data.data.statusCode === 1) {
        let accessToken = data.data.responseData.accessToken;
        let role = data.data.responseData.userProfile.role;
        this.setState({ loader: false });

        localStorage.setItem(
          'userEmail',
          data.data.responseData.userProfile.email
        );
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('role', role);
        localStorage.setItem(
          'isPayoutExists',
          data.data.responseData.userProfile.isPayoutExists
        );
        localStorage.setItem(
          'isAccountDetailsExists',
          data.data.responseData.userProfile.isAccountDetailsExists
        );
        localStorage.setItem(
          'profileImage',
          data.data.responseData.userProfile.profileImage !== 'undefined'
            ? data.data.responseData.userProfile.profileImage
            : ''
        );
        localStorage.setItem('userId', data.data.responseData.userProfile._id);
        if (
          localStorage.getItem('isAccountDetailsExists') == false &&
          localStorage.getItem('role') != 2 &&
          localStorage.getItem('role') != 4
        ) {
          this.props.history.push('/account-settings');
        } else {
          if (localStorage.getItem('QRcode')) {
            this.props.history.push(
              `/qrverificaion/${localStorage.getItem('QRcode')}`
            );
          } else {
            this.props.history.push(
              `/${
                data.data.responseData.userProfile.role == 4
                  ? 'admin'
                  : data.data.responseData.userProfile.role == 2
                  ? 'events-feed'
                  : 'dashboard'
              }`
            );
          }
        }
      } else {
        this.setState({ loader: false });
        this.setState({ errors: data.data.error.responseMessage });
      }
    });
  };

  handleChange = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        [e.target.name + 'ErrorMsg']: '',
        errorMessage: '',
      },
      () => this.setState({ errors: '' })
    );
  };

  handleValidation = () => {
    let validate = true;
    let { email, password } = this.state;
    if (email === '' || email === undefined) {
      validate = false;
      this.setState({
        emailErrorMsg: 'Please enter your email address.',
      });
    } else if (!isValidEmail(String(email.toLowerCase()))) {
      validate = false;
      this.setState({ emailErrorMsg: 'Please enter a valid email address.' });
    } else {
      this.setState({
        emailErrorMsg: '',
      });
    }
    if (password.trim() === '' || password.trim() === undefined) {
      validate = false;
      this.setState({
        passwordErrorMsg: 'Please enter password.',
      });
    } else if (password.trim().length <= 5) {
      validate = false;
      this.setState({
        passwordErrorMsg: ' Please enter minimum 6 characters',
      });
    } else {
      this.setState({
        passwordErrorMsg: '',
      });
    }

    return validate;
  };

  handleSubmit = (e) => {
    e.preventDefault();
    let { email, password } = this.state;
    this.setState({ redirect: false, errors: '' });
    let obj = {
      email: email.toLowerCase(),
      password: password,
    };

    let { dispatch } = this.props;
    if (this.handleValidation()) {
      this.setState({ redirect: false, loader: true });
      dispatch(usersActions.login(obj)).then((data) => {
        console.log("LOGIN DATA",data);
        if (data.data.statusCode === 1) {
          this.setState({ loader: false });
          let accessToken = data.data.responseData.accessToken;
          let role = data.data.responseData.userProfile.role;
          localStorage.setItem('accessToken', accessToken);
          localStorage.setItem('role', role);
          localStorage.setItem(
            'isPayoutExists',
            data.data.responseData.userProfile.isPayoutExists
          );
          localStorage.setItem(
            'isAccountDetailsExists',
            data.data.responseData.userProfile.isAccountDetailsExists
          );
          localStorage.setItem(
            'profileImage',
            data.data.responseData.userProfile.profileImage !== 'undefined'
              ? data.data.responseData.userProfile.profileImage
              : ''
          );
          localStorage.setItem(
            'userId',
            data.data.responseData.userProfile._id
          );
          localStorage.setItem(
            'userEmail',
            data.data.responseData.userProfile.email
          );
          localStorage.setItem('promoterId', data.data.responseData.userProfile.promoterId);
          axios.defaults.headers.common['accessToken'] = `${accessToken}`;

          if (
            localStorage.getItem('isAccountDetailsExists') == false &&
            localStorage.getItem('role') != 2 &&
            localStorage.getItem('role') != 4
          ) {
            this.props.history.push('/account-settings');
          } else {
            if (localStorage.getItem('QRcode')) {
              this.props.history.push(
                `/qrverificaion/${localStorage.getItem('QRcode')}`
              );
            } else {
              this.props.history.push(
                `/${
                  data.data.responseData.userProfile.role == 4
                    ? 'admin'
                    : data.data.responseData.userProfile.role == 2
                    ? 'events-feed'
                    : 'dashboard'
                }`
              );
            }
          }
        } else {
          this.setState({
            loader: false,
            errors: data.data.error.responseMessage,
            errorCode: data.data.error.errorCode,
          });
        }
      });
    }
  };

  render() {
    let {
      errors,
      loader,
      emailErrorMsg,
      passwordErrorMsg,
      showPassword,
      errorCode,
    } = this.state;

    return (
      <div className="base-color-option-2">
        {loader ? <Loader /> : null}
        <div className="main-container">
          <div className="container-fluid page-body-wrapper full-page-wrapper">
            <div className="user-login login-v2">
              <div className="row no-gutters">
                <div className="col-lg-12">
                  <div className="logo-container text-center">
                    <Link className="brand-logo login-page" to="/">
                      {' '}
                      <img
                        src={require('../../assets/images/mobile-logo.png')}
                        alt=""
                        title=""
                      />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="row no-gutters">
                <div className="col-6 col-sm-10 col-lg-5 mx-auto" style={{maxWidth:'100%',}}>
                  <div className="card mb-5 mb-md-0">
                    <div className="col-sm-12 col-lg-12">
                      <div className="user-login-content">
                        <h4 className="login-title cm_bold">Sign In</h4>

                        <form
                          className="needs-validation"
                          onSubmit={this.handleSubmit}
                        >
                          <div className="form-group inner-addon">
                            <input
                              type="text"
                              autoComplete="off"
                              className="form-control"
                              maxLength="100"
                              name="email"
                              value={this.state.email}
                              onChange={this.handleChange}
                              placeholder="Email Address"
                            />

                            {emailErrorMsg ? (
                              <div className="error">{emailErrorMsg}</div>
                            ) : null}
                          </div>
                          <div className="form-group inner-addon">
                            <span
                              onClick={this.handleShowPassword}
                              className={`eye_icon ${
                                this.state.showPassword ? '' : 'slesh'
                              }`}
                            >
                              <EyeIcon />
                            </span>
                            <input
                              type={`${showPassword ? 'text' : 'password'}`}
                              name="password"
                              maxLength="50"
                              autoComplete="off"
                              value={this.state.password}
                              className="form-control"
                              id="exampleInputPassword1"
                              onChange={this.handleChange}
                              placeholder="Password"
                            />
                            {errorCode === 9 ? (
                              <div className="error">{errors}</div>
                            ) : null}
                            {errorCode === 4 ? (
                              <div className="error">{errors}</div>
                            ) : null}
                            {passwordErrorMsg ? (
                              <div className="error">{passwordErrorMsg}</div>
                            ) : null}
                            {errorCode !== 9 && errorCode !== 4 && errors ? (
                              <div className="error"> {errors} </div>
                            ) : null}
                          </div>
                          <div className="text-center mb-3">
                            <button
                              type="submit"
                              className="btn btn-primary btn-block"
                            >
                              SIGN IN
                            </button>
                          </div>

                          <div className="row">
                            <div className=" col-6 text-left">
                              <div className="text-muted  text-uppercase">
                                Don't have an account yet?
                                <Link to="/join" className="link">
                                  {' '}
                                  Join
                                </Link>{' '}
                                now
                              </div>
                            </div>
                            <div className="col-6 text-right">
                              <div className="text-muted  text-uppercase">
                                <Link to="/forgot-password" className="link">
                                  Forgot Password?
                                </Link>
                              </div>
                            </div>
                          </div>
                        </form>
                        <div className="separator">
                          <span></span>
                          <span className="text-muted">OR</span>
                          <span></span>
                        </div>
                        <div className="user-login-footer mb-3 row">
                          <div className="col-12">
                            <FacebookLogin
                              textButton={'SIGN IN WITH FACEBOOK'}
                              tag="button"
                              disableMobileRedirect={true}
                              appId={process.env.REACT_APP_API_KEY}
                              autoLoad={false}
                              size="metro"
                              fields="name,email,picture.width(800)"
                              callback={this.responseFacebook}
                              cssClass="btn btn-facebook btn block fb-btn"
                              icon="fa-facebook-official mr-1"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { props, login, user } = state;
  return {
    props,
    login,
    user,
  };
}

export default connect(mapStateToProps)(Login);
