/* eslint-disable no-undef */
import React from 'react';
import { connect } from 'react-redux';
import Dropdown from 'react-bootstrap/Dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-regular-svg-icons';
import { usersActions } from '../../_actions';
import ResponseFilter from '../../config/response-handler';
import { Loader, LogOut, TopBarHome } from '../../config/Svgicon';
import Modal from 'react-bootstrap/Modal';
import Cookies from 'js-cookie';
const handleremoveMenuClass = () => document.body.classList.remove('menu_open');
class TopBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      openLogoutPopup: false,
      logoutdeletePopup: false,
    };
    this.redirectTo = this.redirectTo.bind(this);
  }
  redirectTo() {
    this.props.history.push({
      pathname: '/',
    });
  }

  handleLogout = () => {
    this.setState({ logoutdeletePopup: true });
  };
  handleOpenLogoutPopup = () =>
    this.setState({ openLogoutPopup: !this.state.openLogoutPopup });
  closeModal = () =>
    this.setState({
      openLogoutPopup: false,
    });

  goToHomePage = () => {
    this.props.history.push({
      pathname: '/',
    });
  };

  onLogoutSubmit = async () => {
    this.setState({ loader: true });
    let token = await localStorage.getItem('accessToken');
    let { dispatch } = this.props;
    dispatch(usersActions.logout(token)).then((res) => {
      ResponseFilter(res);
      if (res.data.statusCode === 1) {
        this.setState({ loader: false });
        localStorage.removeItem('accessToken');
        localStorage.removeItem('role');
        localStorage.removeItem('isPayoutExists');
        localStorage.removeItem('isAccountDetailsExists');
        localStorage.removeItem('profileImage');
        localStorage.removeItem('userId');
        localStorage.clear();
        localStorage.removeItem('quantTotal');
        localStorage.removeItem('selectObjList');
        localStorage.removeItem('subTotal');
        localStorage.removeItem('eventID');
        localStorage.removeItem('orderTicketList');
        localStorage.removeItem('PerTicketFee');
        localStorage.removeItem('CCFees');
        localStorage.removeItem('TransactionFee');
        localStorage.removeItem('perTicketFeesValue');
        localStorage.removeItem('ccFeesValue');
        localStorage.removeItem('transactionFeesValue');
        localStorage.removeItem('checkoutCustomFields');
        localStorage.removeItem('checkoutFields');
        this.props.history.push('/');
      } else {
        this.setState({ loader: false });
      }
    });
  };

  handleCloseModal = () => {
    this.setState({ logoutdeletePopup: false });
  };
  
  toggleMenuClass = () => document.body.classList.toggle('menu_open');

  render() {
    let profileImage = localStorage.getItem('profileImage');
    let { loader, openLogoutPopup } = this.state;
    return (
      <div className="navbar-nav navbar-nav-right">
        {loader ? <Loader /> : null}
        <div className="mobile_trigger">
          <li className="nav-item mobile-sidebar">
            <button
              className="nav-link navbar-toggler navbar-toggler-right align-self-center"
              data-toggle="lgy-sidebar"
              type="button"
            >
              <i className="fa fa-align-right" aria-hidden="true"></i>
              <span onClick={this.toggleMenuClass} />
            </button>
          </li>
        </div>

        <Dropdown className="nav-item nav-profile dropdown">
          <Dropdown.Toggle className="nav-link dropdown-toggle">
            <li className="nav-item">
              <button
                className="nav-link navbar-toggler navbar-toggler-right align-self-center"
                data-toggle="lgy-sidebar"
                type="button"
              >
                {profileImage ? (
                  <img
                    src={profileImage ? profileImage : ''}
                    alt="Event Promoter"
                    className="user-img"
                  />
                ) : (
                  <FontAwesomeIcon
                    className="far fa-user-circle fa-2x"
                    icon={faUserCircle}
                  />
                )}
              </button>
            </li>
          </Dropdown.Toggle>

          <Dropdown.Menu className="dropdown-menu nav-user-container animated">
            <li className="dropdown-item">
              <Dropdown.Item
                className="dropdown-link"
                onClick={this.goToHomePage}
              >
                <TopBarHome />
                Home Page
              </Dropdown.Item>
            </li>
            <li className="dropdown-item">
              <Dropdown.Item
                className="dropdown-link"
                onClick={this.handleOpenLogoutPopup}
              >
                <LogOut /> Sign Out
              </Dropdown.Item>
            </li>
          </Dropdown.Menu>
        </Dropdown>

        <Modal
          size="md"
          show={openLogoutPopup}
          onHide={this.closeModal}
          aria-labelledby="example-modal-sizes-title-sm"
        >
          <Modal.Header closeButton></Modal.Header>
          <div className="cm_modal">
            <div className="modal-body">
              <h4 className="mb-4">Are you sure you want to Sign out?</h4>
              <br />

              <div className="bg-white pull-right mb-4">
                <button className="btn btn-light" onClick={this.closeModal}>
                  No
                </button>
                <button
                  type="submit"
                  className="btn btn-primary ml-2"
                  onClick={this.onLogoutSubmit}
                >
                  Yes
                </button>
              </div>
              <br />
              <br />
            </div>
          </div>
        </Modal>

        <div onClick={handleremoveMenuClass} className="menu_overlay" />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { props, user } = state;
  return {
    props,
    user,
  };
}
export default connect(mapStateToProps)(TopBar);
