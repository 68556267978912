/* eslint-disable no-undef */
import React from 'react';
import { Delete } from '../../config/Svgicon';
import _, { toNumber } from 'lodash';
import ReactSelect from 'react-select';
import { Plus2, Plus } from '../../config/Svgicon';
import Modal from 'react-bootstrap/Modal';
import { usersService } from '../../_services/usersService';
import ResponseFilter from '../../config/response-handler';
import { message, Select, Empty } from 'antd';

class Coupon extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      relatedEventList: [],
      selectedOption: null,
      eventId: this.props.eventId,
      selectedItems: [],
      List: [],
      sortOrder: 'asc',
      sortBy: 'created',
      page: 1,
      loader: false,
      openEditPopup: false,
      openAddPopup: false,
      openDeletePopup: false,
      couponDetails: [
        {
          discount: '',
          couponName: '',
          quantity: '',
          discountType: '',
          selectedTickets: [],
        },
      ],
      couponSort:true,
      disabledBtn: false,
      discountType: '',
      discount: '',
      couponName: '',
      quantity: '',
      couponId: '',
    };
  }

  componentDidMount() {
    this.getCouponList();
  }

  closeModal = () => {
    this.setState({
      openEditPopup: false,
      openAddPopup: false,
      openDeletePopup: false,
      couponDetails: [
        {
          discount: '',
          couponName: '',
          quantity: '',
          discountType: '',
          selectedTickets: [],
        },
      ],
      disabledBtn: false,
      discountType: '',
      discount: '',
      couponName: '',
      quantity: '',
      couponId: '',
      inputErrorMsg: '',
      selectedItems: '',
    });
  };
  handleOpenAddPopup = () =>
    this.setState({ openAddPopup: !this.state.openAddPopup });
  handleOpenDeletePopup = (couponId) =>
    this.setState({ openDeletePopup: !this.state.openDeletePopup, couponId });

  handleOpenEditPopup = ({
    couponName,
    selectedTickets,
    quantity,
    discountType,
    discount,
    _id,
  }) =>
    this.setState({
      openEditPopup: !this.state.openEditPopup,
      couponName,
      selectedItems: selectedTickets,
      quantity,
      discountType,
      discount,
      couponId: _id,
    });
  numberInputValidation = (e) => {
    if (
      !(
        (e.keyCode > 95 && e.keyCode < 106) ||
        (e.keyCode > 47 && e.keyCode < 58) ||
        e.keyCode === 8
      )
    ) {
      e.preventDefault();
    }
  };

  handleChangeInput(i, e) {
    const { name } = e.target;
    let couponDetails = [...this.state.couponDetails];

    if (name == 'discount') {
      couponDetails[i].selectedTickets = [];
    }
    var checkNumber = /^\d*\.?\d*$/i.test(e.target.value);
    if (e.target.type === 'number') {
      if (checkNumber === false || checkNumber === 'false') {
        e.preventDefault();
      }
      var value = e.target.value.replace(/\+|-/gi, '');
    } else if (name == 'couponName') {
      var value = e.target.value.replace(/\s/g, '');
    } else {
      var { value } = e.target;
    }

    couponDetails[i] = {
      ...couponDetails[i],
      [name]: value,
    };
    this.setState({
      couponDetails,
      [e.target.name + 'ErrorMsg']: '',
      inputErrorMsg: '',
    });
  }

  handleChangeDiscountType(i, e) {
    let { couponDetails } = this.state;
    couponDetails[i].discount = '';
    if (e == 2) {
      couponDetails[i].discountType = e;
      couponDetails[i].selectedTickets = [];
      this.setState({ couponDetails, inputErrorMsg: '' });
    } else {
      couponDetails[i].discountType = e;
      this.setState({ couponDetails, inputErrorMsg: '' });
    }
  }

  handleChange = (e) => {
    const { name } = e.target;
    var checkNumber = /^\d*\.?\d*$/i.test(e.target.value);
    if (e.target.type === 'number') {
      if (checkNumber === false || checkNumber === 'false') {
        e.preventDefault();
      }
      var value = e.target.value.replace(/\+|-/gi, '');
    } else if (name == 'couponName') {
      var value = e.target.value.replace(/\s/g, '');
    } else {
      var { value } = e.target;
    }

    this.setState({
      [name]: value,
      [e.target.name + 'ErrorMsg']: '',
      inputErrorMsg: '',
    });
  };

  handleEditDiscountType = (e) => {
    this.setState({
      discount: '',
    });
    if (e == 2) {
      this.setState({
        selectedItems: [],
        discountType: e,
        inputErrorMsg: '',
      });
    } else {
      this.setState({
        discountType: e,
        inputErrorMsg: '',
      });
    }
  };

  addClick() {
    this.setState((prevState) => ({
      couponDetails: [
        ...prevState.couponDetails,
        {
          discount: '',
          couponName: '',
          quantity: '',
          discountType: '',
          selectedTickets: [],
        },
      ],
    }));
  }
  removeClick(i) {
    let couponDetails = [...this.state.couponDetails];
    couponDetails.splice(i, 1);
    this.setState({ couponDetails });
  }

  handleValidation() {
    var isValid = true;
    let { couponDetails } = this.state;

    couponDetails.map((item, index) => {
      if (couponDetails[index].discountType === 1) {
        if (Number(couponDetails[index].discount) > 100) {
          isValid = false;
          this.setState({
            inputErrorMsg: 'Discount percentage must be between 1-100%.',
          });
        }
      }
      if (Number(couponDetails[index].discount) == 0) {
        isValid = false;
        this.setState({
          inputErrorMsg: 'Discount must be greater than 0.',
        });
      }
      if (
        couponDetails[index].selectedTickets.length === '' ||
        couponDetails[index].selectedTickets.length === 0
      ) {
        isValid = false;
        this.setState({
          inputErrorMsg: 'Please fill all the mandatory fields.',
        });
      }
      if (
        couponDetails[index].couponName === '' ||
        couponDetails[index].couponName === undefined
      ) {
        isValid = false;
        this.setState({
          inputErrorMsg: 'Please fill all the mandatory fields.',
        });
      }

      if (
        couponDetails[index].quantity === '' ||
        couponDetails[index].quantity === undefined ||
        couponDetails[index].quantity === '0'
      ) {
        isValid = false;
        this.setState({
          inputErrorMsg: 'Please fill all the mandatory fields.',
        });
      }

      if (
        couponDetails[index].discount === '' ||
        couponDetails[index].discount === undefined
      ) {
        isValid = false;
        this.setState({
          inputErrorMsg: 'Please fill all the mandatory fields.',
        });
      }
      if (
        couponDetails[index].discountType === '' ||
        couponDetails[index].discountType === undefined
      ) {
        isValid = false;
        this.setState({
          inputErrorMsg: 'Please fill all the mandatory fields.',
        });
      }
    });
    return isValid;
  }
  handlevalidateTickett = (e) => {
    let relatedEventList = [];
    let eventsData = 0;

    let eventsList = this.props.ticketsList;
    for (var l = 0; l < eventsList.length; l++) {
      eventsData = eventsList[l];
      if (this.state.selectedItems.includes(eventsData._id)) {
        relatedEventList.push(eventsData.price);
      }
      this.setState({ relatedEventList: relatedEventList });
    }
  };
  handleEditValidetion = () => {
    this.handlevalidateTickett();
    let eventsData;
    let eventsList = this.state.relatedEventList;
    let validate = true;
    let { couponName, quantity, discountType, discount, selectedItems } =
      this.state;
    if (couponName === '' || couponName === undefined) {
      validate = false;
      this.setState({
        inputErrorMsg: 'Please fill all the mandatory fields.',
      });
    }
    if (selectedItems.length === '' || selectedItems.length === 0) {
      validate = false;
      this.setState({
        inputErrorMsg: 'Please fill all the mandatory fields.',
      });
    }
    if (quantity === '' || quantity === undefined || quantity === '0') {
      validate = false;
      this.setState({
        inputErrorMsg: 'Please fill all the mandatory fields.',
      });
    }

    if (discountType === '' || discountType === undefined) {
      validate = false;
      this.setState({
        inputErrorMsg: 'Please fill all the mandatory fields.',
      });
    }

    if (discount === '' || discount === undefined) {
      validate = false;
      this.setState({
        inputErrorMsg: 'Please fill all the mandatory fields.',
      });
    }
    if (discountType === 1) {
      if (Number(discount) > 100) {
        validate = false;
        this.setState({
          inputErrorMsg: 'Discount percentage must be between 1-100%.',
        });
      }
    }
    if (Number(discount) == 0) {
      validate = false;
      this.setState({
        inputErrorMsg: 'Discount must be greater than 0.',
      });
    }

    if (discountType === 2) {
      for (var l = 0; l < eventsList.length; l++) {
        eventsData = eventsList[l];

        if (eventsData < discount) {
          validate = false;
          this.setState({
            inputErrorMsg:
              'Please select valid tickets(Ticket price should be less than discount amount).',
          });
        }
      }
    }
    return validate;
  };

  getCouponList = () => {
    let { search, page, sortOrder, sortBy } = this.state;
    let obj = {
      eventId: this.props.eventId,
      search: search,
      sortOrder: sortOrder,
      sortBy: sortBy,
      page: page - 1,
    };
    this.setState({ loader: true });
    usersService.couponList(obj).then((res) => {
      let { status, resData } = ResponseFilter(res);
      if (status) {
        this.setState({ loader: false });
        let fullList = resData.result;
        fullList.sort(function(a, b) {
          var textA = a.couponName.toUpperCase();
          var textB = b.couponName.toUpperCase();
          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });
        
        
        let couponSort = this.state.couponSort;
        if(!couponSort){
          fullList.reverse();
        }
        this.setState({
          List: fullList,
          totalCount: resData.count,
        });
      } else {
        this.setState({ loader: false });
      }
    });
  };
  sortCouponsByName = () => {
      
      let couponList = this.state.List;
      
      couponList.sort(function(a, b) {
        var textA = a.couponName.toUpperCase();
        var textB = b.couponName.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });
      let couponSort = !this.state.couponSort;
      if(!couponSort){
        couponList.reverse();
      }
      
      
      this.setState({
        List:couponList,
        couponSort:couponSort
      });
  }
  handleAddSubmit = (event) => {
    event.preventDefault();
    let self = this;
    if (this.handleValidation()) {
      let params = {
        eventId: this.props.eventId,
        couponDetails: JSON.stringify(this.state.couponDetails),
      };
      const accessToken = localStorage.getItem('accessToken');
      this.setState({ disabledBtn: true });
      usersService.createCoupon(params, accessToken).then((res) => {
        ResponseFilter(res);
        if (res.data.statusCode === 1) {
          message.success('Promo code has been created successfully!');

          self.closeModal();
          self.setState(
            {
              disabledBtn: false,
            },
            () => this.getCouponList()
          );
        } else {
          self.closeModal();
          self.setState(
            {
              disabledBtn: false,
            },
            () => this.getCouponList()
          );
        }
      });
    }
  };

  handleEditSubmit = (event) => {
    event.preventDefault();
    if (this.handleEditValidetion()) {
      let params = {
        couponId: this.state.couponId,
        couponName: this.state.couponName.replace(/\s/g, ''),
        discountType: this.state.discountType.toString(),
        quantity: this.state.quantity.toString(),
        discount: this.state.discount.toString(),
        selectedTickets: this.state.selectedItems,
      };
      const accessToken = localStorage.getItem('accessToken');
      usersService.updateCoupon(params, accessToken).then((res) => {
        ResponseFilter(res);
        if (res.data.statusCode === 1) {
          message.success('Promo code details updated Successfully!');
          this.closeModal();
          this.setState(
            {
              openEditPopup: false,
            },
            () => this.getCouponList()
          );
        } else {
          this.closeModal();
          this.setState(
            {
              openEditPopup: false,
            },
            () => this.getCouponList()
          );
        }
      });
    }
  };
  handleDelete = (data) => {
    let obj = {
      couponId: this.state.couponId,
      status: 2,
    };
    const accessToken = localStorage.getItem('accessToken');
    usersService.deleteCoupon(obj, accessToken).then((res) => {
      ResponseFilter(res);
      if (res.data.statusCode === 1) {
        message.success('Promo code has been  deleted successfully!');
        this.setState(
          {
            openDeletePopup: false,
          },
          () => this.getCouponList()
        );
      } else {
        this.setState({
          openDeletePopup: false,
        });
      }
    });
  };

  handleChangeSelect(i, selectedTickets) {
    let couponDetails = [...this.state.couponDetails];
    couponDetails[i].selectedTickets = selectedTickets;
    this.setState({ couponDetails });
  }

  handleEditSelect = (selectedItems) => {
    this.setState({ selectedItems });
  };
  render() {
    let options = [];
    for (var l = 0; l < this.props.ticketsList.length; l++) {
      if (this.props.ticketsList[l].price !== null) {
        options = [
          ...options,
          {
            value: this.props.ticketsList[l]._id,
            label: this.props.ticketsList[l].ticketName,
            price: this.props.ticketsList[l].price,
          },
        ];
      }
    }

    let { List, disabledBtn, selectedItems } = this.state;

    return (
      <React.Fragment>
        <div
          className="tab-pane fade show active"
          id="details"
          role="tabpanel"
          aria-labelledby="details"
        >
          <div className="col-lg-12 card-margin">
            <div className="card ">
              <div className="card-header">
                <div className="col-md-8">
                  <h6 className="card-title m-0">Event Codes</h6>
                </div>
                <div className="col-md-4 pr-0">
                  <button
                    type="button"
                    className="btn btn-sm btn-primary pull-right white"
                    onClick={this.handleOpenAddPopup}
                  >
                    <i className="fa fa-plus p-t-4"></i> Create Promo Code
                  </button>
                </div>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  <table id="data-table-2" className="table table-striped">
                    <thead>
                      <tr>
                        <th>
                          <span className="cm_col">Name</span>
                          <span className={this.state.couponSort==true?'sortingDESC ml-2':'sortingASC ml-2'} onClick={() =>
                            this.sortCouponsByName()
                          }></span>
                        </th>
                        <th>Redeemed</th>
                        <th>Available</th>
                        <th>
                          <span className="cm_col">Total</span>
                        </th>
                        <th>
                          <span className="cm_col">Discount</span>
                        </th>
                        <th>Apply To Ticket Type</th>
                        <th>Delete</th>
                        <th>Save</th>
                      </tr>
                    </thead>
                    <tbody>
                      {!_.isEmpty(List) ? (
                        <React.Fragment>
                          {List.map((item, i) => {
                            return (
                              <tr>
                                <td>
                                  <strong>
                                    <span className="cm_col">
                                      {item.couponName}
                                    </span>
                                  </strong>
                                </td>
                                <td>{item.redeemed ? item.redeemed : 0}</td>
                                <td>{item.quantity}</td>
                                <td>
                                  <span className="cm_col">
                                    {Number(item.quantity) +
                                      Number(item.redeemed ? item.redeemed : 0)}
                                  </span>
                                </td>

                                <td>
                                  <div className="col-md-2">
                                    <span className="cm_col">
                                      {item.discountType === 2 ? '$' : ''}
                                      {item.discountType === 1
                                        ? item.discount
                                        : Number(item.discount).toFixed(2)}
                                      {item.discountType === 1 ? '%' : ''}
                                    </span>
                                  </div>
                                </td>
                                <td>
                                  <span className="cm_col_tickets">
                                    {!_.isEmpty(item.ticketNames) ? (
                                      <React.Fragment>
                                        {item.ticketNames.map((name) => (
                                          <span className="cm_col_show">
                                            {name}
                                          </span>
                                        ))}
                                      </React.Fragment>
                                    ) : (
                                      'Ticket deleted'
                                    )}
                                  </span>
                                </td>
                                <td>
                                  <span
                                    onClick={() =>
                                      this.handleOpenDeletePopup(item._id)
                                    }
                                  >
                                    <Delete />
                                  </span>
                                </td>
                                <td>
                                  <button
                                    type="button"
                                    className="btn btn-sm btn-dark"
                                    onClick={() =>
                                      this.handleOpenEditPopup(item)
                                    }
                                  >
                                    Update
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                        </React.Fragment>
                      ) : (
                        ''
                      )}
                    </tbody>
                  </table>
                  {!_.isEmpty(this.state.List) ? (
                    ''
                  ) : (
                    <div className="text-center">
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          size="md"
          show={this.state.openDeletePopup}
          onHide={this.closeModal}
          aria-labelledby="example-modal-sizes-title-sm"
        >
          <Modal.Header closeButton></Modal.Header>
          <div className="cm_modal">
            <div className="modal-body">
              <h4 className="mb-4">
                Are you sure you want to delete this promo code?
              </h4>
              <br />

              <div className="bg-white pull-right mb-4">
                <button
                  className="btn btn-light"
                  type="button"
                  onClick={this.closeModal}
                >
                  No
                </button>
                <button
                  type="submit"
                  className="btn btn-primary ml-2"
                  onClick={() => this.handleDelete(List)}
                >
                  Yes
                </button>
              </div>
              <br />
              <br />
            </div>
          </div>
        </Modal>

        <Modal
          size="lg"
          show={this.state.openAddPopup}
          onHide={this.closeModal}
          className="coupon-popup"
          aria-labelledby="example-modal-sizes-title-sm"
        >
          <Modal.Header closeButton>
            <h5 className="card-title mt-4">Add Promo Code</h5>
          </Modal.Header>
          {this.state.inputErrorMsg ? (
            <p className="error mt-0">{this.state.inputErrorMsg}</p>
          ) : null}

          <form>
            <div className="card-margin">
              <div className="card-body">
                <div>
                  {this.state.couponDetails.map((el, i) => (
                    <React.Fragment>
                      <div className="border-bottom   mt-0 mb-2">
                        <div className="row">
                          <div className="col-md-10">
                            <div className="row">
                              <div className=" col-lg-6 col-sm-6  form-group">
                                <label className="sub">
                                  Name<span className="text-danger">*</span>
                                </label>
                                <input
                                  type="text"
                                  maxLength="50"
                                  className="form-control"
                                  id="row-1-position"
                                  placeholder="Name"
                                  name="couponName"
                                  value={el.couponName || ''}
                                  onChange={this.handleChangeInput.bind(
                                    this,
                                    i
                                  )}
                                />
                              </div>
                              <div className=" col-lg-6 col-sm-6 form-group">
                                <label className="sub">
                                  Quantity<span className="text-danger">*</span>
                                </label>
                                <input
                                  onKeyDown={(e) =>
                                    this.numberInputValidation(e)
                                  }
                                  onKeyPress={(e) => {
                                    if (
                                      el.quantity.length > 3 &&
                                      e.key !== 'Backspace'
                                    ) {
                                      e.preventDefault();
                                    } else if (e.key == '-') {
                                      e.preventDefault();
                                    }
                                  }}
                                  type="number"
                                  id="number"
                                  min="1"
                                  className="form-control"
                                  name="quantity"
                                  placeholder="Quantity"
                                  value={el.quantity || ''}
                                  onChange={this.handleChangeInput.bind(
                                    this,
                                    i
                                  )}
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className=" col-lg-4 col-md-4 col-6 form-group">
                                <label className="sub">
                                  Discount<span className="text-danger">*</span>
                                </label>
                                <input
                                  disabled={el.discountType == ''}
                                  maxLength="50"
                                  type="number"
                                  min="1"
                                  className="form-control mr-3"
                                  name="discount"
                                  placeholder="Discount"
                                  onKeyPress={(e) => {
                                    if (
                                      el.discount.length > 5 &&
                                      e.key !== 'Backspace'
                                    ) {
                                      e.preventDefault();
                                    } else if (e.key == '-') {
                                      e.preventDefault();
                                    }
                                  }}
                                  value={el.discount || ''}
                                  onChange={this.handleChangeInput.bind(
                                    this,
                                    i
                                  )}
                                />
                              </div>
                              <div className="col-lg-2 col-md-2 col-6 form-group">
                                {/* <label className="sub">
                                  Discount Type
                                  <span className="text-danger">*</span>
                                </label> */}
                                <div className="d-flex mt-md-4 mt-3 pt-md-1">
                                  <span className="string-check string-check-bordered-primary string-check-inline mr-2">
                                    <input
                                      type="radio"
                                      className="form-check-input"
                                      id="formRadioInput11"
                                      value={el.discountType || ''}
                                      onChange={(e) =>
                                        this.handleChangeDiscountType(i, 1)
                                      }
                                      checked={
                                        el.discountType === 1 ? true : false
                                      }
                                    />
                                    <label
                                      className="string-check-label"
                                      for="formRadioInput11"
                                    >
                                      <span className="">
                                        <small>%</small>
                                      </span>
                                    </label>
                                  </span>
                                  <span className="string-check string-check-bordered-primary">
                                    <input
                                      type="radio"
                                      className="form-check-input"
                                      id="formRadioInput12"
                                      value={el.discountType || ''}
                                      onChange={(e) =>
                                        this.handleChangeDiscountType(i, 2)
                                      }
                                      checked={
                                        el.discountType === 2 ? true : false
                                      }
                                    />
                                    <label
                                      className="string-check-label"
                                      for="formRadioInput12"
                                    >
                                      <span className="">
                                        <small>$</small>
                                      </span>
                                    </label>
                                  </span>
                                </div>
                              </div>
                              <div className=" col-lg-6 col-sm-6 form-group">
                                <label className="sub">
                                  Apply To Ticket Type
                                  <span className="text-danger">*</span>
                                </label>
                                <br />
                                <Select
                                  disabled={
                                    el.discountType === '' || el.discount === ''
                                      ? true
                                      : false
                                  }
                                  mode="multiple"
                                  placeholder="Select"
                                  size="large"
                                  value={el.selectedTickets}
                                  onChange={this.handleChangeSelect.bind(
                                    this,
                                    i
                                  )}
                                  className="w-100"
                                >
                                  {!_.isEmpty(this.props.ticketsList) ? (
                                    <React.Fragment>
                                      {el.discountType == 2 ? (
                                        <React.Fragment>
                                          {this.props.ticketsList
                                            .filter(
                                              (e) => e.price >= el.discount
                                            )
                                            .map((item) =>
                                              item.ticketType === 2 ? (
                                                <Select.Option value={item._id}>
                                                  {item.ticketName}
                                                </Select.Option>
                                              ) : (
                                                ''
                                              )
                                            )}
                                        </React.Fragment>
                                      ) : (
                                        <React.Fragment>
                                          {this.props.ticketsList.map((item) =>
                                            item.ticketType === 2 ? (
                                              <Select.Option value={item._id}>
                                                {item.ticketName}
                                              </Select.Option>
                                            ) : (
                                              ''
                                            )
                                          )}
                                        </React.Fragment>
                                      )}
                                    </React.Fragment>
                                  ) : (
                                    ''
                                  )}
                                </Select>

                                {/* 
                                <ReactSelect
                                  // className="form-control"
                                  value={el.selectedOption}
                                  onChange={this.handleChangeReactSelect}
                                  isMulti={true}
                                  options={options}
                                /> */}
                              </div>
                            </div>
                          </div>
                          {this.state.couponDetails.length == 1 ? (
                            ''
                          ) : (
                            <div class="col-md-2 text-md-center">
                              <div class="mt-md-4 pt-3 mb-4 mb-md-0">
                                <span
                                  title="remove"
                                  class="ml-3  btn-primary p-3"
                                  onClick={this.removeClick.bind(this, i)}
                                >
                                  <Delete />
                                </span>
                              </div>
                            </div>
                          )}
                        </div>
                        {/* 
                        <div className="row">
                          <div className=" col-lg-12 col-sm-12 form-group">
                            <span
                              title="remove"
                              onClick={this.removeClick.bind(this, i)}
                            >
                              <Delete />
                            </span>
                          </div>
                        </div> */}
                      </div>
                    </React.Fragment>
                  ))}

                  <div className="text-right">
                    <button
                      type="button"
                      className="btn btn-sm btn-dark"
                      onClick={this.addClick.bind(this)}
                      style={{ width: 70 }}
                    >
                      <Plus2 /> Add
                    </button>
                  </div>
                </div>
              </div>
              <div className="card-footer bg-white">
                <button
                  className="btn btn-light"
                  type="button"
                  onClick={this.closeModal}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-primary ml-2"
                  onClick={this.handleAddSubmit}
                  disabled={
                    disabledBtn || this.state.couponDetails.length === 0
                  }
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </Modal>

        <Modal
          size="lg"
          show={this.state.openEditPopup}
          onHide={this.closeModal}
          className="coupon-popup"
          aria-labelledby="example-modal-sizes-title-sm"
        >
          <Modal.Header closeButton>
            <h5 className="card-title mt-4">Edit Promo Code</h5>
          </Modal.Header>
          {this.state.inputErrorMsg ? (
            <p className="error mt-0">{this.state.inputErrorMsg}</p>
          ) : null}

          <form>
            <div className="card-margin">
              <div className="card-body">
                <div>
                  <React.Fragment>
                    <div className="border-bottom   mt-0 mb-2">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="row">
                            <div className=" col-lg-6 col-sm-6  form-group">
                              <label className="sub">
                                Name<span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="row-1-position"
                                placeholder="Name"
                                name="couponName"
                                maxLength="50"
                                value={this.state.couponName}
                                onChange={this.handleChange}
                              />
                            </div>
                            <div className=" col-lg-6 col-sm-6 form-group">
                              <label className="sub">
                                Quantity<span className="text-danger">*</span>
                              </label>
                              <input
                                type="number"
                                min="1"
                                className="form-control"
                                id="row-1-position"
                                placeholder="Quantity"
                                name="quantity"
                                value={this.state.quantity}
                                onChange={this.handleChange}
                                onKeyDown={(e) => this.numberInputValidation(e)}
                                onKeyPress={(e) => {
                                  if (
                                    this.state.quantity.length > 3 &&
                                    e.key !== 'Backspace'
                                  ) {
                                    e.preventDefault();
                                  } else if (e.key == '-') {
                                    e.preventDefault();
                                  }
                                }}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className=" col-lg-4 col-md-4 col-6 form-group">
                              <label className="sub">
                                Discount<span className="text-danger">*</span>
                              </label>
                              <input
                                disabled={this.state.discountType == ''}
                                type="number"
                                className="form-control mr-3"
                                id="row-1-position"
                                placeholder="Discount"
                                name="discount"
                                value={this.state.discount}
                                onChange={this.handleChange}
                                onKeyPress={(e) => {
                                  if (
                                    this.state.discount.length > 5 &&
                                    e.key !== 'Backspace'
                                  ) {
                                    e.preventDefault();
                                  } else if (e.key == '-') {
                                    e.preventDefault();
                                  }
                                }}
                              />
                            </div>
                            <div className="col-lg-2 col-md-2 col-6 form-group">
                              <div className="d-flex mt-md-4 mt-3 pt-md-1">
                                <span className="string-check string-check-bordered-primary string-check-inline mr-2">
                                  <input
                                    type="radio"
                                    className="form-check-input"
                                    checked={
                                      this.state.discountType === 1
                                        ? true
                                        : false
                                    }
                                    value={this.state.discountType}
                                    onChange={(e) =>
                                      this.handleEditDiscountType(1)
                                    }
                                  />
                                  <label
                                    className="string-check-label"
                                    for="formRadioInput11"
                                  >
                                    <span className="">
                                      <small>%</small>
                                    </span>
                                  </label>
                                </span>
                                <span className="string-check string-check-bordered-primary">
                                  <input
                                    type="radio"
                                    className="form-check-input"
                                    checked={
                                      this.state.discountType === 2
                                        ? true
                                        : false
                                    }
                                    value={this.state.discountType}
                                    onChange={(e) =>
                                      this.handleEditDiscountType(2)
                                    }
                                  />
                                  <label
                                    className="string-check-label"
                                    for="formRadioInput12"
                                  >
                                    <span className="">
                                      <small>$</small>
                                    </span>
                                  </label>
                                </span>
                              </div>
                            </div>
                            <div className=" col-lg-6 col-sm-6 form-group">
                              <label className="sub">
                                Apply To Ticket Type
                                <span className="text-danger">*</span>
                              </label>
                              <br />
                              <Select
                                disabled={
                                  this.state.discountType === '' ||
                                  this.state.discount === ''
                                    ? true
                                    : false
                                }
                                mode="multiple"
                                size="large"
                                placeholder="Select"
                                value={selectedItems}
                                className="w-100"
                                onChange={this.handleEditSelect}
                              >
                                {!_.isEmpty(this.props.ticketsList) ? (
                                  <React.Fragment>
                                    {this.state.discountType == 2 ? (
                                      <React.Fragment>
                                        {this.props.ticketsList
                                          .filter(
                                            (e) =>
                                              e.price >= this.state.discount
                                          )
                                          .map((item) =>
                                            item.ticketType === 2 ? (
                                              <Select.Option value={item._id}>
                                                {item.ticketName}
                                              </Select.Option>
                                            ) : (
                                              ''
                                            )
                                          )}
                                      </React.Fragment>
                                    ) : (
                                      <React.Fragment>
                                        {this.props.ticketsList.map((item) =>
                                          item.ticketType === 2 ? (
                                            <Select.Option value={item._id}>
                                              {item.ticketName}
                                            </Select.Option>
                                          ) : (
                                            ''
                                          )
                                        )}
                                      </React.Fragment>
                                    )}
                                  </React.Fragment>
                                ) : (
                                  ''
                                )}
                              </Select>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* 
                        <div className="row">
                          <div className=" col-lg-12 col-sm-12 form-group">
                            <span
                              title="remove"
                              onClick={this.removeClick.bind(this, i)}
                            >
                              <Delete />
                            </span>
                          </div>
                        </div> */}
                    </div>
                  </React.Fragment>

                  <div className="text-right">
                    <button
                      type="button"
                      className="btn btn-sm btn-dark"
                      onClick={this.addClick.bind(this)}
                      style={{ width: 70 }}
                    >
                      <Plus2 /> Add
                    </button>
                  </div>
                </div>
              </div>
              <div className="card-footer bg-white">
                <button
                  className="btn btn-light"
                  type="button"
                  onClick={this.closeModal}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-primary ml-2"
                  disabled={disabledBtn}
                  onClick={this.handleEditSubmit}
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </Modal>
      </React.Fragment>
    );
  }
}

export default Coupon;
