/* eslint-disable no-undef */
import React from 'react';
import Footer from '../template/Footer';
import SideNav from '../template/SideNav';
import TopBar from '../template/TopBar';
import Modal from 'react-bootstrap/Modal';
import { usersService } from '../../_services/usersService';
import Pagination from 'react-js-pagination';
import ResponseFilter from '../../config/response-handler';
import {
  Loader,
  Edit,
  EyeIcon,
  Active,
  Draft,
  Completed,
  Image,
  Delete,
} from '../../config/Svgicon';
import moment from 'moment';
import _, { toLower } from 'lodash';
import { Select, Empty, message } from 'antd';
import { Link } from 'react-router-dom';
import { CSVLink } from 'react-csv'

const { Option } = Select;

class ManageMyEvent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      openDeletePopup: false,
      eventListDetails: [],
      eventListCompleted: [],
      limit:500,
      page: 1,
      status: '',
      eventIdDelete: '',
      sortOrder: 'desc',
      sortBy: 'created',
      eventStats:[],
      quickStatI:0,
    };
  }

  componentDidMount() {
    if (
      localStorage.getItem('isAccountDetailsExists') == 'false' &&
      localStorage.getItem('role') != 2
    ) {
      this.props.history.push('/account-settings');
    }
    // ADJUST TO CHECK FOR PROMOTER ACCESS HERE
    if (localStorage.getItem('role') == 2) {
      if(!localStorage.getItem('promoterId')||localStorage.getItem('promoterId')==''){ 
        this.props.history.push('/events-feed');
      } else {
        this.setState({isAssistant:true});
      }
    }
    // GET PROMOTER ID
    /*if (localStorage.getItem('role') == 2) {
      this.props.history.push('/events-feed');
    }*/
    if (localStorage.getItem('role') == 4) {
      this.props.history.push('/admin');
    }

    document.title = 'My Events | Farechild';
    this.eventList();
  }

  handlePageChange = (page) => {
    this.setState({ page: page, loader: true }, () => this.eventList());
  };
  closeModal = () => {
    this.setState({
      eventIdDelete: '',
      openDeletePopup: false,
    });
  };

  handleDeleteDarftEvent = (eventId) => {
    let obj = {
      eventId: eventId,
    };
    const accessToken = localStorage.getItem('accessToken');
    usersService.deleteDraftEvent(obj, accessToken).then((res) => {
      ResponseFilter(res);
      if (res.data.statusCode === 1) {
        message.success('Event deleted Successfully!');
        this.closeModal();
        this.eventList();
      } else {
        this.closeModal();
        this.eventList();
      }
    });
  };
  eventList = () => {
    const currentTimeZoneName =
      Intl.DateTimeFormat().resolvedOptions().timeZone;

    let { limit, sortOrder, sortBy, page, status } = this.state;
    let obj = {
      currentTimeZoneName: currentTimeZoneName,
      limit: limit,
      createdBy: localStorage.getItem('userId'),
      sortOrder: sortOrder,
      sortBy: sortBy,
      status: status,
      page: page - 1,
    };
    if (localStorage.getItem('role') == 2) {
      if(localStorage.getItem('promoterId')&&localStorage.getItem('promoterId')!=''){ 
        obj.createdBy = localStorage.getItem('promoterId');
        obj.status = "2";
      }
    }
    console.log('QUERY OBJ',obj)
    this.setState({ loader: true });
    usersService.eventList(obj).then((res) => {
      ResponseFilter(res);
      console.log('get events res',res);
      if (res.data.statusCode === 1) {
        let upcoming = [];
        let completed = [];
        res.data.responseData.result.map((info) => {
          if(info.status==3){
            completed.push(info);
          } else {
            upcoming.push(info);
          }
        });
        this.setState({
          eventListDetails: upcoming,
          eventListCompleted: completed,
          totalCount: res.data.responseData.count,
          loader: false,
        });
      } else {
        this.setState({ loader: false });
      }
    });
    console.log('event stats query');
    usersService.eventStats(obj).then((res) => {
      ResponseFilter(res); 
      console.log('event stats res',res);
      console.log('EVENT STATS!!!!',res);
      let stats = res.data.responseData;
      let newStats = [];
      stats.map((event,i)=>{
        console.log("STATS EVENT",event);
        let attendees = event[0].attendeeDetails;
        let attendeeCSV = [];
        if(attendees&&attendees.length>0){
          attendees.map((attendee,y)=>{
            attendeeCSV.push({
              'Name':attendee._id,
              'Tickets Purchased':attendee.totalTicketsPurchased,
              'Tickets Redeemed':attendee.totalTicketsRedeemed,
            });  
          });
        }
        let tempStats = {...event[0],attendeeCSV:attendeeCSV};
        console.log('TEMP STATS',tempStats);
        newStats.push(tempStats);
      });
      this.setState({eventStats:newStats},()=>{
        console.log('STATS STATE',this.state);
      })
    });
  };

  handleOpenDeletePopup = (eventIdDelete) =>
    this.setState({
      openDeletePopup: !this.state.openDeletePopup,
      eventIdDelete,
    });
  changeQuickStats = (data) => {
    console.log('quick stats',data);
    this.state.eventStats.map((event,i)=>{
      console.log(event.eventDetails[0]._id);
      
      if(event.eventDetails[0]._id==data){
        console.log(event);
        return this.setState({quickStatI:i},()=>{
          console.log(this.state);
          window.scrollTo({top:0,behavior: 'smooth'});
        });
      }
    });
  }
  openEventPage = (eventId, name1) => {
    var name = name1.replace(/ /g, '-');
    var eventName = toLower(name);
    switch (process.env.REACT_APP_ENV) {
      case 'stag':
      case 'staging':
        const url1 = `https://stag.farechild.com/event/${eventId}/${encodeURIComponent(
          eventName
        )}`;
        window.open(url1, '_blank');
        break;

      case 'dev':
      case 'development':
        const url2 = `https://dev.farechild.com/event/${eventId}/${encodeURIComponent(
          eventName
        )}`;
        window.open(url2, '_blank');

        break;
      case 'prod':
      case 'production':
        const url3 = `https://events.farechild.com/event/${eventId}/${encodeURIComponent(
          eventName
        )}`;

        window.open(url3, '_blank');

        break;
      default:
        const url4 = `http://localhost:3000/event/${eventId}/${encodeURIComponent(
          eventName
        )}`;
        window.open(url4, '_blank');
    }
  };
  handleFilter = (e) => {
    this.setState({ status: e ? e : '', page: 1 }, () => this.eventList());
  };
  render() {
    let { loader } = this.state;
    return (
      <div className="dark-sidebar">
        
        {loader ? <Loader /> : null}
        <div className="main-container">
          <div className="container-fluid page-body-wrapper">
            <nav className="navbar fixed-top">
              <div className="navbar-menu-container d-flex align-items-center justify-content-center">
                <div className="sub-heading">
                  <h4 className="page-title ">MY EVENTS</h4>
                </div>
                <div className="text-center navbar-brand-container align-items-center justify-content-center">
                  <a className="brand-logo" href="/">
                    <img
                      src={require('../../assets/images/mobile-logo.png')}
                      alt=""
                      className="mb-3"
                    />
                  </a>
                </div>

                <TopBar {...this.props} />
              </div>
            </nav>
            <SideNav />
            <div className="main-panel">
              <div className="content-wrapper manage-event">
                {localStorage.getItem("role") == 4 || localStorage.getItem("isAdmin") === "true" ?
                  <div className="row card-margin">
                    <div className="col-12">
                      {this.state.eventStats.length>0?
                      <div className="card quick-stats">
                        <div className="quick-stats-title">
                          <h3>{this.state.eventStats[this.state.quickStatI].eventDetails[0].eventName}</h3>
                        </div>
                        <div className="quick-stats-body">
                          <div className="image"><img class="widget-8-img" src={this.state.eventStats[this.state.quickStatI].eventDetails[0].eventImage} alt="" title=""></img></div>
                          <div className="stats">
                          <h3><b>Promoter Revenue:</b> ${Number(this.state.eventStats[this.state.quickStatI].eventDetails[0].totalRevenue).toFixed(2)}</h3>
                          <div className="quick-stats-section tickets">
                            <h3>Tickets</h3>
                            <h4><b>Tickets Sold:</b> {this.state.eventStats[this.state.quickStatI].eventDetails[0].totalTicketsSold}</h4>
                            <ul>
                            {this.state.eventStats[this.state.quickStatI].totalsByTicketType.map((ticket)=>{
                              return <li><div><b>{ticket.ticketName}</b></div><div>{ticket.ticketsSold} Sold</div></li>;
                            })}
                            </ul>
                          </div>
                          
                          <div className="quick-stats-section discounts">
                            <h3>Discounts</h3>
                            <h4><b>Total Discounted:</b> ${Number(this.state.eventStats[this.state.quickStatI].eventDetails[0].totalDiscounts?this.state.eventStats[this.state.quickStatI].eventDetails[0].totalDiscounts:0).toFixed(2)}</h4>
                            <ul className="stat-scroll-box">
                              {this.state.eventStats[this.state.quickStatI].discountDetails.map((discount)=>{
                                return <li><div><b>{discount.discountName}</b></div><div>{discount.numberRedeemed} Redeemed</div></li>;
                              })}
                            </ul>
                          </div>
                          <div className="quick-stats-section attendees">
                            <h3>Attendees 
                              <div className="stat-section-export">
                                <CSVLink
                                  filename={`Attendees-List.csv`}
                                  data={this.state.eventStats[this.state.quickStatI].attendeeDetails}
                                  className='btn btn-sm btn-dark mb-2 ml-2'
                                >
                                Export Attendee List
                              </CSVLink>
                            </div>
                            </h3>
                            <div><b>{Math.abs(this.state.eventStats[this.state.quickStatI].totalTicketsRedeemed)} guest{this.state.eventStats[this.state.quickStatI].totalTicketsRedeemed!=1?'s':''} attended</b></div>
                          </div>
                          </div>
                        </div>
                      </div>:''}
                    </div>
                  </div>
                :''}
                <div className="row card-margin">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-header card-new-header">
                        <div className="row d-flex">
                          <div className="col-sm-6">
                            <h5 className="card-title">
                              My Events
                              <small className="card-title-secondary">
                                Manage your events below
                              </small>
                            </h5>
                          </div>
                          <div className="col-sm-6 text-sm-right mt-3 mt-sm-0">
                            <Select
                              className="status_button"
                              value={
                                this.state.status
                                  ? this.state.status
                                  : 'Filter by Event Type'
                              }
                              style={{ minWidth: 140 }}
                              onChange={(e) => this.handleFilter(e)}
                            >
                              <Option value="">
                                <i class="far fa-check-square"></i>All
                              </Option>
                              <Option value="2">
                                <Active className="m-3" />
                                Live
                              </Option>
                              <Option value="1">
                                <Draft />
                                Draft
                              </Option>
                              <Option value="3">
                                <Completed />
                                Completed
                              </Option>
                            </Select>
                          </div>
                        </div>
                      </div>
                     
                      <div className="card-body">
                        <div className="table-responsive">
                          <table className="table widget-8">
                            <thead>
                              <tr>
                                <th className="border-0">Event</th>
                                <th className="border-0">Status</th>
                                {(this.state.isAssistant?'':<th className="border-0">Quick Stats</th>)}
                                <th className="border-0 text-center">Quick View</th>
                                <th className="border-0 text-center">View</th>
                                <th className="border-0 text-center">{(this.state.isAssistant?'Check In Guests':'Edit')}</th>
                                {(this.state.isAssistant?'':<th className="border-0 text-center">Delete</th>)}
                              </tr>
                            </thead>
                            <tbody>
                              {!_.isEmpty(this.state.eventListDetails) ? (
                                <React.Fragment>
                                  {this.state.eventListDetails.map((info) => (
                                    <tr>
                                      <td>
                                        <div className="widget-8-title-wrapper">
                                          {info.eventImage ? (
                                            <img
                                              className="widget-8-img"
                                              src={
                                                info.eventImage
                                                  ? info.eventImage
                                                  : ''
                                              }
                                              alt=""
                                              title=""
                                            />
                                          ) : (
                                            <span className="widget-8-img">
                                              <Image />
                                            </span>
                                          )}

                                          <div className="widget-8-product-info">
                                            <div className="title event-title-name">
                                              {info.eventName
                                                ? info.eventName
                                                : ''}
                                            </div>
                                            <div className="attribute">
                                              {info.eventPlatform == 1 ? (
                                                <>
                                                  {info.eventDate[0]
                                                    ? moment(
                                                        info.eventDate[0]
                                                      ).format('MMMM DD ,yyyy')
                                                    : moment(
                                                        info.eventDate[0]
                                                      ).format('MMMM DD ,yyyy')}
                                                </>
                                              ) : (
                                                <>
                                                  {info.startTimeStamp
                                                    ? moment(
                                                        info.startTimeStamp
                                                      ).format('MMMM DD, yyyy')
                                                    : moment(
                                                        info.startTimeStamp
                                                      ).format('MMMM DD, yyyy')}
                                                </>
                                              )}{' '}
                                               {info.eventPlatform == 2 ? (
                                  <>{info.timeZone ? <>({info.timeZone})</> : ''}</>
                                ) : (
                                  ''
                                )}

                                             
                                            </div>
                                          </div>
                                        </div>
                                      </td>
                                      <td>
                                        {info.status === 1 ? (
                                          <div className="widget-8-status-wrapper">
                                            <div className="badge badge-pill badge-outline-secondary">
                                              Draft
                                            </div>
                                          </div>
                                        ) : info.status === 2 ? (
                                          <div className="widget-8-status-wrapper">
                                            <div className="badge badge-pill badge-outline-success">
                                              Live
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="widget-8-status-wrapper">
                                            <div className="badge badge-pill badge-outline-warning">
                                              Completed
                                            </div>
                                          </div>
                                        )}
                                      </td>
                                      {(this.state.isAssistant?'':
                                      <td>
                                        <div className="widget-8-desc row">
                                          <div
                                            className="widget-8-order-wrapper"
                                            style={{ width: 200 }}
                                          >
                                            <div className="figure">
                                              {info.totalTicketSold
                                                ? info.totalTicketSold
                                                : 0}
                                              /
                                              {(info.totalTickets
                                                ? info.totalTickets
                                                : 0) +
                                                (info.totalTicketSold
                                                  ? info.totalTicketSold
                                                  : 0)}
                                              <small className="pl-1 text-success">
                                                <strong>
                                                  {info.totalTicketSold === 0
                                                    ? 0
                                                    : Number(
                                                        ((info.totalTicketSold
                                                          ? info.totalTicketSold
                                                          : 0) /
                                                          ((info.totalTickets
                                                            ? info.totalTickets
                                                            : 0) +
                                                            (info.totalTicketSold
                                                              ? info.totalTicketSold
                                                              : 0))) *
                                                          100
                                                      ).toFixed(2)}
                                                  %
                                                </strong>
                                              </small>
                                            </div>
                                            <div className="desc">
                                              Tickets Sold
                                            </div>
                                          </div>
                                          <div
                                            className="widget-8-return-wrapper"
                                            style={{ width: 100 }}
                                          >
                                            <div className="figure">
                                              $
                                              {info.subTotal
                                                ? Number(
                                                    info.subTotal
                                                  ).toFixed(2)
                                                : 0}
                                            </div>
                                            <div className="desc">
                                            Promoter Revenue
                                            </div>
                                          </div>
                                          <div
                                            className="widget-8-earning-wrapper "
                                            style={{ width: 100 }}
                                          >
                                            <div className="figure">
                                              {info.eventVisitor
                                                ? info.eventVisitor
                                                : 0}
                                            </div>
                                            <div className="desc">
                                              Page Visitors
                                            </div>
                                          </div>
                                        </div>
                                      </td>)}
                                      <td className="text-center">
                                        {localStorage.getItem("role") == 4 || localStorage.getItem("isAdmin") === "true" ?
                                          <div
                                            onClick={() =>
                                              this.changeQuickStats(
                                                info._id,
                                                info.eventName
                                              )
                                            }
                                          >
                                            <span className="view_page">
                                              <EyeIcon />
                                            </span>
                                          </div>
                                        :'_'}
                                      </td>
                                      <td className="text-center">
                                        {info.status === 1 ? (
                                          '_'
                                        ) : (
                                          <Link
                                            onClick={() =>
                                              this.openEventPage(
                                                info._id,
                                                info.eventName
                                              )
                                            }
                                          >
                                            <span className="view_page">
                                              <EyeIcon />
                                            </span>
                                          </Link>
                                        )}
                                      </td>

                                      <td className="text-center">
                                        {info.status === 1 ? (
                                          <Link
                                            className="btn btn-sm btn-flash-primary"
                                            to={`/create-event/${info._id}`}
                                            type="button"
                                            title="Edit event"
                                          >
                                            <Edit />
                                          </Link>
                                        ) : info.status === 2 ? (
                                          <React.Fragment>
                                            <Link
                                              className="btn btn-sm btn-flash-primary"
                                              to={`/manage-event/edit-event/${info._id}`}
                                              type="button"
                                              title="Edit event"
                                            >
                                              <Edit />
                                            </Link>
                                          </React.Fragment>
                                        ) : (
                                          <React.Fragment>
                                            <Link
                                              className="btn btn-sm btn-flash-primary"
                                              to={{
                                                pathname: `/manage-event/after-event-update/${info._id}`,
                                                state: {
                                                  eventName: info.eventName,
                                                },
                                              }}
                                              type="button"
                                              title="Edit event"
                                            >
                                              <Edit />
                                            </Link>
                                          </React.Fragment>
                                        )}
                                      </td>
                                      {(this.state.isAssistant?'':
                                      <td className="text-center">
                                        {info.totalTicketSold === 0 ? (
                                          <span
                                            className="view_page"
                                            onClick={() =>
                                              this.handleOpenDeletePopup(
                                                info._id
                                              )
                                            }
                                          >
                                            <Delete />
                                          </span>
                                        ) : (
                                          '_'
                                        )}
                                      </td>
                                      )}
                                    </tr>
                                  ))}
                                </React.Fragment>
                              ) : (
                                ''
                              )}
                            </tbody>
                          </table>
                        </div>
                        {this.state.eventListDetails.length > 0 ? (
                          ''
                        ) : (
                          <div className="text-center">
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                          </div>
                        )}
                      </div>
                      <div className="card-body">
                        <div className="table-responsive">
                          <table className="table widget-8">
                            <thead>
                              <tr>
                                <th className="border-0">Event</th>
                                <th className="border-0">Status</th>
                                {(this.state.isAssistant?'':<th className="border-0">Quick Stats</th>)}
                                <th className="border-0 text-center">Quick View</th>
                                <th className="border-0 text-center">View</th>
                                <th className="border-0 text-center">{(this.state.isAssistant?'Check In Guests':'Edit')}</th>
                                {(this.state.isAssistant?'':<th className="border-0 text-center">Delete</th>)}
                              </tr>
                            </thead>
                            <tbody>
                              {!_.isEmpty(this.state.eventListCompleted) ? (
                                <React.Fragment>
                                  {this.state.eventListCompleted.map((info) => (
                                    <tr>
                                      <td>
                                        <div className="widget-8-title-wrapper">
                                          {info.eventImage ? (
                                            <img
                                              className="widget-8-img"
                                              src={
                                                info.eventImage
                                                  ? info.eventImage
                                                  : ''
                                              }
                                              alt=""
                                              title=""
                                            />
                                          ) : (
                                            <span className="widget-8-img">
                                              <Image />
                                            </span>
                                          )}

                                          <div className="widget-8-product-info">
                                            <div className="title event-title-name">
                                              {info.eventName
                                                ? info.eventName
                                                : ''}
                                            </div>
                                            <div className="attribute">
                                              {info.eventPlatform == 1 ? (
                                                <>
                                                  {info.eventDate[0]
                                                    ? moment(
                                                        info.eventDate[0]
                                                      ).format('MMMM DD ,yyyy')
                                                    : moment(
                                                        info.eventDate[0]
                                                      ).format('MMMM DD ,yyyy')}
                                                </>
                                              ) : (
                                                <>
                                                  {info.startTimeStamp
                                                    ? moment(
                                                        info.startTimeStamp
                                                      ).format('MMMM DD, yyyy')
                                                    : moment(
                                                        info.startTimeStamp
                                                      ).format('MMMM DD, yyyy')}
                                                </>
                                              )}{' '}
                                               {info.eventPlatform == 2 ? (
                                  <>{info.timeZone ? <>({info.timeZone})</> : ''}</>
                                ) : (
                                  ''
                                )}

                                             
                                            </div>
                                          </div>
                                        </div>
                                      </td>
                                      <td>
                                        {info.status === 1 ? (
                                          <div className="widget-8-status-wrapper">
                                            <div className="badge badge-pill badge-outline-secondary">
                                              Draft
                                            </div>
                                          </div>
                                        ) : info.status === 2 ? (
                                          <div className="widget-8-status-wrapper">
                                            <div className="badge badge-pill badge-outline-success">
                                              Live
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="widget-8-status-wrapper">
                                            <div className="badge badge-pill badge-outline-warning">
                                              Completed
                                            </div>
                                          </div>
                                        )}
                                      </td>
                                      {(this.state.isAssistant?'':
                                      <td>
                                        <div className="widget-8-desc row">
                                          <div
                                            className="widget-8-order-wrapper"
                                            style={{ width: 200 }}
                                          >
                                            <div className="figure">
                                              {info.totalTicketSold
                                                ? info.totalTicketSold
                                                : 0}
                                              /
                                              {(info.totalTickets
                                                ? info.totalTickets
                                                : 0) +
                                                (info.totalTicketSold
                                                  ? info.totalTicketSold
                                                  : 0)}
                                              <small className="pl-1 text-success">
                                                <strong>
                                                  {info.totalTicketSold === 0
                                                    ? 0
                                                    : Number(
                                                        ((info.totalTicketSold
                                                          ? info.totalTicketSold
                                                          : 0) /
                                                          ((info.totalTickets
                                                            ? info.totalTickets
                                                            : 0) +
                                                            (info.totalTicketSold
                                                              ? info.totalTicketSold
                                                              : 0))) *
                                                          100
                                                      ).toFixed(2)}
                                                  %
                                                </strong>
                                              </small>
                                            </div>
                                            <div className="desc">
                                              Tickets Sold
                                            </div>
                                          </div>
                                          <div
                                            className="widget-8-return-wrapper"
                                            style={{ width: 100 }}
                                          >
                                            <div className="figure">
                                              $
                                              {info.subTotal
                                                ? Number(
                                                    info.subTotal
                                                  ).toFixed(2)
                                                : 0}
                                            </div>
                                            <div className="desc">
                                            Promoter Revenue
                                            </div>
                                          </div>
                                          <div
                                            className="widget-8-earning-wrapper "
                                            style={{ width: 100 }}
                                          >
                                            <div className="figure">
                                              {info.eventVisitor
                                                ? info.eventVisitor
                                                : 0}
                                            </div>
                                            <div className="desc">
                                              Page Visitors
                                            </div>
                                          </div>
                                        </div>
                                      </td>)}
                                      
                                      
                                      

                                      <td className="text-center">
                                      {info.eventDate[0]>'2023-09-01'&&(localStorage.getItem("role") == 4 || localStorage.getItem("isAdmin") === "true")?
                                        <div
                                            onClick={() =>
                                              this.changeQuickStats(
                                                info._id,
                                                info.eventName
                                              )
                                            }
                                          >
                                            <span className="view_page">
                                              <EyeIcon />
                                            </span>
                                          </div>
                                      :'_'}
                                        
                                      </td>
                                      <td className="text-center">
                                        {info.status === 1 ? (
                                          '_'
                                        ) : (
                                          <Link
                                            onClick={() =>
                                              this.openEventPage(
                                                info._id,
                                                info.eventName
                                              )
                                            }
                                          >
                                            <span className="view_page">
                                              <EyeIcon />
                                            </span>
                                          </Link>
                                        )}
                                      </td>

                                      <td className="text-center">
                                        {info.status === 1 ? (
                                          <Link
                                            className="btn btn-sm btn-flash-primary"
                                            to={`/create-event/${info._id}`}
                                            type="button"
                                            title="Edit event"
                                          >
                                            <Edit />
                                          </Link>
                                        ) : info.status === 2 ? (
                                          <React.Fragment>
                                            <Link
                                              className="btn btn-sm btn-flash-primary"
                                              to={`/manage-event/edit-event/${info._id}`}
                                              type="button"
                                              title="Edit event"
                                            >
                                              <Edit />
                                            </Link>
                                          </React.Fragment>
                                        ) : (
                                          <React.Fragment>
                                            <Link
                                              className="btn btn-sm btn-flash-primary"
                                              to={{
                                                pathname: `/manage-event/after-event-update/${info._id}`,
                                                state: {
                                                  eventName: info.eventName,
                                                },
                                              }}
                                              type="button"
                                              title="Edit event"
                                            >
                                              <Edit />
                                            </Link>
                                          </React.Fragment>
                                        )}
                                      </td>
                                      {(this.state.isAssistant?'':
                                      <td className="text-center">
                                        {info.totalTicketSold === 0 ? (
                                          <span
                                            className="view_page"
                                            onClick={() =>
                                              this.handleOpenDeletePopup(
                                                info._id
                                              )
                                            }
                                          >
                                            <Delete />
                                          </span>
                                        ) : (
                                          '_'
                                        )}
                                      </td>
                                      )}
                                    </tr>
                                  ))}
                                </React.Fragment>
                              ) : (
                                ''
                              )}
                            </tbody>
                          </table>
                        </div>
                        {this.state.eventListDetails.length > 0 ? (
                          ''
                        ) : (
                          <div className="text-center">
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                          </div>
                        )}
                      </div>
                      <div className="card-footer bg-white" style={{display:'none'}}>
                        <div className="d-flex align-items-center justify-content-between flex-column flex-sm-row mt-4">
                          {this.state.eventListDetails.length > 0 ? (
                            <p className="mb-3 mb-sm-0">
                              Showing{' '}
                              {this.state.page == 1 ? (
                                <React.Fragment>1</React.Fragment>
                              ) : (
                                this.state.page * this.state.limit -
                                this.state.limit +
                                1
                              )}{' '}
                              to{' '}
                              {this.state.eventListDetails
                                ? this.state.eventListDetails.length ===
                                  this.state.limit
                                  ? this.state.page * this.state.limit -
                                    this.state.limit +
                                    this.state.limit
                                  : this.state.page * this.state.limit -
                                    this.state.limit +
                                    this.state.eventListDetails.length
                                : ''}{' '}
                              of {this.state.totalCount} events
                            </p>
                          ) : (
                            <p className="mb-3 mb-sm-0">
                              Showing 0 to 0 of 0 events
                            </p>
                          )}

                          <nav>
                            <ul>
                              <Pagination
                                activePage={this.state.page}
                                itemsCountPerPage={this.state.limit}
                                totalItemsCount={this.state.totalCount}
                                pageRangeDisplayed={5}
                                onChange={this.handlePageChange}
                              />
                            </ul>
                          </nav>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
        <Modal
          size="md"
          show={this.state.openDeletePopup}
          onHide={this.closeModal}
          aria-labelledby="example-modal-sizes-title-sm"
        >
          <Modal.Header closeButton></Modal.Header>
          <div className="cm_modal">
            <div className="modal-body">
              <h4 className="mb-4">
                Are you sure you want to delete this event?
              </h4>
              <br />

              <div className="bg-white pull-right mb-4">
                <button
                  className="btn btn-light"
                  type="button"
                  onClick={this.closeModal}
                >
                  No
                </button>
                <button
                  type="submit"
                  className="btn btn-primary ml-2"
                  onClick={() =>
                    this.handleDeleteDarftEvent(this.state.eventIdDelete)
                  }
                >
                  Yes
                </button>
              </div>
              <br />
              <br />
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default ManageMyEvent;
